import React from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import DesempenhoCircularProgressBar from '../../../components/desempenhoCircularProgressBar';

import { AulasExerciciosTabsCreators } from '../../../store/ducks/aulasExerciciosTabs';

import Styles, { Assunto } from './styles';
import { DesempenhoUnidadeTematica } from '../../../services';

const DesempenhoUnidadeTematicaAccordion = ({
	desempenhoUnidadeTematica,
	onChange,
	expanded,
}: DesempenhoUnidadeTematicaProps) => {
	const classes = Styles();

	const history = useHistory()

	const dispatch = useDispatch()

	const handleClickAssunto = (unidadeTematica: number) => {
		history.push('/aulas', { unidadeTematica })

		dispatch(AulasExerciciosTabsCreators.setActiveTab(1));

		dispatch(
			AulasExerciciosTabsCreators.setExerciciosOpenAccordion(unidadeTematica)
		);
	}

	const { unidade_tematica, acertos, assuntos, videoaulas_assistidas, total_videoaulas, total_exercicios_respondidos, total_exercicios, unidade_tematica_id, indice } = desempenhoUnidadeTematica

	return (
		<Accordion className={classes.accordion} onChange={onChange} expanded={expanded}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className={classes.header}>{unidade_tematica}</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				<div className={classes.details}>
					<div>
						<Typography
							className={clsx(classes.blueNumber, classes.desempenhoNumber)}
						>{`${videoaulas_assistidas}/${total_videoaulas}`}</Typography>
						<Typography className={classes.desempenhoLabel}>
							Aulas
							<br />
							assistidas
						</Typography>
					</div>
					<div>
						<Typography
							className={clsx(classes.blueNumber, classes.desempenhoNumber)}
						>{`${total_exercicios_respondidos}/${total_exercicios}`}</Typography>
						<Typography className={classes.desempenhoLabel}>
							Exercícios
							<br />
							respondidos
						</Typography>
					</div>
					<div>
						<Typography
							className={clsx(classes.greenNumber, classes.desempenhoNumber)}
						>{`${acertos}/${total_exercicios_respondidos}`}</Typography>
						<Typography className={classes.desempenhoLabel}>
							Questões
							<br />
							corretas
						</Typography>
					</div>
					<DesempenhoCircularProgressBar
						percentage={indice}
					></DesempenhoCircularProgressBar>
				</div>
				<div className={classes.assuntoList}>
					{assuntos.map(assunto => (
						<Assunto hasDesempenho={assunto.has_desempenho} onClick={() => handleClickAssunto(unidade_tematica_id)}>{assunto.nome}</Assunto>
					))}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default DesempenhoUnidadeTematicaAccordion;

interface DesempenhoUnidadeTematicaProps {
	desempenhoUnidadeTematica: DesempenhoUnidadeTematica;
	onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
	expanded: boolean;
}
