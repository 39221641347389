/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { useQuery } from 'react-query';

import CircularProgress from '../../components/desempenhoCircularProgressBar';
import ExercicioBichinho from '../../components/ExercicioLink';
import Avatar from '../../components/Avatar';

import { NavBarCreators } from '../../store/ducks/navBar';
import { Creators as BackgroundCreators } from '../../store/ducks/background';
import { ModalCreators } from '../../store/ducks/modal';

import { Aula, ExercicioRelatorio, ExerciseListStatus, Tentativa } from '../../services';

import { AlunoCard, AlunoInfoContainer, AvatarContainer, Bloco, BlocosContainer, CharAsset, ExercicioLabel, ExerciciosGrid, MsgMotivacional, MsgMotivacionalContainer, QuestionInfo, QuestionInfoContainer, Sheet, SpanNomeAluno, TentativaContainer } from './style';

import useApi from '../../hooks/useApi';

import { useAppContext } from '../../contexts/AppContext';

import { Performance, assets } from '../../assets/img/relatorioDesempenho';
import correctSheet from '../../assets/img/relatorioDesempenho/correct-sheet.svg';
import wrongSheet from '../../assets/img/relatorioDesempenho/wrong-sheet.svg';
import checkCircle from '../../assets/img/check-circle-solid.svg';
import errorCircle from '../../assets/img/error-red-times.svg';
import Scrollbars from 'react-custom-scrollbars';

type LocationState = {
	aula: Aula
}

const RelatorioDesempenho = () => {
	const { nomeAluno } = useAppContext()

	document.title = 'Super Aluno | Relatório de desempenho';

	const { state: { aula } } = useLocation<LocationState>();

	const api = useApi()

	const { data, isLoading, refetch } = useQuery(['relatorioDesempenho', aula.id], () => api.getRelatorio(aula.id))

	const relatorio = data?.data  

	const serie: number = useSelector((state: any) => state.navigation.serie || 5);

	const dispatch = useDispatch();

	const [assetSet, setAssetSet] = useState<Performance>();

	useEffect(() => {
		refetch()
	}, [])

	useEffect(() => {
		if (isLoading) dispatch(ModalCreators.open('LOADING'))
		else dispatch(ModalCreators.close())
	}, [isLoading])

	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById("root")

		if (widthWindow <= 1200) {
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);
			const aulaSearchFieldContainer = document.getElementById("aula-search-field-container")

			if (rootEl) {
				rootEl.style.height = `${height + 300}px`
				rootEl.style.width = `100%`
			}
			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = "-270px"
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
			}
		}
	};

	useEffect(() => {
		dispatch(
			BackgroundCreators.setBackgrounds([
				{ backgroundImageUrl: assetSet?.bottomLeftBackground as string, position: 'left bottom' },
				{ backgroundImageUrl: assetSet?.bottomRightBackground as string, position: 'right bottom' },
			])
		);
	}, [assetSet]);

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `100%`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);

		dispatch(NavBarCreators.setTitle(`Relatório: ${aula.assunto}`));

		return () => window.removeEventListener("resize", resizeHanlder);
	}, []);

	const performance = useMemo(() => {
		if (relatorio) {
			const numerador = relatorio.tentativas.reduce((acc: number, tentativa: Tentativa) => acc + tentativa.resultado.corretas, 0) * 100
			const denominador = relatorio.tentativas.reduce((acc: number, tentativa: Tentativa) => acc + tentativa.resultado.corretas + tentativa.resultado.erradas, 0)

			return Math.ceil(numerador / denominador)
		}

		return 0
	}, [relatorio])

	useEffect(() => {
		const assetSetPerformance = getAssetSetPerformance(performance);

		const assetsObj = assets.find(asset => asset.performance === assetSetPerformance);

		setAssetSet(assetsObj);
	}, [performance]);

	const renderTentativa = useCallback((tentativa: Tentativa, index: number) => {
		if (!relatorio) return

		const exerciciosCorretos = tentativa.exercicios.filter(correto => correto.correta)
		const exerciciosErrados = tentativa.exercicios.filter(correto => !correto.correta)

		return (
			<>
				{relatorio?.tentativas.length > 1 && <p>{index + 1}ª tentativa</p>}
				<TentativaContainer>
					{exerciciosCorretos.length > 0 && (
						<Bloco correto>
							<Sheet src={correctSheet}></Sheet>
							<QuestionInfoContainer>
								<img src={checkCircle} style={{ height: 20, width: 20 }} alt="Check Circle" />
								<QuestionInfo>{`Você acertou ${exerciciosCorretos.length} questões`}</QuestionInfo>
							</QuestionInfoContainer>
							<ExerciciosGrid>
								{
									tentativa.exercicios.map((exercicio: ExercicioRelatorio, index: number) => {
										if (exercicio.correta)
											return (
												<ExercicioBichinho
													key={exercicio.exercicio_id}
													to={{
														pathname: '/justificativa-exercicio',
														state: { exercicios: tentativa.exercicios, index },
													}}
													correto
													canSeeJustificativa={relatorio.status === ExerciseListStatus.FINISHED}
												>
													<ExercicioLabel>{`Exercício ${index + 1}`}</ExercicioLabel>
												</ExercicioBichinho>
											)

										return null
									})
								}
							</ExerciciosGrid>
						</Bloco>
					)}
					{exerciciosErrados.length > 0 && (
						<Bloco>
							<Sheet src={wrongSheet}></Sheet>
							<QuestionInfoContainer>
								<img src={errorCircle} style={{ height: 20, width: 20 }} alt="Error Circle" />
								<QuestionInfo>{`Você errou ${exerciciosErrados.length} questões`}</QuestionInfo>
							</QuestionInfoContainer>
							<ExerciciosGrid>
								{
									tentativa.exercicios.map((exercicio: ExercicioRelatorio, index: number) => {
										if (!exercicio.correta)
											return (
												<ExercicioBichinho
													key={exercicio.exercicio_id}
													to={{
														pathname: '/justificativa-exercicio',
														state: { exercicios: tentativa.exercicios, index },
													}}
													canSeeJustificativa={relatorio.status === ExerciseListStatus.FINISHED}
												>
													<ExercicioLabel>{`Exercício ${index + 1}`}</ExercicioLabel>
												</ExercicioBichinho>
											)
										
										return null
									})
								}
							</ExerciciosGrid>
						</Bloco>
					)}
				</TentativaContainer>
			</>			
		)
	}, [relatorio])

	return (
		<>
			<AlunoCard>
				<AvatarContainer>
					<Avatar style={{ marginRight: 18, height: 70 }}></Avatar>
					<AlunoInfoContainer>
						<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
						<span>{serie}º ano</span>
					</AlunoInfoContainer>
				</AvatarContainer>
				<CircularProgress
					percentage={performance}
					style={{ width: 94, height: 91, marginBottom: 24 }}
				></CircularProgress>
				<MsgMotivacionalContainer>
					<CharAsset src={assetSet?.charAsset}></CharAsset>
					<MsgMotivacional>{assetSet?.motivationalPhrase}</MsgMotivacional>
				</MsgMotivacionalContainer>
			</AlunoCard>
			<BlocosContainer>
				<Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - 186 - 20} style={{marginRight: 20}}>
					{relatorio?.tentativas?.map((tentativa: Tentativa, index: number) => renderTentativa(tentativa, index))}
				</Scrollbars>
			</BlocosContainer>
		</>
	);
};

export default RelatorioDesempenho;

const getAssetSetPerformance = (percentage: number) => {
	if (0 <= percentage && percentage <= 25) return 'red';
	else if (26 <= percentage && percentage <= 50) return 'orange';
	else if (51 <= percentage && percentage <= 75) return 'yellow';
	else return 'green';
};
