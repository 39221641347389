import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import ComoEstouIndo from '../../pages/ComoEstouIndo';
import Perfil from '../../pages/Perfil';
import UnidadesTematicas from '../../pages/UnidadesTematicas';
import Exercicio from '../../pages/Exercicio';
import Aula from '../../pages/Aula';
import AulaSearch from '../../pages/AulaSearch';
import RelatorioDesempenho from '../../pages/RelatorioDesempenho';
import JustificativaExercicio from '../../pages/JustificativaExercicio';

import NavBar from './NavBar';

import { useAppContext } from '../../contexts/AppContext';

const ContentWrapper = () => {
	const location = useLocation();
	const { setSearchTerm } = useAppContext()

	useEffect(() => {
		if (location.pathname !== '/aula-search') setSearchTerm('');
	}, [location.pathname]); /* eslint-disable-line */

	return (
		<>
			<NavBar></NavBar>
			<Switch>
				<Route path="/exercicio" component={Exercicio} />
				<Route path="/aula-search" component={AulaSearch} />
				<Route path="/perfil" component={Perfil} />
				<Route path="/aulas" component={UnidadesTematicas} />
				<Route path="/video-aula" component={Aula} />
				<Route path="/desempenho" component={ComoEstouIndo} />
				<Route path="/relatorio-desempenho" component={RelatorioDesempenho} />
				<Route path="/justificativa-exercicio" component={JustificativaExercicio} />
				<Route exact path="/divzada" component={() => <div>divzada</div>} />
			</Switch>
		</>
	);
};

export default ContentWrapper