import styled from 'styled-components';

import meninaInterrogacoes from '../../assets/img/menina-interrogacoes.svg';

export const PageTitle = styled.span`
	font: Bold 20px/24px Ubuntu;
	color: white;
	position: relative;
	top: -39px;
	left: 42px;
	width: 500px;
`;

export const RootContainer = styled.main`
	margin: 0 40px 0;
	height: 460px;
`;

export const VideoaulasGridContainer = styled.div`
	margin-top: 25px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 26px;
	height: 100%;
`;

export const NoResultSearchContainer = styled.div`
	display: grid;
	justify-content: center;
	gap: 25px;
`;

export const Msg1 = styled.p`
	font: Bold 20px/24px Ubuntu;
	color: #2f2f30;
	text-align: center;
	margin-top: 84px;
`;

export const Msg2 = styled.p`
	font-family: Ubuntu;
	font-size: 20px;
	color: #16283c;
	text-align: center;
	width: 437px;
`;

export const ImagemMeninaInterrogacoes = styled.img.attrs(() => ({ src: meninaInterrogacoes, alt: 'WTF?' }))`
	justify-self: center;
`;
