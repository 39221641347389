import React, { useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Modal, Backdrop, Fade, Card, CardHeader, CardContent, IconButton } from '@material-ui/core';

import useApi from '../../../hooks/useApi';

import { Globals } from '../../../globals';

import avatars from '../../../assets/img/avatars';
import closeModalIcon from '../../../assets/img/times-solid.svg';

import { AvatarSelectionContainer, Avatar, AvatarsContainer, useStyles } from './styles'

const AvatarSelectModal = ({ open, onClose }: AvatarSelectModalProps) => {
	const classes = useStyles();

	const [selectedAvatar, setSelectedAvatar] = useState<number>();

	const api = useApi()

	const mutation = useMutation(api.editProfile)
	const queryClient = useQueryClient()
	const { data: profileData } = useQuery('profile', api.getProfile)

	useEffect(() => {
		if (profileData) setSelectedAvatar(profileData.data.avatar_id)
	}, [profileData])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={onClose} style={{ marginBottom: 2 }}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>ESCOLHA UM AVATAR</p>}
					/>
					<CardContent className={classes.cardContent}>
						<AvatarsContainer>
							{avatars.slice(9).map((_, index) => {
								const indexWithOffset = index + 9
								return (
									<AvatarSelectionContainer
										key={indexWithOffset}
										onClick={() => setSelectedAvatar(indexWithOffset)}
									>
										<Avatar selected={selectedAvatar === indexWithOffset}
											src={avatars[indexWithOffset]} alt="avatar"></Avatar>
									</AvatarSelectionContainer>
								)
							})}
						</AvatarsContainer>
						<Globals.GeneralButton
							onClick={() => {
								mutation.mutate({ avatar_id: selectedAvatar }, {
									onSuccess: () => queryClient.invalidateQueries('profile')
								})
								onClose();
							}}
							disabled={!selectedAvatar}
						>
							Confirmar
						</Globals.GeneralButton>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default AvatarSelectModal;

interface AvatarSelectModalProps {
	open: boolean;
	onClose: () => void;
}
