import styled, { css } from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';

import InputMask from 'react-input-mask';

interface InputGroupProps {
	password?: boolean;
}

export const FormGroup = styled.div`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	row-gap: 15px;
`;

export const Form = styled.form`
	width: 260px;
`;

export const InputGroup = styled.div<InputGroupProps>`
	display: grid;
	grid-template-columns: max-content 1fr;
	column-gap: 10px;
	align-items: center;
	background-color: #fff;
	border-radius: 5px;
	padding-left: 16px;
	transition: box-shadow 0.2s ease;
	border: 1px solid #c5c5c5;
	border-radius: 8px;
	height: 45px;
	svg {
		color: #999;
	}
	&:focus-within {
		box-shadow: 0px 0px 0px 2px #e0cfda;
	}
	${props =>
		props.password &&
		css`
			grid-template-columns: max-content 1fr max-content;
		`}
`;

export const Input = styled.input`
	width: 195px;
	font-family: 'Ubuntu', sans-serif;
	font-size: 16px;
	border: 0;
	color: #2f2f30;
	opacity: 0.8;
	letter-spacing: 0px;
	filter: hue-rotate(170deg);

	&::placeholder {
		color: #2f2f30;
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
		box-shadow: 0 0 0px 1000px white inset;
	}
`;

export const MatriculaLoginMask = styled(InputMask)`
	width: 195px;
	font-size: 16px;
	border: 0;
	color: #2f2f30;
	opacity: 0.8;
	letter-spacing: 0px;
	filter: hue-rotate(170deg);
	font-family: Ubuntu, sans-serif;

	&::placeholder {
		color: #2f2f30;
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
		box-shadow: 0 0 0px 1000px white inset;
	}
`;

export const FormActions = styled.div`
	display: flex;
	justify-content: center;
`;

export const BtnLogin = styled(Button).attrs((props: any) => ({
	fullWidth: true,
	disableElevation: true,
	type: 'submit',
	color: 'primary',
	variant: 'contained',
	loading: props.loading,
}))`
	width: 300px;
	height: 44px;
	font: Bold 16px/18px Ubuntu;
	letter-spacing: 0px;
	color: #ffffff;
	text-transform: initial;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	border: 0;

	${props =>
		!props.loading &&
		css`
			.MuiButton-label {
				display: flex;
				justify-content: space-between;
			}
		`}
`;

export const BtnLoginText = styled.p``;

export const BtnRecoverPassword = styled(Button).attrs((props: any) => ({
	component: RouterLink,
	to: props.to,
	fullWidth: true,
	disableElevation: true,
	type: 'submit',
	color: 'primary',
}))`
	color: #09bdf0;
	font-family: Ubuntu;
	font-size: 14px;
	letter-spacing: 0px;
	text-transform: initial;
`;

export const BtnAjuda = styled(Button).attrs((props: any) => ({
	children: 'Ajuda',
	fullWidth: true,
	disableElevation: true,
	type: 'submit',
	color: 'primary',
}))`
	color: #2f2f30;
	font-family: Ubuntu;
	font-size: 14px;
	letter-spacing: 0px;
	text-transform: initial;
`;

export const MsgBelowLogo = styled.p`
	text-align: center;
	font-family: Ubuntu;
	font-size: 20px;
	letter-spacing: 0px;
	color: #2f2f30;
`;

export const BelowLoginButton = styled(FormActions)`
	display: flex;
	flex-direction: column;
`;

export const ErrorMessage = styled.p<{ visible: boolean }>`
	font-size: 12px;
	color: #fc2121;
	font-weight: 500;
	text-align: center;
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const EnvironmentsDropdown = styled(Select)`
	border-radius: 8px;
	border: 1px solid #c5c5c5;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding: 8px 0 8px 8px;
	font-size: 14px;
	color: #2f2f30;
	height: 45px;
	width: 100%;

	.MuiInputBase-input {
		background: transparent;
		padding-left: 9px;
	}
`;

export const EnvironmentOption = styled(MenuItem)`
	padding: 8px 19px;
	font-family: Ubuntu;
	font-size: 14px;
`;

export const BackButton = styled(Button)`
	position: fixed;
	top: 2%;
	left: 3%;
	text-transform: none;
	color: white;
	font-size: 1rem;
	font-weight: bold;
`;

export const useStyles = makeStyles(() => ({
	input: {
		marginBottom: 10,
		width: '100%',
	},
}));
