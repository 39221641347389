import styled from 'styled-components';

import livrinhoVerde from '../../assets/img/livrinho-verde.svg';
import livrinhoBranco from '../../assets/img/livrinho-branco.svg';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	border: 2px solid #15ddbd;
	width: 169px;
	height: 44px;
	cursor: pointer;

	background: transparent;
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		background: #15ddbd;
		width: 150%;
		padding-top: 150%;
		transition: 0.25s ease;
		border-radius: 999px;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 410%;
	}

	&:hover {
		color: #fff;
		&:after {
			top: 100%;
		}
	}
`;

const SpanText = styled.span`
	height: 100%;
	width: 100%;
	font-size: 16px;
	position: relative;
	z-index: 1;
	&:hover {
		&:before {
			content: url(${livrinhoBranco});
			margin-right: 8px;
		}
	}
	&:before {
		content: url(${livrinhoVerde});
		margin-right: 8px;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
`;

export { Container, SpanText };
