import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// interfaces
interface SerieChangeSnackbarActions {
	OPEN_SNACKBAR: string;
	CLOSE_SNACKBAR: string;
}

interface SerieChangeSnackbarCreators {
	openSnackbar: () => any;
	closeSnackbar: () => any;
}

export interface SerieChangeSnackbarStore {
	open: boolean;
}

// initial state
const INITIAL_STATE = Immutable<SerieChangeSnackbarStore>({
	open: false,
});

// reducers
const openSnackbar = (state = INITIAL_STATE) => state.set('open', true);
const closeSnackbar = (state = INITIAL_STATE) => state.set('open', false);

// actions
export const { Types: SerieChangeSnackbarTypes, Creators: SerieChangeSnackbarCreators } = createActions<
	SerieChangeSnackbarActions,
	SerieChangeSnackbarCreators
>({
	openSnackbar: [],
	closeSnackbar: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[SerieChangeSnackbarTypes.OPEN_SNACKBAR]: openSnackbar,
	[SerieChangeSnackbarTypes.CLOSE_SNACKBAR]: closeSnackbar,
});
