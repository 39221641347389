import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			width: 430,
			height: 294,
			borderRadius: 20,
			paddingBottom: 70,
			color: 'white',
		},
		cardHeader: {
			background: '#09BDF0 0% 0% no-repeat padding-box',
			height: 50,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			paddingLeft: 72,
			paddingRight: 72,
			paddingBottom: 70,
		},
		whatsapp: {
			display: 'flex',
			alignItems: 'center',
			fontSize: 20,
			fontWeight: 500,
			textDecoration: 'none',
			color: '#2F2F30'
		},
		wppIcon: {
			marginRight: 8,
		},
	})
);
