import React from 'react'

import { Asset, Container, Message } from "./style"

const NoClassesExercisesFeedback = () => (
    <Container>
        <Asset></Asset>
        <Message>
            Ainda não existem aulas e exercícios para esse modo<br></br> de estudo. Não se preocupe, estamos
            preparando<br></br> esse conteúdo para você ;)
        </Message>
    </Container>
)

export default NoClassesExercisesFeedback