import React from 'react';

import styled from 'styled-components';

export default ({ whatIsMissing }: NoAulasExercisesMessageProps) => {
	return <NoAulasExercisesMessage>{`Em breve teremos ${whatIsMissing} para esse tema ;)`}</NoAulasExercisesMessage>;
};

const NoAulasExercisesMessage = styled.span`
	color: #07b0ff;
	font-size: 16px;
`;

interface NoAulasExercisesMessageProps {
	whatIsMissing: 'aulas' | 'exercícios';
}
