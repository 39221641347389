import styled, { css } from 'styled-components';

import { Globals } from '../../../globals';

import pranchetinha from '../../../assets/img/clipboard-list-solid9x12.svg';

export const ExercicioAlunoCard = styled(Globals.AlunoCard)`
	z-index: 1;
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;
`;

export const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
export const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
	text-transform: capitalize;
`;

export const PranchetinhaContainer = styled.div.attrs((props: any) => ({ relatorioAvailable: props.relatorioAvailable }))`
	background: ${props => (props.relatorioAvailable ? '#07B0FF' : '#c5c5c5')} url(${pranchetinha}) 50% 50% no-repeat
		padding-box;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	${props =>
		props.relatorioAvailable &&
		css`
			cursor: pointer;
		`}
`;

export const AssuntoContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, max-content);
	column-gap: 8px;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
	border-top: rgba(221, 216, 216, 0.5) 1px solid;
	padding: 16px 0;
	width: 205px;
	justify-content: start;
`;

export const ExerciciosGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: 8px;
	padding: 18px 0 0 13px;
`;

export const ExercicioLabel = styled.span`
	color: black;
	font-family: Ubuntu;
	font-size: 12px;
`;

export const ExercicioSwitch = styled.div.attrs((props: any) => ({
	respondido: props.respondido,
	correto: props.correto,
}))`
	display: flex;
	justify-content: center;
	width: 107px;
	border: 2px solid
		rgba(
			${props =>
		props.respondido ? (props.correto ? '20, 231, 196, 0.6' : '252, 105, 33, 0.8') : '7, 176, 255, 0.6'}
		);
	border-radius: 8px;
	padding: 10px;
	cursor: pointer;
	transition: 0.25s ease;
	&:hover {
		transform: translateY(-6px);
	}
`;
