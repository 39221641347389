import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from 'react-query';

import { Accordion, AccordionSummary, AccordionDetails, ListItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import SuperEnsinoLoading from '../../../components/SuperEnsinoLoading';
import AulaComponent from './Aula';
import NoAulasExercisesMessage from '../NoAulasExercisesMessage';

import { AulasExerciciosTabsCreators } from '../../../store/ducks/aulasExerciciosTabs';

import useApi from '../../../hooks/useApi';

import { Aula, UnidadeTematica } from '../../../services';

import Styles from './styles';

const ExerciciosAccordion = ({
	unidadeTematica
}: ExerciciosAccordionProps) => {
	const { id, nome } = unidadeTematica

	const classes = Styles();

	const dispatch = useDispatch();

	const exerciciosOpenAccordion = useSelector((state: any) => state.aulasExerciciosTabs.exerciciosOpenAccordion);

	const accordionRef = useRef<HTMLElement>(null);

	const [expanded, setExpanded] = React.useState(false);

	const api = useApi()

	const { isLoading, data } = useQuery(['aulas', id], () => api.getAulas(id), { enabled: expanded })

	const aulas = data?.data ?? []

	useEffect(() => {
		if (exerciciosOpenAccordion === id) {
			setTimeout(() => accordionRef.current?.scrollIntoView({ behavior: 'smooth' }), 500);
			setExpanded(true);
		} else setExpanded(false);
	}, [exerciciosOpenAccordion]); // eslint-disable-line

	const renderAulas = () => {
		if (isLoading)
			return (
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
					<SuperEnsinoLoading></SuperEnsinoLoading>
				</div>
			)

		if (!aulas.length) return <NoAulasExercisesMessage whatIsMissing="exercícios"></NoAulasExercisesMessage>

		return (
			<div className={classes.details}>
				{
					aulas.map((aula: Aula) => (
						<AulaComponent
							key={aula.id}
							aula={aula}
							unidadeTematica={unidadeTematica}
						/>
					))
				}
			</div>
		)
	}

	return (
		<ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
			<Accordion
				ref={accordionRef}
				expanded={expanded}
				className={classes.accordion}
				onChange={(_, expanded) => dispatch(AulasExerciciosTabsCreators.setExerciciosOpenAccordion(expanded ? id : 0))}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.header}>{nome}</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					{renderAulas()}
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

export default ExerciciosAccordion;

interface ExerciciosAccordionProps {
	unidadeTematica: UnidadeTematica
}
