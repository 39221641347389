import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/styles';

import { SerieChangeSnackbarCreators, SerieChangeSnackbarStore } from '../../store/ducks/serieChangeSnackbar';

const SerieChangeSnackbar: React.FC<{ afterClose?: () => void; serie: number; onDesfazer: () => void }> = ({
	afterClose,
	serie,
	onDesfazer,
}) => {
	const dispatch = useDispatch();
	const serieChangeSnackbarStore: SerieChangeSnackbarStore = useSelector((state: any) => state.serieChangeSnackbar);

	const classes = useStyles();

	return (
		<Snackbar
			classes={{ root: classes.snackbar }}
			open={serieChangeSnackbarStore.open}
			autoHideDuration={7000}
			onClose={() => {
				dispatch(SerieChangeSnackbarCreators.closeSnackbar());
				afterClose && dispatch(afterClose);
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<div className={classes.inner}>
				<p>{`Você mudou para o conteúdo do ${serie}º ano`}</p>
				<div className={classes.button} onClick={onDesfazer}>
					Desfazer
				</div>
			</div>
		</Snackbar>
	);
};

export default SerieChangeSnackbar;

const useStyles = makeStyles(() => ({
	snackbar: {
		borderRadius: 8,
		backgroundColor: '#F2FAFD',
		fontWeight: 'bold',
		fontSize: 18,
		padding: '16px 24px',
		color: '#2F2F30',
	},
	inner: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	button: {
		width: 117,
		height: 35,
		fontSize: 18,
		textTransform: 'none',
		color: 'black',
		fontWeight: 'bold',
		border: '2px solid #15ddbd',
		borderRadius: 8,
		opacity: 0.76,
		'&:hover': {
			backgroundColor: '#15ddbd',
			color: 'white',
			cursor: 'pointer',
		},
		marginTop: 11,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
