import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import anexo from '../../../assets/img/anexo.svg';

export const VideoAula = styled(Link).attrs((props: any) => ({ href: props.href }))`
	&& {
		display: grid;
		grid-template-rows: repeat(2, max-content);
		row-gap: 8px;
		padding: 10px;
		text-decoration: none;

		&:hover {
			background-color: rgba(9, 189, 240, 0.15);
		}
	}
`;

export const VideoAulaDetails = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const VideoAulaThumbnail = styled.picture`
	width: 145px;
	img {
		width: 100%;
	}
`;

export const VideoAulaTitle = styled.h6.attrs((props: any) => ({ text: props.text, children: props.text }))`
	font-size: 14px;
	color: black;
	font-family: Ubuntu;
`;

export default makeStyles((theme: Theme) =>
	createStyles({
		accordion: {
			border: '1px solid #07B0FF99',
			borderRadius: 8,
			background: '#F7FCFE',
			boxShadow: '0px 2px 10px #07B0FF1A',
			width: '100%',
		},
		header: {
			color: '#2F2F30',
			letterSpacing: 0,
			fontWeight: 'bold',
			fontSize: 14,
		},
		expandMoreIcon: {
			background: 'linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%)',
			height: 20,
			width: 20,
			borderRadius: 10,
			color: 'white',
		},
		details: {
			display: 'block',
			padding: 10
		},
	})
);

export const AulasContainer = styled.div`
	display: grid;
	grid-column-gap: 5px;
	grid-template-columns: repeat(auto-fill, 165px);
`;

export const AnexosContainer = styled.div`
	padding: 10px;
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 5px;
	border-top: rgba(221, 216, 216, 0.5) 1px solid;
	padding-top: 15px;
`;

export const AnexoLink = styled.a`
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: black;
`;

export const AnexoTitle = styled.span`
	font-size: 14px;
	margin-left: 12px;
`;

export const AnexoIcon = styled.img.attrs(() => ({ src: anexo }))`
	display: inline;
`;

export const SuperEnsinoLoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 25px;
	width: 100%;
`;
