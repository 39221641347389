/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Scrollbars } from 'react-custom-scrollbars';

import useApi from '../../../hooks/useApi';

import Avatar from '../../../components/Avatar';
import Tooltip from '../../../components/SuperTooltip';

import { ModalCreators } from '../../../store/ducks/modal';

import { useAppContext } from '../../../contexts/AppContext';

import { Aula, ExercicioAula, ExerciseListStatus, UnidadeTematica } from '../../../services';

import { AlunoInfoContainer, ExercicioSwitch, AssuntoContainer, AvatarContainer, ExercicioAlunoCard, ExercicioLabel, ExerciciosGrid, PranchetinhaContainer, SpanNomeAluno } from './style';


type LocationState = {
	aula: Aula,
	unidadeTematica: UnidadeTematica,
	exercicio: ExercicioAula,
	index: number
}

const SideCard = ({relatorioAvailable}: Props) => {
	const { userRole, nomeAluno } = useAppContext()
	const history = useHistory();
	const { state } = useLocation<LocationState>()

	const serie: number = useSelector((state: any) => state.navigation.serie || 5);

	const dispatch = useDispatch();

	const api = useApi()

	const { data: aulasData } = useQuery(['aulas', state.unidadeTematica.id], () => api.getAulas(state.unidadeTematica.id))
	const aula = aulasData?.data.find((aula: Aula) => aula.id === state.aula.id)

	const handleVerRelatorio = () => {
		if (!relatorioAvailable) return

		if (userRole !== 3) {
			dispatch(
				ModalCreators.open(
					'UNAUTHORIZED_ACCESS',
					'Não é possível ver o relatório :(',
					'Somente os alunos possuem acesso ao relatório.'
				)
			);
			return
		}

		history.push('/relatorio-desempenho', { aula });
	};
	
	const getExercicioIsRespondido = useCallback((exercicio: ExercicioAula) => 
		aula?.status === ExerciseListStatus.FINISHED || 
		(aula?.status === ExerciseListStatus.REDOING && exercicio.tries > 1) || 
		(aula?.status === ExerciseListStatus.STARTED && exercicio.tries === 1), [aula?.status])

	return (
		<ExercicioAlunoCard>
			<AvatarContainer>
				<Avatar style={{ marginRight: 18, height: 57 }}></Avatar>
				<AlunoInfoContainer>
					<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
					<span>{serie}º ano</span>
				</AlunoInfoContainer>
			</AvatarContainer>
			<AssuntoContainer>
				<Tooltip title={relatorioAvailable ? "Resultado da lista de exerício disponível" : "Complete a lista de exercício para ver seu resultado"} placement="top-start">
					<PranchetinhaContainer
						onClick={handleVerRelatorio}
						relatorioAvailable={relatorioAvailable}
					></PranchetinhaContainer>
				</Tooltip>
				<span style={{ fontSize: 14, width: 171 }}>{aula?.assunto}</span>
			</AssuntoContainer>
			<Scrollbars autoHide style={{ width: 250, height: 250 }}>
				<ExerciciosGrid>
					{aula?.exercicios.map((exercicio: ExercicioAula, index: number) => {
						return (
							<ExercicioSwitch
								key={exercicio.id}
								onClick={() => history.replace('/exercicio', { ...state, exercicio, index })}
								respondido={getExercicioIsRespondido(exercicio)}
								correto={exercicio.acertou}
							>
								<ExercicioLabel>{`Exercício ${index + 1}`}</ExercicioLabel>
							</ExercicioSwitch>
						);
					})}
				</ExerciciosGrid>
			</Scrollbars>
		</ExercicioAlunoCard>
	);
};

export default SideCard

interface Props {
	relatorioAvailable: boolean
}