import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const VideoAulaContainer = styled.div.attrs((props: any) => props)`
	padding: 24px;
	padding: 0;
`;

export const VideoAula = styled(Link).attrs((props: any) => ({ href: props.href }))`
	&& {
		display: grid;
		grid-template-rows: repeat(2, max-content);
		row-gap: 8px;
		text-decoration: none;
		font-family: Ubuntu;
		font-size: 14;
		color: #000;
	}
`;

export const VideoAulaDetails = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const VideoAulaThumbnail = styled.picture`
	img {
		width: 100%;
	}
`;

export const VideoAulaTitle = styled.h6.attrs((props: any) => ({ text: props.text, children: props.text }))`
	font-size: 17px;
	color: #222;
`;
