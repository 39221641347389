/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shuffle } from 'lodash';

import ReportAvailability from '../../../../components/ReportAvailability';
import SuperTooltip from '../../../../components/SuperTooltip';
import ConditionalWrapper from '../../../../components/ConditionalWrapper';
import RedoExerciseList from '../../../../components/RedoExerciseList';
import FinishExerciseList from '../../../../components/FinishExerciseList';

import { useAppContext } from '../../../../contexts/AppContext';
import { ModalCreators } from '../../../../store/ducks/modal';

import useApi from '../../../../hooks/useApi';

import { Aula, ExercicioAula, ExerciseListStatus, UnidadeTematica } from '../../../../services';

import { ActionsContainer, Assunto, AssuntoContainer, Exercicio, ExerciciosGrid, RootContainer } from './style';
import { ActionsModalCreators } from '../../../../store/ducks/actionsModal';

const AulaComponent = ({ aula, unidadeTematica }: AulaProps) => {
	const { userRole } = useAppContext()
	const dispatch = useDispatch();
	const api = useApi()
	const { data: aulasData } = useQuery(['aulas', unidadeTematica.id], () => api.getAulas(unidadeTematica.id))
	const queryClient = useQueryClient()
	const mutation = useMutation((status: ExerciseListStatus) => api.updateExerciseListStatus(aula.exercise_list_student, status))
	const history = useHistory();

	const allExercisesDone = aula.exercicios?.every(exercicio => exercicio.respondido)
	const relatorioAvailable = aula.exercicios.every(exercicio => exercicio.respondido)

	const handleClick = useCallback(() => {
		if (!relatorioAvailable) return

		if (userRole !== 3) {
			dispatch(
				ModalCreators.open(
					'UNAUTHORIZED_ACCESS',
					'Não é possível ver o relatório :(',
					'Somente os alunos possuem acesso ao relatório.'
				)
			)
			return
		}

		history.push('/relatorio-desempenho', { aula });
	}, [relatorioAvailable, userRole, aula])

	const decisionPending = aula.status === ExerciseListStatus.STARTED && allExercisesDone

	const updateAula = (status: ExerciseListStatus) => {
		const aulasDataDraft = {...aulasData}
		const aulaDraft = aulasDataDraft.data?.find((item: Aula) => item.id === aula.id)

		if (aulaDraft) {			
			if (status === ExerciseListStatus.REDOING)
				aulaDraft.exercicios = shuffle(aulaDraft.exercicios)

			aulaDraft.status = status

			queryClient.setQueryData(['aulas', unidadeTematica.id], aulasDataDraft)
		}		
	}

	const redoExerciseList = () => {
		const status = ExerciseListStatus.REDOING

		mutation.mutate(status, {
			onSuccess: () => {
				updateAula(status)
				history.push('/exercicio', {
					unidadeTematica,
					aula,
					index: 0
				})
			}
		})
	}

	const finishExerciseList = () => {
		const status = ExerciseListStatus.FINISHED

		mutation.mutate(status, {
			onSuccess: () => updateAula(status)
		})
	}

	const closeConfirmation = () => dispatch(ActionsModalCreators.closeActionsModal())

	const getExercicioIsRespondido = useCallback((exercicio: ExercicioAula) => 
		aula?.status === ExerciseListStatus.FINISHED || 
		(aula?.status === ExerciseListStatus.REDOING && exercicio.tries > 1) || 
		(aula?.status === ExerciseListStatus.STARTED && exercicio.tries === 1), [aula?.status])
		
	return (
		<RootContainer>
			<AssuntoContainer>
				<ActionsContainer columnsNumber={decisionPending ? 3 : 1}>
					<ReportAvailability
						tooltip
						available={relatorioAvailable}
						onClick={handleClick}
					></ReportAvailability>
					{/** Se a lista de exercícios ainda estiver como STARTED e todos os exercícios já tiverem sido resolvidos 
					 * significa que ele ainda não aplicou a decisão de REFAZER ou FINALIZAR. Aí ele será forçado a escolher
					 * REFAZER ou a FINALIZAR a lista
					*/}
					{
						decisionPending && 
						<>
							<RedoExerciseList
								onClick={redoExerciseList}
							/>
							<FinishExerciseList
								onClick={() => dispatch(ActionsModalCreators.openActionsModal([
									{label: "sim", callback: () => {
										closeConfirmation()
										finishExerciseList()
									}},
									{label: "nao", callback: closeConfirmation},
								], '', 'Ao confirmar, não será mais possível refazer os exercícios. Deseja realmente confirmar?'))}
							/>
						</>
					}
				</ActionsContainer>				
				<Assunto>{aula.assunto}</Assunto>
			</AssuntoContainer>
			<ExerciciosGrid>
				{aula.exercicios?.map((exercicio, index) => (
					<ConditionalWrapper key={exercicio.id} condition={exercicio.respondido} wrapper={(children: any) => (
						<SuperTooltip
							title={exercicio.acertou ? 'Exercício correto' : 'Exercício errado'}
							placement="top"
						>
							{children}
						</SuperTooltip>
					)}>
						{
							<Exercicio
								respondido={getExercicioIsRespondido(exercicio)}
								key={exercicio.id}
								text={`Exercício ${index + 1}`}
								to={{
									pathname: '/exercicio',
									state: {
										unidadeTematica,
										aula,
										exercicio,
										index
									},
								}}
								correto={exercicio.acertou}
							/> as any
						}
					</ConditionalWrapper>
				))}
			</ExerciciosGrid>
		</RootContainer>
	);
};

export default AulaComponent;

interface AulaProps {
	aula: Aula
	unidadeTematica: UnidadeTematica
}
