import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';
import { composeWithDevTools } from 'redux-devtools-extension'

import localforage from 'localforage';

import rootReducer from './ducks';

import { REACT_APP_STORAGE_KEY } from '../env';

const storageKey = REACT_APP_STORAGE_KEY as string;

const persistedReducer = persistReducer(
	{
		key: storageKey,
		storage: localforage,
		stateReconciler: seamlessImmutableReconciler,
		transforms: [seamlessImmutableTransformCreator({})],
	},
	rootReducer
);

const store = createStore(persistedReducer, composeWithDevTools());

const persistor = persistStore(store);

export { store, persistor };
