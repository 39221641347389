import styled, { css } from 'styled-components';

export const Container = styled.form.attrs((props: any) => ({
	width: props.width,
	id: 'aula-search-field-container',
}))`
	display: flex;
	width: ${props => props.width}px;
	height: 36px;
	background: #f7fcfe 0% 0% no-repeat padding-box;
	border-radius: 18px;
	padding: 6px;
	justify-content: space-between;
	transition: all 0.3s ease-in-out;
	align-items: center;
`;

export const SearchField = styled.input`
	color: #2f2f30;
	font-family: Ubuntu;
	font-size: 12px;
	font-weight: 500;
	padding-left: 11px;
	border: 0;
	flex-shrink: 1;
	flex-grow: 1;
	&:placeholder {
		opacity: 0.6;
	}
`;

export const LupaContainer = styled.div.attrs((props: any) => ({ disabled: props.disabled }))`
	width: 26px;
	height: 26px;
	background: #${props => (props.disabled ? 'C5C5C5' : '07b0ff')};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-left: 7px;

	${props =>
		!props.disabled &&
		css`
			cursor: pointer;

			transition-duration: 1s;
			&:hover {
				transform: rotateY(360deg);
			}
		`}
`;

export const SuggestionsContainer = styled.div.attrs((props: any) => ({
	isOpen: props.isOpen,
	position: props.position,
	width: props.width,
}))`
	z-index: 3;
	border-radius: 8px;
	background-color: #f7fcfe;
	font-family: Ubuntu;
	font-size: 12px;
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
	min-width: ${props => props.width}px;
	visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in-out;
	${props =>
		props.position.left &&
		css`
			left: ${props.width === 347 ? props.position.left : props.position.left - 153}px;
		`}
	${props =>
		props.position.top &&
		css`
			top: ${props.position.top + 8}px;
		`}
	box-shadow: rgba(0, 19, 255, 0.20) 0px 3px 20px;
`;

export const Suggestion = styled.div`
	padding: 6px 10px;
	display: grid;
	gap: 5px;
	grid-template-columns: repeat(2, max-content);
	cursor: pointer;
	border-radius: 8px;

	&:hover {
		background-color: rgba(9, 189, 240, 0.15);
	}
`;
