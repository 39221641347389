import styled from "styled-components";

import noClassesExercisesFeedback from '../../../assets/img/no-classes-exercises-feedback.svg';

export const Container = styled.div`
    display: flex;
	align-items: center;
	position: absolute;
	top: 170px;
	left: 310px;
	padding-top: 27px;
	padding-left: 40px;
`

export const Asset = styled.img.attrs(() => ({
	src: noClassesExercisesFeedback,
	alt: "Sem aulas/exercícios"
}))`
    width: 104px;
`

export const Message = styled.p`
    color: #16283C;
	font-size: 20;
	margin-left: 48;
`