import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface Actions {
	SET_TITLE: string;
}

interface Creators {
	setTitle: (title: string) => any;
}

interface NavBar {
	title: string;
}

// initial state
const INITIAL_STATE: NavBar = {
	title: ''
};

// reducers
const setTitle = (state = INITIAL_STATE, action: any) => ({ title: action.title });

// actions
export const { Types, Creators: NavBarCreators } = createActions<Actions, Creators>({
	setTitle: ['title'],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[Types.SET_TITLE]: setTitle,
});
