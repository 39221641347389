import React from 'react'

import { InputLabel, OutlinedInputProps, FormControlClassKey, OutlinedInputClassKey } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { StyledInput, StyledFormControl } from './styles'

const Input = (props: InputProps) => {
    return (
        <StyledFormControl variant="outlined" classes={props.classes} >
            <InputLabel htmlFor={props.id} classes={props.labelClasses}>{props.id?.replace(/^\w/, (c) => c.toUpperCase())}</InputLabel>
            <StyledInput {...props} classes={props.inputClasses}></StyledInput>
        </StyledFormControl>
    )
}

export interface InputProps extends OutlinedInputProps {
    classes?: Partial<ClassNameMap<FormControlClassKey>>
    inputClasses?: Partial<ClassNameMap<OutlinedInputClassKey>>
    labelClasses?: Partial<ClassNameMap<OutlinedInputClassKey>>
}

export default Input