/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, MemoryRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import MatriculaLoginForm from './MatriculaLoginForm';
import PasswordInput from './PasswordInput';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import PasswordRecoveryFeedback from './PasswordRecoveryFeedback';

import { AuthCreators } from '../../store/ducks/auth';

import { BoyOverBooksImage, Content, Container, Logo, AppName } from './style';

import superEnsinoLogo from '../../assets/img/super-ensino-logo-login-form.png';
import closeModalIcon from '../../assets/img/grey-times-solid.svg';
import noConnectionAsset from '../../assets/img/sem-conexao.svg';

const Login = () => {
	const token = useSelector((state: any) => state.auth.token);

	const dispatch = useDispatch();

	const resizeHanlder = () => {
		const heightWindow = window.innerHeight;
		const rootEl = document.getElementById("root")

		if (heightWindow < 601) {
			if (rootEl) {
				rootEl.style.height = `${heightWindow + Math.abs(heightWindow - 557)}px`
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `100%`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);
		document.title = 'Super Aluno | Login';

		if (!token) dispatch(AuthCreators.resetAuth());
		return () => {
			const rootEl = document.getElementById("root")
			const body = document.body;

			body.style.height = `100%`
			if (rootEl) {
				rootEl.style.height = `100%`
			}
			window.removeEventListener("resize", resizeHanlder);
		}
	}, []);

	return (
		<>
			<img src={closeModalIcon} style={{ display: 'none' }} alt="Close modal" />
			<img src={noConnectionAsset} style={{ display: 'none' }} alt="No connection" />
			<Container>
				<BoyOverBooksImage></BoyOverBooksImage>
				<Content>
					<Logo>
						<img src={superEnsinoLogo} alt="Logo da Super Ensino" />
					</Logo>
					<AppName>Super Aluno Web</AppName>
					<MemoryRouter>
						<Switch>
							<Route exact path="/" component={MatriculaLoginForm}></Route>
							<Route path="/password-input" component={PasswordInput}></Route>
							<Route path="/password-recovery" component={PasswordRecoveryForm}></Route>
							<Route path="/password-recovery-feedback" component={PasswordRecoveryFeedback}></Route>
							<Redirect path="*" to="/" />
						</Switch>
					</MemoryRouter>
				</Content>
			</Container>
		</>
	);
};

export default Login;
