import React from 'react';

import { Container, SpanText } from './style';

const SuperReforcoSaebSelectionButton: React.FC<DisciplinaButtonSelection> = ({ selected, children, onClick }) => {
	const containerConditionalStyle = {
		background: selected ? '#09BDF0' : 'white',
	};

	return (
		<Container style={containerConditionalStyle} onClick={onClick}>
			<SpanText style={{ color: selected ? 'white' : '' }}>{children}</SpanText>
		</Container>
	);
};

export default SuperReforcoSaebSelectionButton;

interface DisciplinaButtonSelection {
	selected?: boolean;
	onClick: () => void;
}
