import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

import SuperEnsinoLoading from '../SuperEnsinoLoading';

import { ModalCreators, ModalName } from '../../store/ducks/modal';

const LoadingModal = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const isOpen = useSelector((state: any) => state.modal.name === ModalName.LOADING);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={() => dispatch(ModalCreators.close())}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<SuperEnsinoLoading></SuperEnsinoLoading>
			</Fade>
		</Modal>
	);
};

export default LoadingModal

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	})
);
