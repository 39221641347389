import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface Actions {
	SET_SERIE: string;
	SET_DISCIPLINA: string;
	SET_MODO: string;
	SET_PREVIOUS_SERIE: string;
	RESET: string
}

export type Modo = 'SR' | 'PB'

interface Creators {
	setSerie: (serie: number) => any;
	setDisciplina: (disciplina: number) => any;
	setModo: (modo: Modo) => any;
	setPreviousSerie: (previousSerie: number) => any;
	reset: () => any;
}

export interface NavigationStore {
	modo: Modo;
	serie?: number;
	disciplina?: number;
	previousSerie?: number;
}

// initial state
const INITIAL_STATE: NavigationStore = {
	modo: 'SR',
};

// reducers
const setSerie = (state = INITIAL_STATE, action: any) => ({ ...state, serie: action.serie });
const setDisciplina = (state = INITIAL_STATE, action: any) => ({ ...state, disciplina: action.disciplina });
const setModo = (state = INITIAL_STATE, action: any) => ({ ...state, modo: action.modo });
const setPreviousSerie = (state = INITIAL_STATE, action: any) => ({ ...state, previousSerie: action.previousSerie });
const reset = (state = INITIAL_STATE) => ({ ...INITIAL_STATE, serie: state.serie })

// actions
export const { Types: NavigationTypes, Creators: NavigationCreators } = createActions<
	Actions,
	Creators
>({
	setSerie: ['serie'],
	setDisciplina: ['disciplina'],
	setModo: ['modo'],
	setPreviousSerie: ['previousSerie'],
	reset: []
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[NavigationTypes.SET_SERIE]: setSerie,
	[NavigationTypes.SET_DISCIPLINA]: setDisciplina,
	[NavigationTypes.SET_MODO]: setModo,
	[NavigationTypes.SET_PREVIOUS_SERIE]: setPreviousSerie,
	[NavigationTypes.RESET]: reset,
});
