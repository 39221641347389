import React from 'react';
import {Done} from '@material-ui/icons'

import SuperTooltip from '../SuperTooltip';

const ReportAvailability: React.FC<Props> = ({ onClick }) => {
	const containerStyle: React.CSSProperties = {
		borderRadius: '50%',
		backgroundColor: '#2fb0ff',
		height: 20,
		width: 20,
		minWidth: 20,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	return (
		<SuperTooltip
			title="Finalizar lista de exercícios"
			placement="top-start"
		>
			<div style={containerStyle} onClick={onClick}>
				<Done htmlColor='white' style={{width: 16, height: 16}}/>
			</div>
		</SuperTooltip>
	)
};

export default ReportAvailability;

interface Props {
	onClick: () => void;
}
