import styled from 'styled-components';

import { Globals } from '../../globals';

export const AlunoCard = styled(Globals.AlunoCard)`
	box-shadow: 0px 3px 10px #0013ff26;
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
	margin-bottom: 16px;
`;

export const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 20px;
`;
export const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
	text-transform: capitalize;
`;

export const BlocosContainer = styled.main`
	position: absolute;
	top: 184px;
	left: 343px;
	width: 850px;	
	/* display: flex;
	flex-direction: row;
    flex-wrap: wrap; */
`;

export const TentativaContainer = styled.div`
	display: flex;
	gap: 32px;
	margin-bottom: 32px;
`

export const Bloco = styled.div.attrs((props: any) => ({ correto: props.correto }))`
	/* width: 359px; */
	border: 1px solid #${props => (props.correto ? '14E7C4' : 'FC6921')};
	box-shadow: 0px 2px 10px #07b0ff1a;
	background: #f7fcfe 0% 0% no-repeat padding-box;
	border-radius: 8px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

export const Sheet = styled.img`
	margin-bottom: 16px;
	width: 82px;
`;


export const MsgMotivacionalContainer = styled.div`
	display: flex;
	align-items: center;
	width: 213px;
`;

export const CharAsset = styled.img`
	width: 75px;
	height: 75px;
`;

export const MsgMotivacional = styled.p`
	font-size: 12px;
	width: 130px;
	height: 35px;
	margin-left: 10px;
`;

export const QuestionInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 26px;
`;

export const QuestionInfo = styled.p`
	color: black;
	font-family: Ubuntu;
	font-size: 14px;
	margin-left: 8px;
`;

export const ExerciciosGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
`;

export const ExercicioLabel = styled.span`
	color: black;
	font-family: Ubuntu;
	font-size: 12px;
`;
