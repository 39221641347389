import React from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { PasswordFieldProps, PF } from './styles'

const PasswordField = (props: PasswordFieldProps) => {
	const [passwordIsVisible, setPasswordIsVisible] = React.useState(false);

	return (
		<PF
			{...props}
			type={passwordIsVisible ? 'text' : 'password'}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={() => setPasswordIsVisible(!passwordIsVisible)}
						edge="end"
					>
						{passwordIsVisible ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			}

		/>
	);
};

export default PasswordField;