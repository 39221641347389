/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import useApi from '../hooks/useApi'
import { Disciplina } from '../services'

import { getReducedName } from '../utils'

interface AppContextType {
    idAluno: number
    nomeAluno: string
    userRole?: number
    disciplinas: Disciplina[]
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
    searchTermSuggestions: string[]
    setSearchTermSuggestions: (searchTermSuggestions: string[]) => void
}

const AppContext = createContext<AppContextType | null>(null)

const AppContextProvider: FC = ({ children }) => {
    const serie = useSelector((state: any) => state.navigation.serie || 5)
    const disciplina = useSelector((state: any) => state.navigation.disciplina)

    const [idAluno, setIdAluno] = useState<number>(0)
    const [nomeAluno, setNomeAluno] = useState('')
    const [userRole, setUserRole] = useState<number>()
    const [searchTerm, setSearchTerm] = useState('')
    const [searchTermSuggestions, setSearchTermSuggestions] = useState<string[]>([])

    const api = useApi()

    const { data: profileData } = useQuery('profile', api.getProfile)
    const { data: disciplinasData } = useQuery('disciplinas', api.getDisciplinas)

    const disciplinas = disciplinasData?.data ?? []

    useEffect(() => {
        if (profileData) {
            setUserRole(profileData.data.role)
            setNomeAluno(getReducedName(profileData.data.nome))
            setIdAluno(profileData.data.id)
        }
    }, [profileData])

    useEffect(() => {
        if (!searchTerm) {
            setSearchTermSuggestions([])
            return
        }

        searchAulasSuggestions(searchTerm)
    }, [searchTerm])

    const searchAulasSuggestions = useCallback(
        _.debounce((searchTerm: string) => {
            if (searchTerm.length)
                api.searchAulas({ limit: 5, search_term: searchTerm, serie, disciplina })
                    .then(({ data: { results } }) => setSearchTermSuggestions(results.map(aula => aula.titulo)))
        }, 1000)
        , [])

    return (
        <AppContext.Provider value={{ nomeAluno, userRole, disciplinas, searchTerm, setSearchTerm, searchTermSuggestions, setSearchTermSuggestions, idAluno }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider

export const useAppContext = () => {
    const context = useContext(AppContext) as AppContextType
    return context
}
