import styled from 'styled-components';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const AvatarSelectionContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	border-radius: 8px;
	padding: 16px 13px 0;
	cursor: pointer;
	margin-right: 10px;
`;

export const Avatar = styled.img<AvatarProps>`
	border: 8px solid ${props => (props.selected ? '#14e7c499' : 'transparent')};
	border-radius: 50%;
	user-select: none;
`;

export const AvatarsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 40px;
`;

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			borderRadius: 20,
			paddingBottom: 40,
			color: 'white',
		},
		cardHeader: {
			background: '#09BDF0 0% 0% no-repeat padding-box',
			height: 50,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			paddingLeft: 72,
			paddingRight: 72,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			flexDirection: 'column',
		},
	})
);

interface AvatarProps {
	selected: boolean;
}
