import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			width: 430,
			borderRadius: 20,
			color: 'white',
		},
		cardHeader: {
			background: '#09BDF0 0% 0% no-repeat padding-box',
			height: 50,
			paddingLeft: 47,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			padding: '30px 85px 73px',
		},
		cardContentInputGroup: {
			display: 'grid',
			gridRowGap: 16,
			justifyContent: 'center',
		},
		instruction: {
			textAlign: 'center',
		},
		submitButton: {
			width: '100%',
			height: 44,
			border: 0,
			borderRadius: 8,
			color: 'white',
			font: 'Bold 16px/18px Ubuntu',
			textTransform: 'none',
		},
		checkCircleContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
		checkCircle: {
			width: 70,
		},
		errorMessage: {
			fontSize: 12,
			color: '#FC2121',
			textAlign: 'center',
			visibility: 'hidden',
		},
		errorMessageVisible: {
			visibility: 'visible',
		},
		cardContentRoot: {
			'&:last-child': {
				paddingBottom: 73,
			},
		},
	})
);

export default useStyles;
