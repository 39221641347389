import { Theme, createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			borderRadius: 20,
			background: '#f7fcfe',
			maxWidth: 500
		},
		cardHeader: {
			height: 50,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			padding: '40px 70px',
		},
		sairButton: {
			color: 'white',
			width: 169,
			height: 44,
			borderRadius: 8,
			background: 'transparent linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%) 0% 0% no-repeat padding-box',
			fontSize: 14,
			fontWeight: 'bold',
			marginBottom: 12,
		},
		backToAppButton: {
			fontSize: 14,
			marginBottom: 16,
			fontWeight: 500,
			color: '#09BDF0',
			textTransform: 'none',
		},
		simButton: {
			marginRight: 8
		}
	})
);

export const Summary = styled.div.attrs((props: {correto: boolean})=> ({correto: props.correto}))`
	border: 2px solid #${props => (props.correto ? '14E7C4' : 'FC6921')};
	border-radius: 8px;
	padding: 8px;
	display: flex;
	margin-bottom: 4px;
`