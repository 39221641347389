import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

import AulaSearchField from '../../AulaSearchField';
import SuperTooltip from '../../SuperTooltip';

import { SerieChangeSnackbarCreators, SerieChangeSnackbarStore } from '../../../store/ducks/serieChangeSnackbar';
import { ModalCreators } from '../../../store/ducks/modal'

import iconBack from '../../../assets/img/arrow-left-solid.svg';
import homeLogoSuperEnsino from '../../../assets/img/home-logo-superensino.png';

const NavBar = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const history = useHistory();

	const title = useSelector((state: any) => state.navBar.title);
	const snackbarStore: SerieChangeSnackbarStore = useSelector((state: any) => state.serieChangeSnackbar);

	return (
		<header className={classes.container}>
			<nav className={classes.navBar}>
				<img
					src={iconBack}
					className={clsx(classes.header, classes.floatUpItem)}
					alt="Voltar"
					onClick={() => {
						snackbarStore.open && dispatch(SerieChangeSnackbarCreators.closeSnackbar());
						history.goBack();
					}}
				/>
				<SuperTooltip title="Início">
					<img
						src={homeLogoSuperEnsino}
						className={clsx(classes.header, classes.floatUpItem)}
						alt="Logo da Super Ensino"
						onClick={() => {
							snackbarStore.open && dispatch(SerieChangeSnackbarCreators.closeSnackbar());
							history.push('/');
						}}
					/>
				</SuperTooltip>

				<Typography
					className={clsx(classes.header, classes.floatUpItem)}
					onClick={() => history.push('/perfil')}
				>
					Perfil
				</Typography>
				{/* <Typography className={classes.header}>Notificações</Typography> */}
				<Typography
					className={clsx(classes.header, classes.floatUpItem)}
					onClick={() => dispatch(ModalCreators.open('AJUDA'))}
				>
					Ajuda
				</Typography>
				<Typography
					className={clsx(classes.header, classes.floatUpItem)}
					onClick={() => dispatch(ModalCreators.open('LOGOUT'))}
				>
					Sair
				</Typography>

				<Typography className={classes.title} variant="h5" noWrap>
					{title}
				</Typography>
			</nav>
			<AulaSearchField style={searchFieldStyle}></AulaSearchField>
		</header>
	);
}

export default NavBar

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		padding: '24px 32px 0 47px',
		background: 'transparent linear-gradient(84deg, #078CFF 0%, #13E6DC 100%) 0% 0% no-repeat padding-box',
		height: 160,
		flexShrink: 0,
	},
	navBar: {
		display: 'flex',
		alignItems: 'center',
		height: 60,
		width: "80%",
	},
	logoSuperEnsino: {
		height: 30,
	},
	title: {
		flexGrow: 1,
		alignSelf: 'flex-end',
		fontWeight: 'bold',
		color: '#FFFFFF',
		position: 'relative',
		top: 65,
		left: -18,
	},
	header: {
		color: '#FFFFFF',
		font: 'bold 16px/18px Ubuntu',
		cursor: 'pointer',
		padding: 15,
		transition: '0.25s ease',
	},
	floatUpItem: {
		transition: '0.25s ease',
		'&:hover': {
			transform: 'translateY(-6px)',
		},
	},
}));

const searchFieldStyle: CSSProperties = {
	zIndex: 2,
	marginTop: 24,
};
