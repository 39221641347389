import { combineReducers } from 'redux';

import auth from './auth';
import navigation from './navigation';
import background from './background';
import navBar from './navBar';
import serieChangeSnackbar from './serieChangeSnackbar';
import aulasExerciciosTabs from './aulasExerciciosTabs';
import modal from './modal';
import actionsModal from './actionsModal';

const rootReducer = combineReducers({
	auth,
	navigation,
	background,
	navBar,
	serieChangeSnackbar,
	aulasExerciciosTabs,
	modal,
	actionsModal
});

export default rootReducer;
