import React, { CSSProperties, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import avatars from '../../assets/img/avatars';
import logo from '../../assets/img/logo-super-ensino-97x82.png';
import useApi from '../../hooks/useApi';

const Avatar = ({ style }: AvatarProps) => {
	const [avatar, setAvatar] = useState(logo)

	const api = useApi()

	const { data: profileData } = useQuery('profile', api.getProfile)

	useEffect(() => {
		if (profileData) setAvatar(avatars[profileData.data.avatar_id || 10])
	}, [profileData])

	return (
		<img src={avatar} style={style} alt="Avatar do Aluno" />
	);
};

interface AvatarProps {
	style?: CSSProperties
}

export default Avatar;