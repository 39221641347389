import styled from 'styled-components';

import { ContentGrid } from '../../globals';

import leftBottomLoginPageImage from '../../assets/img/boy-over-book-stack.svg';
import rightLoginPageImage from '../../assets/img/login-page-right-background-image.png';

export const Container = styled(ContentGrid)`
	background: url(${rightLoginPageImage}) no-repeat right top,
		transparent linear-gradient(62deg, #078cff 0%, #17ffd2 100%) 0% 0% no-repeat padding-box;
	position: relative;
	z-index: 1;
	display: flex;
    flex-direction: row;
    justify-content: flex-start;
	align-items: flex-start;
    padding-left: 5%;
    padding-top: 5%;

	@media screen and (max-height: 900px){
		padding-top: 3%;
		padding-left: 13%;
	}
`;

export const BoyOverBooksImage = styled.img.attrs(() => ({ src: leftBottomLoginPageImage }))`
	bottom: 0px;
	left: 0px;
	position: absolute;
	z-index: 1;
`;

export const Content = styled.main`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	row-gap: 16px;
	justify-content: center;
	top: 73px;
	left: 145px;
	width: 455px;
	background: #f7fcfe;
	border-radius: 30px;
	box-shadow: 0px 3px 20px #0013ff26;
	padding-top: 59px;
	padding-bottom: 35px;
`;

export const Logo = styled.picture`
	width: 260px;
`;

export const AppName = styled.h1`
	text-align: center;
	color: #32487b;
`