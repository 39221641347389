import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	CircularProgress,
	Typography
} from '@material-ui/core';

import { validate } from 'email-validator'

import Input from '../../../components/Input'

import useStyles, { SubmitButton, ErrorMessage } from './styles'

import closeModalIcon from '../../../assets/img/times-solid.svg';
import checkCircle from '../../../assets/img/check-circle-solid.svg';
import useApi from '../../../hooks/useApi';

const ChangeEmailTelefoneModal = ({ open, onClose }: ChangeEmailTelefoneModal) => {
	const classes = useStyles();

	const [email, setEmail] = useState('');
	const [emailConfirmation, setEmailConfirmation] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(true);

	const [errorMessage, setErrorMessage] = useState('metal galera')
	const [formIsValid, setFormIsValid] = useState(true)

	const [processing, setProcessing] = useState(false)
	const [success, setSuccess] = useState(false)

	const api = useApi()

	const mutation = useMutation(api.editProfile)
	const queryClient = useQueryClient()

	const setAllFieldsToValid = () => {
		setFormIsValid(true)
		setEmailIsValid(true)
	}

	const onCloseModal = () => {
		onClose();

		setEmail('')
		setEmailConfirmation('')

		setSuccess(false)
	};

	useEffect(() => {
	}, []);

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setAllFieldsToValid()
	};
	const handleChangeEmailConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
		setEmailConfirmation(e.target.value);
		setAllFieldsToValid()
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()

		setProcessing(true)

		if (!email) {
			setEmailIsValid(false)
			setErrorMessage('Insira um e-mail')
			setFormIsValid(false)
		} else if (email && !validate(email)) {
			setEmailIsValid(false)
			setErrorMessage('Insira um e-mail válido')
			setFormIsValid(false)
		} else if (email !== emailConfirmation) {
			setEmailIsValid(false)
			setErrorMessage('O novo e-mail deve corresponder a sua confirmação')
			setFormIsValid(false)
		} else {
			try {
				mutation.mutate({ email }, {
					onSuccess: () => queryClient.invalidateQueries('profile')
				})

				setSuccess(true)

				setTimeout(() => onCloseModal(), 2000)
			}
			catch (error) {
				setErrorMessage(error)
				setFormIsValid(false)
			}
		}

		setProcessing(false)
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={onCloseModal} style={{ marginBottom: 2 }}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>ALTERAÇÃO DE E-MAIL</p>}
					/>
					<CardContent className={classes.cardContent}>
						{
							!processing && success ? (
								<>
									<div className={classes.checkCircleContainer}>
										<img
											className={classes.checkCircle}
											src={checkCircle}
											alt="Check circle"
										/>
									</div>
									<Typography className={classes.instruction}>
										E-mail alterado com sucesso!
									</Typography>
								</>
							) :
								(
									<form onSubmit={handleSubmit} className={classes.form}>
										<Input id="Novo e-mail" error={!emailIsValid} value={email} onChange={handleChangeEmail} labelWidth={88}></Input>
										<Input id="Confirme o novo e-mail" error={!emailIsValid} value={emailConfirmation} onChange={handleChangeEmailConfirmation} labelWidth={175}></Input>
										<ErrorMessage visible={!formIsValid}>{errorMessage}</ErrorMessage>
										<SubmitButton
											disableFocusRipple
											type="submit"
										>
											{processing ? (
												<CircularProgress size={18} />
											) : (
												'Confirmar'
											)}
										</SubmitButton>
									</form>
								)
						}
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default ChangeEmailTelefoneModal;

interface ChangeEmailTelefoneModal {
	open: boolean;
	onClose: () => void;
}
