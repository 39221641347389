import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, MemoryRouter, Route, Redirect, BrowserRouter } from 'react-router-dom';

import Login from './pages/Login';
import Inicio from './pages/Inicio';

import ContentWrapper from './components/ContentWrapper';
import AppContextProvider from './contexts/AppContext';

import { REACT_APP_IS_DEV } from './env';

const Routes = () => {
	const token = useSelector((state: any) => state.auth.token);

	if (!token) return <Login></Login>

	const routerChildren = (
		<AppContextProvider>
			<Switch>
				<Route exact path="/" component={Inicio} />
				<ContentWrapper></ContentWrapper>
				<Redirect path="*" to="/" />
			</Switch>
		</AppContextProvider>
	)

	if (REACT_APP_IS_DEV)
		return (
			<BrowserRouter>
				{routerChildren}
			</BrowserRouter>
		)


	return (
		<MemoryRouter>
			{routerChildren}
		</MemoryRouter>
	)
};

export default Routes;
