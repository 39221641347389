/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from 'react-query'

import { Accordion, AccordionSummary, AccordionDetails, ListItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import SuperTooltip from '../../../components/SuperTooltip';
import SuperEnsinoLoading from '../../../components/SuperEnsinoLoading';
import ConditionalWrapper from '../../../components/ConditionalWrapper';
import AnexosSection from './AnexosSection';

import NoAulasExercisesMessage from '../NoAulasExercisesMessage';

import { AulasExerciciosTabsCreators } from '../../../store/ducks/aulasExerciciosTabs';

import { UnidadeTematica, Videoaula } from '../../../services'

import useStyles, { SuperEnsinoLoadingContainer, VideoAula, VideoAulaDetails, VideoAulaThumbnail, VideoAulaTitle, AulasContainer } from './styles';

import useApi from '../../../hooks/useApi';

import blueCheck from '../../../assets/img/blue-check.svg';
import mapaMental from '../../../assets/mapa-mental.png';
import pdf from '../../../assets/pdf.png';
import video from '../../../assets/video.png';

const VideoaulasAccordion = ({ unidadeTematica }: VideoaulasAccordionProps) => {
	const { id, nome, anexos, mapas_mentais } = unidadeTematica

	const classes = useStyles();

	const accordionRef = useRef<HTMLElement>(null);

	const dispatch = useDispatch();

	const videoaulasOpenAccordion = useSelector((state: any) => state.aulasExerciciosTabs.videoaulasOpenAccordion);

	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (videoaulasOpenAccordion === id) {
			setTimeout(() => accordionRef.current?.scrollIntoView({ behavior: 'smooth' }), 700);
			setExpanded(true);
		} else setExpanded(false);
	}, [videoaulasOpenAccordion]); /* eslint-disable-line */

	const api = useApi()

	const { data, isLoading } = useQuery(['videoaulas', id], () => api.getVideoaulas(id), { enabled: expanded })

	const videoaulas = data?.data.results ?? []

	const renderAulas = () => {
		if (isLoading) return (
			<SuperEnsinoLoadingContainer>
				<SuperEnsinoLoading></SuperEnsinoLoading>
			</SuperEnsinoLoadingContainer>
		)

		if (!videoaulas.length) return (
			<NoAulasExercisesMessage whatIsMissing="aulas"></NoAulasExercisesMessage>
		)

		return (
			<>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<img width={30} src={video} alt='video' />
					<span style={{padding: '0 10px'}}>Videoaulas</span>
				</div>
				<AulasContainer>
					{
						videoaulas.map((videoaula: Videoaula) => (
							<ConditionalWrapper condition={videoaula.assistido} wrapper={(children: any) => (
								<SuperTooltip key={videoaula.id} title="Vídeo assistido" placement="top">
									{children}
								</SuperTooltip>
							)}>
								{
									<VideoAula
										key={videoaula.id}
										to={{
											pathname: '/video-aula',
											state: { unidadeTematica, videoaula }
										}}
									>
										<VideoAulaThumbnail>
											<img src={videoaula.thumbnail.uri || ''} alt={`${videoaula.titulo}`} />
										</VideoAulaThumbnail>
										<VideoAulaDetails>
											<VideoAulaTitle text={videoaula.titulo} />
											{videoaula.assistido && <img src={blueCheck} alt="Blue check" style={{ alignSelf: 'flex-start' }} />}
										</VideoAulaDetails>
									</VideoAula> as any
								}
							</ConditionalWrapper>
						))
					}
				</AulasContainer>
			</>
		)
	}

	const renderAnexos = () => {
		const attachments = []

		if (mapas_mentais && mapas_mentais.length)
			attachments.push(<AnexosSection anexos={mapas_mentais} title='Mapas mentais' icon={<img width={30} src={mapaMental} style={{borderRadius: '50%'}} alt='mapa-mental' />} />)

		if (anexos && anexos.length) 
			attachments.push(<AnexosSection anexos={anexos} title='Cadernos de apoio'  icon={<img width={30} src={pdf} alt='pdf' />}/>)

		if (!isLoading)
			return attachments	

		return null
	}

	return (
		<ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
			<Accordion
				className={classes.accordion}
				expanded={expanded}
				ref={accordionRef}
				onChange={(_, expanded) => dispatch(AulasExerciciosTabsCreators.setVideoaulasOpenAccordion(expanded ? id : 0))}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.header}>{nome}</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					{renderAulas()}
					{renderAnexos()}
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

export default VideoaulasAccordion;

interface VideoaulasAccordionProps {
	unidadeTematica: UnidadeTematica
}
