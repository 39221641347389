import styled from 'styled-components';
import { makeStyles, Select } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { Globals } from '../../globals';

const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
`;

const AvatarAluno = styled.img.attrs((props: any) => ({ src: props.src, alt: 'Avatar do aluno' }))`
	margin-right: 18px;
	width: 40px;
	height: 57px;
`;

const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
`;

const ModoSelect = styled(Select).attrs(props => ({
	native: true,
}))`
	width: 169px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid #c5c5c5;
	background: #09bdf0 0% 0% no-repeat padding-box;
	padding: 8px 0 8px 8px;
	font-size: 14px;
	color: white;
	font-weight: bold;
`;

const DisciplinaSelect = styled(Select).attrs(props => ({
	native: true,
}))`
	width: 169px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid #c5c5c5;
	background: #15ddbd 0% 0% no-repeat padding-box;
	padding: 8px 0 8px 8px;
	font-size: 14px;
	color: white;
	font-weight: bold;
`;

const SerieOption = styled.option`
	padding: 8px 19px;
	font-family: Ubuntu;
	font-size: 14px;
	color: #2f2f30;
`;

const FiltrosContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	padding: 20px 0;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
	margin-bottom: 16px;
`;

const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const DropdownLabel = styled.p`
	font-size: 14px;
	color: #2f2f30;
	margin-bottom: 8px;
`;

const DesempenhoButton = styled(Globals.GeneralButton).attrs((props: any) => ({
	component: RouterLink,
	to: props.to,
}))``;

export const TabsContainer = styled.div`
	display: block;
	width: 50%;
	position: absolute;
	top: 128px;
	left: 343px;
	z-index: 2;
`

export const TabsHeadersContainer = styled.div`
	background-color: white;
	border-radius: 4px 4px 0px 0px;
	box-shadow: 0px 1px 1px rgba(0, 19, 255, 0.1);
`;

export const TabPanel = styled.section.attrs((props: any) => ({
	value: props.value,
	index: props.index,
	hidden: props.value !== props.index,
}))`
	margin-top: 10px;
	width: 100%;
	max-height: 60vh;
`;

export const useStyles = makeStyles(() => ({
	tabHover: {
		'&:hover': {
			backgroundColor: 'rgba(9, 189, 240, 0.15)',
		},
	},
}));

export default {
	AvatarContainer,
	AvatarAluno,
	AlunoInfoContainer,
	SpanNomeAluno,
	ModoSelect,
	DisciplinaSelect,
	SerieOption,
	FiltrosContainer,
	DropdownLabel,
	DropdownContainer,
	DesempenhoButton,
	TabsContainer
};
