import styled from 'styled-components';

const NavItem = styled.p`
	color: white;
	font: bold 16px/18px Ubuntu;
	padding: 15px;
	cursor: pointer;
	transition: 0.25s ease;
	&:hover {
		transform: translateY(-6px);
	}
`;

export { NavItem };
