import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface BackgroundActions {
	SET_BACKGROUNDS: string;
	CLEAN_BACKGROUNDS: string;
}

interface BackgroundCreators {
	setBackgrounds: (backgrounds: SingleBackgroundData[]) => any;
	cleanBackgrounds: () => any;
}

interface SingleBackgroundData {
	backgroundImageUrl: string;
	position: 'right bottom' | 'left bottom';
}

interface BackgroundData {
	backgrounds: SingleBackgroundData[];
}

export interface InitialState {
	data: BackgroundData;
}

// initial state
const INITIAL_STATE: InitialState = {
	data: {
		backgrounds: [],
	},
};

// reducers
const setBackgrounds = (state = INITIAL_STATE, action: any) => ({
	data: { backgrounds: action.backgrounds },
});
const cleanBackgrounds = () => INITIAL_STATE;

// actions
export const { Types, Creators } = createActions<BackgroundActions, BackgroundCreators>({
	setBackgrounds: ['backgrounds'],
	cleanBackgrounds: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[Types.SET_BACKGROUNDS]: setBackgrounds,
	[Types.CLEAN_BACKGROUNDS]: cleanBackgrounds,
});
