/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { useQuery } from 'react-query';

import SuperReforcoSaebSelectionButton from '../../components/SuperReforcoSaebSelectionButton';
import DisciplinaSelectionButton from '../../components/DisciplinaSelectionButton';
import NavBar from './NavBar';
import SerieChangeSnackbar from '../../components/SerieChangeSnackbar';
import Avatar from '../../components/Avatar';
import SuperEnsinoLoading from '../../components/SuperEnsinoLoading';

import useApi from '../../hooks/useApi';

import { NavigationCreators } from '../../store/ducks/navigation';
import { SerieChangeSnackbarCreators } from '../../store/ducks/serieChangeSnackbar';
import { AulasExerciciosTabsCreators } from '../../store/ducks/aulasExerciciosTabs';

import { useAppContext } from '../../contexts/AppContext';

import { Disciplina } from '../../services';

import {
	AlunoInfoContainer,
	Container,
	DialogText,
	DisciplinaButtonItem,
	DisciplinaButtonsContainer,
	FiltrosContent,
	LogoContainer,
	Main,
	MainWrapper,
	ModeButtonsContainer,
	SpanNomeAluno,
} from './style';

const Inicio = () => {
	const dispatch = useDispatch();

	const { setSearchTerm, nomeAluno } = useAppContext()

	const history = useHistory();

	const serie = useSelector((state: any) => state.navigation.serie || 5);
	const modo = useSelector((state: any) => state.navigation.modo);
	const disciplina = useSelector((state: any) => state.navigation.disciplina);
	const previousSerie = useSelector((state: any) => state.navigation.previousSerie);

	document.title = 'Super Aluno | Home';

	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById("root")

		if (widthWindow <= 1399) {
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);

			if (rootEl) {
				rootEl.style.height = `${height}px`
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
				rootEl.style.width = `100%`
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `auto`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);

		dispatch(NavigationCreators.reset());
		dispatch(AulasExerciciosTabsCreators.resetStore());

		return () => {
			setSearchTerm('');
			window.removeEventListener("resize", resizeHanlder);
		}
	}, []);

	const dispatchDisciplina = (disciplina: number) => {
		dispatch(NavigationCreators.setDisciplina(disciplina));
	};

	const api = useApi()

	const { data: disciplinasData, isLoading: disciplinasAreLoading } = useQuery('disciplinas', api.getDisciplinas)
	const { isLoading: profileIsLoading } = useQuery('profile', api.getProfile)
	const { isLoading: schoolIsLoading } = useQuery('school', api.getEscola)

	const disciplinas = disciplinasData?.data ?? []

	return (
		<Container id='containerRoot'>
			<NavBar />
			<MainWrapper>
				<Main>
					{profileIsLoading || schoolIsLoading || disciplinasAreLoading ? (
						<SuperEnsinoLoading />
					) : (
						<FiltrosContent>
							<LogoContainer>
								<Avatar style={{ marginRight: 18 }} />
								<AlunoInfoContainer>
									<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
									<span>{serie}º ano</span>
								</AlunoInfoContainer>
							</LogoContainer>
							<DialogText>O que vamos estudar?</DialogText>
							<ModeButtonsContainer>
								<SuperReforcoSaebSelectionButton
									onClick={() => dispatch(NavigationCreators.setModo('SR'))}
									selected={modo === 'SR'}
								>
									SUPER REFORÇO
								</SuperReforcoSaebSelectionButton>
								<SuperReforcoSaebSelectionButton
									onClick={() => dispatch(NavigationCreators.setModo('PB'))}
									selected={modo === 'PB'}
								>
									SAEB
								</SuperReforcoSaebSelectionButton>
							</ModeButtonsContainer>
							<DialogText>Escolha uma matéria!</DialogText>
							<DisciplinaButtonsContainer>
								{disciplinas
									.filter((disciplina: Disciplina) => disciplina.is_prova_brasil === (modo === 'PB'))
									.map((disciplinaObj: Disciplina) => (
										<DisciplinaButtonItem key={disciplinaObj.id}>
											<DisciplinaSelectionButton
												selected={disciplina === disciplinaObj.id}
												onClick={() => {
													dispatchDisciplina(disciplinaObj.id);
													history.push('/aulas');
												}}
											>
												{disciplinaObj.nome}
											</DisciplinaSelectionButton>
										</DisciplinaButtonItem>
									))}
							</DisciplinaButtonsContainer>
						</FiltrosContent>
					)}
				</Main>
			</MainWrapper>
			<SerieChangeSnackbar
				serie={serie}
				onDesfazer={() => {
					history.goBack();
					dispatch(SerieChangeSnackbarCreators.closeSnackbar());
					dispatch(NavigationCreators.setSerie(previousSerie));
				}}
			/>
		</Container>
	);
};

export default Inicio
