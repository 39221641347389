/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useQuery } from 'react-query';

import { Badge, IconButton, InputBase } from '@material-ui/core';

import NumberFormat from 'react-number-format'

import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars'

import { Globals } from '../../globals';

import Avatar from '../../components/Avatar';
import PasswordChangeModal from './ChangePasswordModal';
import SuperTooltip from '../../components/SuperTooltip';
import SelectArrow from './SelectArrow';
import AvatarSelectModal from './AvatarSelectModal';
import ChangeTelefoneModal from './ChangeTelefoneModal';
import ChangeEmailModal from './ChangeEmailModal';

import useApi from '../../hooks/useApi';

import { NavigationCreators } from '../../store/ducks/navigation';
import { Creators as BackgroundCreators } from '../../store/ducks/background';
import { NavBarCreators } from '../../store/ducks/navBar';
import { SerieChangeSnackbarCreators } from '../../store/ducks/serieChangeSnackbar';

import { getReducedName } from '../../utils';

import contactImage from '../../assets/img/perfil/contact.svg';
import padlockImage from '../../assets/img/perfil/padlock.svg';
import responsavelImage from '../../assets/img/perfil/responsavel.svg';
import logoSuperEnsino from '../../assets/img/se-logo-card-aluno.png';
import rightBottomImage from '../../assets/img/menina-livro-aberto-colo.png';
import leftBottomImage from '../../assets/img/menina-com-globo-terrestre480x384.png';
import topRightImage from '../../assets/img/criancas-serelepes.png';
import pencil from '../../assets/img/pencil-alt-solid.svg';

import { useStyles, ResponsavelUsername, AlunoNome, CardInfoContainer, CardInfoLabel, ChangeSerieButton, PerfilInfoCard, PerfilInfoContainer, PerfilLabel, PerfilMainContainer, RepresentativeImage, SerieOption, SeriesSelect, Tooltip, Edit, CardInfo } from './style'

enum ModalName {
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	CHANGE_AVATAR = 'CHANGE_AVATAR',
	CHANGE_TELEFONE = 'CHANGE_TELEFONE',
	CHANGE_EMAIL = 'CHANGE_EMAIL'
}

const Perfil = () => {
	const currentNavigationSerie: number = useSelector((state: any) => state.navigation.serie || 5);

	const [serie, setSerie] = useState(currentNavigationSerie);
	const [serieTooltipIsOpen, setSerieTooltipIsOpen] = useState(false);

	const [openModal, setOpenModal] = useState<string>()

	const closeModal = () => setOpenModal(undefined)

	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();

	const api = useApi()

	const { data: profileData } = useQuery('profile', api.getProfile)
	const { data: schoolData } = useQuery('school', api.getEscola)

	const capitalizeString = (string: string) =>
		string
			.split(' ')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(' ');

	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById("root")
		const topCriancas = document.getElementById("topCriancas")

		const body = document.body;
		const html = document.documentElement;
		const height = Math.max(body.scrollHeight, body.offsetHeight,
			html.clientHeight, html.scrollHeight, html.offsetHeight);

		if (widthWindow <= 1299) {

			const aulaSearchFieldContainer = document.getElementById("aula-search-field-container")

			if (rootEl) {
				rootEl.style.height = `${height}px`

			}

			if (topCriancas) {
				topCriancas.style.width = `30%`
			}

			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = "-220px"
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
				rootEl.style.width = `100%`
			}

			if (topCriancas) {
				topCriancas.style.width = `300px`
				topCriancas.style.left = `auto`
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById("root")
		const body = document.body;
		if (rootEl) {
			rootEl.style.height = `auto`
			rootEl.style.width = `100%`
			body.style.overflowY = "visible"
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);

		document.title = 'Super Aluno | Perfil';

		dispatch(
			BackgroundCreators.setBackgrounds([
				{ backgroundImageUrl: rightBottomImage, position: 'right bottom' },
				{ backgroundImageUrl: leftBottomImage, position: 'left bottom' },
			])
		);

		return () => {
			if (rootEl) {
				rootEl.style.height = `auto`
				rootEl.style.width = `100%`
				body.style.overflowY = "visible"
			}
			window.removeEventListener("resize", resizeHanlder);
		}
	}, []);

	useEffect(() => {
		dispatch(NavBarCreators.setTitle(`Perfil: ${capitalizeString(profileData?.data.nome ?? 'Carregando...')}`));
	}, [profileData])

	const handleSerieChange = (event: ChangeEvent<{ value: unknown }>) => setSerie(Number(event.target.value));

	const is1920px = window.matchMedia('(width: 1920px)').matches

	const scrollbarProps: ScrollbarProps = {
		autoHide: true,
		autoHeight: true,
		autoHeightMax: is1920px ? 1000 : 350,
		autoHeightMin: is1920px ? 0 : 350
	}

	const userData = useMemo(() => {
		let data: any;
		
		if (profileData && schoolData) {
			data = {
				nome: getReducedName(profileData.data.nome),
				email: profileData.data.email ?? '-',
				telefone: profileData.data.telefone_celular ?? '-',
				username: profileData.data.username,
				serie: schoolData.data.serie
			}

			if (profileData.data.role === 3) {
				data.responsavel1Username = profileData.data.responsaveis[0]?.username
				data.responsavel2Username = profileData.data.responsaveis[1]?.username
				data.nomeEscola = schoolData.data.nome ?? '-'
				data.role = profileData.data.role
				data.matricula = profileData.data.registration
				data.turno = schoolData.data.turma_turn ?? '-'
				data.turma = schoolData.data.turma_cod && schoolData.data.turma_cod[schoolData.data.turma_cod.length - 1]
			}
		}

		return data
	}, [profileData, schoolData])

	const studentSerieTurma = userData?.serie && userData?.turma ? `${userData.serie}º ${userData.turma}` : '-'

	return (
		<>
			<Globals.AlunoCard>
				<div className={classes.avatarContainer}>
					<Badge
						overlap="circle"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						badgeContent={
							<SuperTooltip title="Editar avatar">
								<IconButton onClick={() => setOpenModal(ModalName.CHANGE_AVATAR)} className={classes.iconButton}>
									<img className={classes.pencil} src={pencil} alt="Pencilzera" />
								</IconButton>
							</SuperTooltip>
						}
						style={{ justifyContent: 'center' }}
					>
						<Avatar></Avatar>
					</Badge>
				</div>
				<PerfilInfoContainer>
					<PerfilLabel>
						Perfil de <br />
						<AlunoNome>{userData?.nome}</AlunoNome>
					</PerfilLabel>
					<Tooltip open={serieTooltipIsOpen}>
						<SeriesSelect
							value={serie}
							onChange={handleSerieChange}
							input={<InputBase></InputBase>}
							IconComponent={SelectArrow}
							onOpen={() => setSerieTooltipIsOpen(false)}
							onMouseEnter={() => setSerieTooltipIsOpen(true)}
							onMouseLeave={() => setSerieTooltipIsOpen(false)}
							MenuProps={{
								style: {
									top: 0,
								},
							}}
							classes={{
								select: classes.select,
								selectMenu: classes.selectMenu,
							}}
						>
							{[4, 5, 6, 7, 8, 9].map(serie => (
								<SerieOption key={serie} value={serie}>{`${serie}º ano`}</SerieOption>
							))}
						</SeriesSelect>
					</Tooltip>
					{serie !== currentNavigationSerie && (
						<ChangeSerieButton
							onClick={() => {
								history.push('/');

								dispatch(NavigationCreators.setSerie(serie));
								dispatch(NavigationCreators.setPreviousSerie(currentNavigationSerie));

								dispatch(SerieChangeSnackbarCreators.openSnackbar());
							}}
						>{`Ir para o ${serie}º ano`}</ChangeSerieButton>
					)}
				</PerfilInfoContainer>
				<img src={logoSuperEnsino} alt="Logo da Super Ensino" />
			</Globals.AlunoCard>
			<PerfilMainContainer>
				{userData?.role === 3 && (
					<PerfilInfoCard>
						<Scrollbars {...scrollbarProps}>
							<RepresentativeImage src={contactImage}></RepresentativeImage>
							<CardInfoContainer>
								<CardInfoLabel>Escola</CardInfoLabel>
								<CardInfo>{userData.nomeEscola}</CardInfo>
							</CardInfoContainer>
							<CardInfoContainer>
								<CardInfoLabel>Ano/Turma</CardInfoLabel>
								<CardInfo>{studentSerieTurma}</CardInfo>
							</CardInfoContainer>
							<CardInfoContainer last={!userData.matricula}>
								<CardInfoLabel>Turno</CardInfoLabel>
								<CardInfo style={{ textTransform: 'capitalize' }}>{userData.turno}</CardInfo>
							</CardInfoContainer>
							{
								userData.matricula &&
								<CardInfoContainer last>
									<CardInfoLabel>Matrícula</CardInfoLabel>
									<NumberFormat renderText={(formattedValue: string) => <CardInfo>{formattedValue}</CardInfo>} value={userData.matricula} displayType="text" format="#######-#"></NumberFormat>
								</CardInfoContainer>
							}
						</Scrollbars>
					</PerfilInfoCard>
				)}
				<PerfilInfoCard>
					<Scrollbars {...scrollbarProps}>

						<RepresentativeImage src={padlockImage}></RepresentativeImage>
						{
							userData?.email &&
							<CardInfoContainer>
								<CardInfoLabel>E-mail</CardInfoLabel>
								<Edit onClick={() => setOpenModal(ModalName.CHANGE_EMAIL)}></Edit>
								<CardInfo>{userData?.email}</CardInfo>
							</CardInfoContainer>
						}
						<CardInfoContainer>
							<CardInfoLabel>Login</CardInfoLabel>
							<CardInfo>{userData?.username}</CardInfo>
						</CardInfoContainer>
						<CardInfoContainer last={!userData?.telefone}>
							<CardInfoLabel>Senha</CardInfoLabel>
							<Edit onClick={() => setOpenModal(ModalName.CHANGE_PASSWORD)}></Edit>
							<CardInfo style={{ fontWeight: 'bold' }}>********</CardInfo>
						</CardInfoContainer>
						<CardInfoContainer last>
							<CardInfoLabel>Telefone</CardInfoLabel>
							<Edit onClick={() => setOpenModal(ModalName.CHANGE_TELEFONE)}></Edit>
							<NumberFormat renderText={(formattedValue: string) => <CardInfo>{formattedValue || userData?.telefone}</CardInfo>} value={userData?.telefone} displayType="text" format="(##) #####-####"></NumberFormat>
						</CardInfoContainer>
					</Scrollbars>
				</PerfilInfoCard>
				{
					userData?.role === 3 && (
						<PerfilInfoCard>
							<Scrollbars {...scrollbarProps}>

								<RepresentativeImage src={responsavelImage}></RepresentativeImage>
								<CardInfoContainer last>
									<CardInfoLabel>Responsáveis</CardInfoLabel>
									<ResponsavelUsername>{userData?.responsavel1Username}</ResponsavelUsername>
									{userData?.responsavel2Username && <ResponsavelUsername last>{userData?.responsavel2Username}</ResponsavelUsername>}
								</CardInfoContainer>
							</Scrollbars>
						</PerfilInfoCard>
					)
				}

			</PerfilMainContainer>
			<PasswordChangeModal
				open={openModal === ModalName.CHANGE_PASSWORD}
				onClose={closeModal}
			></PasswordChangeModal>
			<AvatarSelectModal
				open={openModal === ModalName.CHANGE_AVATAR}
				onClose={closeModal}
			></AvatarSelectModal>
			<ChangeEmailModal
				open={openModal === ModalName.CHANGE_EMAIL}
				onClose={closeModal}
			></ChangeEmailModal>
			<ChangeTelefoneModal
				open={openModal === ModalName.CHANGE_TELEFONE}
				onClose={closeModal}
			></ChangeTelefoneModal>
			<img
				id="topCriancas"
				style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
				src={topRightImage}
				alt="Crianças serelepes"
			></img>
		</>
	);
};

export default Perfil
