import styled from 'styled-components';

import leftImage from '../../assets/img/menina-com-globo-terrestre.png';
import rightImage from '../../assets/img/menina-segurando-mochila-e-cadeirante.svg';

const Container = styled.div`
	background: url(${rightImage}) no-repeat right bottom, url(${leftImage}) no-repeat left bottom,
		transparent linear-gradient(8deg, #078cff 0%, #13e3dd 100%) 0% 0% no-repeat padding-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const MainWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Main = styled.main`
	box-shadow: 0px 3px 20px #0013ff26;
	border-radius: 30px;
	background: #f2fafd 0% 0% no-repeat padding-box;
	color: #2f2f30;
	font-size: 20px;
	font-weight: 500;

	width: 542px;
	height: 594px;

	display: flex;
	align-items: center;
	position: relative;

	@media (max-height: 720px) {
		padding: 30px 48px;
		width: 500px;

		img {
			width: 87px;
		}

		p {
			margin-bottom: 18px;
		}
	}

	@media (max-height: 766px) {
		transform: scale(0.8);
		top: -40px;
	}

	top: -70px;
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 24px;
`;

const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const SpanNomeAluno = styled.span`
	color: #09bdf0;
	text-transform: capitalize;
`;

// Representa o texto de diálogo com o aluno ("O que vamos estudar?", "Escola uma matéria!")
const DialogText = styled.p`
	margin-bottom: 23px;
`;

const ModeButtonsContainer = styled.div`
	display: grid;
	column-gap: 16px;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 34px;

	@media (max-height: 720px) {
		margin-bottom: 28px;
	}
`;

const DisciplinaButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 400px;
	justify-content: space-between;
`;

const DisciplinaButtonItem = styled.div`
	display: flex;
	width: 50%;
	flex-grow: 1;
	justify-content: center;
	margin-bottom: 16px;
`;

const FiltrosContent = styled.div`
	display: grid;
	grid-template-columns: max-content;
	align-self: center;
	justify-content: center;
	justify-items: center;
	margin: auto;
	animation: animation 0.5s;

	@keyframes animation {
		from {
			transform: scale(0);
		}

		to {
			transform: scale(1);
		}
	}
`;

export {
	MainWrapper,
	Main,
	Container,
	SpanNomeAluno,
	LogoContainer,
	AlunoInfoContainer,
	ModeButtonsContainer,
	DialogText,
	DisciplinaButtonsContainer,
	DisciplinaButtonItem,
	FiltrosContent,
};
