import styled from 'styled-components';

import { ContentGrid } from '../../globals';

export const DesempenhoGrid = styled(ContentGrid)`
	background: no-repeat right bottom, transparent 0% 0% no-repeat padding-box;
	position: relative;
	z-index: 1;
`;

export const DadosGerais = styled.main`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	row-gap: 16px;
	justify-content: center;
	top: -154px;
	left: 16px;
	border-radius: 30px;
	padding-top: 64px;
	padding-bottom: 40px;
	position: absolute;
	z-index: 1;
`;

export const UnidadeTematica = styled.main`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	row-gap: 16px;
	justify-content: center;
	top: -40px;
	left: 351px;
	padding-top: 64px;
	padding-bottom: 40px;
	position: absolute;
	z-index: 1;
	@media (max-width: 1280px) {
		width: 310px;
	}
	
`;
