import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import chevronDown from '../../assets/img/chevron-down-solid.svg';

export default () => (
	<IconButton className="MuiSelect-icon" style={{ top: 0 }}>
		<img src={chevronDown} alt="Dropdown arrow" />
	</IconButton>
);
