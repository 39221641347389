import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	border: 2px solid #09bdf0;
	width: 169px;
	height: 44px;
	cursor: pointer;

	background: transparent;
	position: relative;
	overflow: hidden;

	&:hover {
		color: #fff;
		&:after {
			top: 100%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		background: #09bdf0;
		width: 150%;
		padding-top: 150%;
		transition: 0.25s ease;
		border-radius: 999px;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 410%;
	}
`;

const SpanText = styled.span`
	height: 100%;
	width: 100%;
	font-size: 16px;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
`;

export { Container, SpanText };
