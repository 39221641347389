import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Button,
} from '@material-ui/core';

import { ActionButton, ActionsModalCreators } from '../../store/ducks/actionsModal';

import closeModalIcon from '../../assets/img/grey-times-solid.svg';

const LogoutModal = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const open = useSelector((state: any) => state.actionsModal.open);
	const title = useSelector((state: any) => state.actionsModal.title);
	const actionButtons: ActionButton[] = useSelector((state: any) => state.actionsModal.actionButtons);
	const message: string = useSelector((state: any) => state.actionsModal.message);

	const handleClose = () => dispatch(ActionsModalCreators.closeActionsModal())

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton
								onClick={handleClose}
								style={{ marginBottom: 2 }}
							>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>{title}</p>}
					/>
					<CardContent className={classes.cardContent}>
						<p style={{textAlign: 'center', marginBottom: 16}}>{message}</p>
						{
							actionButtons.map(button => (
								<Button
									onClick={button.callback}
									className={classes.sairButton}
									key={button.label}
								>
									{button.icon}
									{button.label}
								</Button>
							))
						}
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default LogoutModal

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			borderRadius: 20,
			background: '#f7fcfe',
			maxWidth: 500
		},
		cardHeader: {
			height: 50,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			padding: '40px 70px',
		},
		sairButton: {
			color: 'white',
			width: 169,
			height: 44,
			borderRadius: 8,
			background: 'transparent linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%) 0% 0% no-repeat padding-box',
			fontSize: 14,
			fontWeight: 'bold',
			marginBottom: 12,
		},
		backToAppButton: {
			fontSize: 14,
			marginBottom: 16,
			fontWeight: 500,
			color: '#09BDF0',
			textTransform: 'none',
		},
		divider: {
			height: 16
		}
	})
);
