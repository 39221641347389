import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent, IconButton } from '@material-ui/core';

import closeModalIcon from '../../assets/img/grey-times-solid.svg';
import unavailableDesempenho from '../../assets/img/nao-ha-desempenho.png';

export default (props: { open: boolean; onClose: () => void }) => {
	const classes = useStyles();

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.open}
			onClose={props.onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={props.onClose}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
					/>
					<CardContent className={classes.cardContent}>
						<div className={classes.assetContainer}>
							<img src={unavailableDesempenho} alt="Sem desempenho" />
							<p className={classes.title}>Área ainda não disponível :(</p>
						</div>
						<p className={classes.message}>
							Assim que você fizer exercícios e assistir a aula dessa disciplina, a área estará
							disponível!
						</p>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			width: 364,
			borderRadius: 20,
		},
		cardHeader: {
			padding: '16px 16px 0',
		},
		cardContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			padding: '0 26px',
			'&:last-child': {
				paddingBottom: 48,
			},
		},
		assetContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		title: {
			fontSize: 20,
			fontWeight: 'bold',
			color: '#07B0FF',
			width: 169,
			marginLeft: 12,
			height: 57,
		},
		message: {
			marginTop: 30,
			textAlign: 'center',
			lineHeight: 1.5,
		},
	})
);
