/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, createRef, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory, useLocation } from 'react-router-dom';
import { shuffle } from 'lodash';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import SideCard from './SideCard';
import FeedbackModal from './FeedbackModal';

import { Creators as BackgroundCreators } from '../../store/ducks/background';
import { NavBarCreators } from '../../store/ducks/navBar';
import { ModalCreators } from '../../store/ducks/modal';

import useApi from '../../hooks/useApi';

import { useAppContext } from '../../contexts/AppContext';

import { Alternativa, Aula, ExercicioAula, ExerciseListStatus, ResponderExercicioData, UnidadeTematica } from '../../services';

import topRightImage from '../../assets/img/menino-estudando292x169.png';
import respostaErrada from '../../assets/img/resposta-errada.png';
import respostaCerta from '../../assets/img/resposta-certa.png';
import bannerRespostaCorreta from '../../assets/img/barra-vc-acertou.png';
import bannerRespostaErrada from '../../assets/img/barra-vc-errou.png';

import { Alternativas, AlternativasItem, AlternativasItemEnunciado, AlternativasItemIndex, ButtonsContainer, ConfirmarButton, CustomizedGeneralButton, ExercicioContent, ExercicioId, ExercicioLabel, FeedbackAsset, FeedbackContainer, JustificativaButton, JustificativaContainer, Question, QuestionEnunciado, QuestionEnunciadoWrapper } from './style';

type LocationState = {
	aula: Aula,
	unidadeTematica: UnidadeTematica,
	index: number
}

export enum AlternativaStatus {
	READY_TO_SELECT_OR_SELECTED = 0,
	CORRECT = 1,
	WRONG = 2,
	NOT_SELECTED = 3
}

enum ExerciseStatus {
	LIST_NOT_STARTED = 0, // Lista ainda não foi iniciada
	LIST_STARTED_AND_NOT_ANSWERED_EXERCISE = 1, // Lista foi iniciada e o exercício
	LIST_STARTED_AND_ANSWERED_EXERCISE = 2, // Lista foi iniciada e o exercício já foi respondido 
	LIST_BEING_REDONE_AND_TRIES_LEFT = 3, // Lista está sendo refeita e ainda há tentativas restantes para este exercício
	LIST_BEING_REDONE_AND_NO_TRIES_LEFT = 4, // Lista está sendo refeita e não há mais tentativas restantes para este exercício
	LIST_FINISHED = 5, // Lista finalizada
	UNDEFINED = 6
}

const ExercicioPage = () => {
	document.title = 'Super Aluno | Exercícios';

	const { userRole } = useAppContext()

	const api = useApi()

	const dispatch = useDispatch();

	const history = useHistory();
	const { state } = useLocation<LocationState>()
	const { isLoading, data: exercicioData } = useQuery(['exercicio', state.aula.exercicios[state.index].id], () => api.getExercicio(state.aula.exercicios[state.index].id)) 
	const { data: aulasData } = useQuery(['aulas', state.unidadeTematica.id], () => api.getAulas(state.unidadeTematica.id))
	const mutation = useMutation((data: ResponderExercicioData) => api.responderExercicio(data))
	const queryClient = useQueryClient()

	const [feedbackIsOpen, setFeedbackIsOpen] = useState(false);
	const [feedbackImage, setFeedbackImage] = useState('')
	const [exerciseStatus, setExerciseStatus] = useState<ExerciseStatus>(ExerciseStatus.UNDEFINED)
	const [feedbackModalIsOpen, setFeedbackModalOpen] = useState(false)

	const [userScrolled, setUserScrolled] = useState(false);
	const [alternativaSelecionadaIndex, setAlternativaSelecionadaIndex] = useState(-1)

	const scrollbarRef = useRef<Scrollbars>(null);
	const justificativaRef = createRef<HTMLDivElement>();
	const confirmarButtonRef = createRef<HTMLButtonElement>();

	const indexAlpha = ['a', 'b', 'c', 'd'];

	const exercicio = exercicioData?.data
	const aula = aulasData?.data.find((aula: Aula) => aula.id === state.aula.id)

	const justificativa = exercicio?.alternativas?.find((alternativa: Alternativa) => alternativa.correta)?.explicacao;

	const hasNoTriesLeft = exercicio && exercicio?.tries > 1

	const isRespondido = !!exercicio?.ultima_resposta;
	const alternativaSelecionada = exercicio?.alternativas[alternativaSelecionadaIndex]

	/**
	 * A justificativa é renderizada quando há justificativa para o exercício e, 
	 * quando não há mais tentativas disponíveis para o exercício ou quando a lista foi finalizada
	 */
	const justificativaIsVisible = justificativa && (hasNoTriesLeft || aula?.status === ExerciseListStatus.FINISHED)

	/**
	 * O aluno pode selecionar alternativa quando:
	 * - Ele ainda não tiver iniciado a lista
	 * - Ele tiver iniciado a lista e ele ainda não tiver respondido o exercício
	 * - Ele estiver refazendo a lista e ele possuir tentativas restantes no exercício
	 */
	const canSelectAlternativa = [ExerciseStatus.LIST_NOT_STARTED, ExerciseStatus.LIST_STARTED_AND_NOT_ANSWERED_EXERCISE,
		ExerciseStatus.LIST_BEING_REDONE_AND_TRIES_LEFT].includes(exerciseStatus)

	/**
	 * O botão Confirmar ficará visível quando:
	 * - Uma alternativa tiver sido selecionada (quando isRespondido, sempre vai ocorrer)
	 * - Esse exercício puder ter alternativa selecionada no momento
	 */
	const confirmarButtonIsVisible = alternativaSelecionada && canSelectAlternativa

	// O botão próximo só funciona se o index do exercício for menor que a quantidade de exercícios - 1
	// Por ex: a lista tem 5 exercícios, o botão próximo só ficará disponível se o index for até 5 - 1 (4)
	const proximoButtonIsVisible = aula && state.index < aula.exercicios.length - 1

	// O  botão anterior só ficará disponível a partir da questão 1 em diante
	const anteriorButtonIsVisible = aula && state.index > 0

	const relatorioAvailable = aula?.exercicios.every((exercicio: ExercicioAula) => exercicio.respondido) 

	/* ========== useEffects ========== */

	useEffect(() => {
		dispatch(BackgroundCreators.setBackgrounds([]));
		dispatch(NavBarCreators.setTitle(state.unidadeTematica.nome));

		const rootEl = document.getElementById('root');
		if (rootEl) {
			rootEl.style.height = `auto`;
		}
		resizeHanlder();
		window.addEventListener('resize', resizeHanlder);
	}, []);

	const summary = useMemo(() => {
		const aula = aulasData?.data?.find((aula: Aula) => aula.id === state.aula.id)

		const summary = {acertos: 0, erros: 0}

		aula?.exercicios.forEach((exercicio: ExercicioAula) => {
			if (exercicio.acertou) summary.acertos++
			else summary.erros++
		})
		
		return summary
	}, [aulasData])

	useEffect(() => {
		if (feedbackIsOpen) setTimeout(() => setFeedbackIsOpen(false), 7000);
	}, [feedbackIsOpen]);
	
	useEffect(() => {
		// Ao passar para outro exercício, scrolla para cima e volta ao estado inicial,
		// como se o user ainda não tivesse scrollado
		setUserScrolled(false);
		scrollbarRef.current?.scrollToTop();	

		if (!exercicio) return

		if (aula?.status === null || aula?.status === undefined) setExerciseStatus(ExerciseStatus.LIST_NOT_STARTED)
		else if (aula?.status === ExerciseListStatus.STARTED && !exercicio.ultima_resposta) setExerciseStatus(ExerciseStatus.LIST_STARTED_AND_NOT_ANSWERED_EXERCISE)
		else if (aula?.status === ExerciseListStatus.STARTED && exercicio.ultima_resposta) setExerciseStatus(ExerciseStatus.LIST_STARTED_AND_ANSWERED_EXERCISE)
		else if (aula?.status === ExerciseListStatus.REDOING && exercicio.tries < 2) setExerciseStatus(ExerciseStatus.LIST_BEING_REDONE_AND_TRIES_LEFT)
		else if (aula?.status === ExerciseListStatus.REDOING && exercicio.tries > 1) setExerciseStatus(ExerciseStatus.LIST_BEING_REDONE_AND_NO_TRIES_LEFT)
		else if (aula?.status === ExerciseListStatus.FINISHED) setExerciseStatus(ExerciseStatus.LIST_FINISHED)
		else setExerciseStatus(ExerciseStatus.UNDEFINED)
	}, [exercicio, aula?.status]);

	useEffect(() => {
		if (!exercicio) return

		/**
		 * Se:
		 * - A lista não tiver sido iniciada
		 * - A lista tiver sido iniciada mas o exercício não tiver sido respondido ainda
		 * - A lista estiver sendo refeita e ainda resta uma tentativa para o exercício
		 * ... deixa a alternativa desselecionada
		 */
		if (canSelectAlternativa) setAlternativaSelecionadaIndex(-1)
		else {
			// Seta a alternativa que o aluno respondeu
			// Procura o index (0 a 3 (4 alternativas)) da alternativa respondida no array de exercicios da aula
			const alternativaRespondidaIndex = exercicio.alternativas.findIndex((alternativa: Alternativa) => alternativa.id === exercicio?.ultima_resposta)

			setAlternativaSelecionadaIndex(alternativaRespondidaIndex)
		}
	}, [canSelectAlternativa, exercicio])

	useEffect(() => {
		if (isLoading) dispatch(ModalCreators.open('LOADING'))
		else dispatch(ModalCreators.close())
	}, [isLoading])

	/* ========== Handlers ========== */

	// Passa para o próximo exercício ainda não resolvido
	const handleProximoClick = useCallback(() => {
		if (!proximoButtonIsVisible) return

		setFeedbackIsOpen(false)

		const nextExerciseIndex = state.index + 1

		history.replace('/exercicio', { ...state, index: nextExerciseIndex })
	}, [state, aula, proximoButtonIsVisible]);

	const handleAnteriorClick = useCallback(() => {
		if (!anteriorButtonIsVisible) return

		setFeedbackIsOpen(false)

		const previousExerciseIndex = state.index - 1

		history.replace('/exercicio', { ...state, index: previousExerciseIndex })
	}, [state, aula, anteriorButtonIsVisible]);

	const handleConfirmarClick = useCallback(() => {
		if (mutation.isLoading) return

		if (exercicio && alternativaSelecionada && aula) {
			if (userRole !== 3) {
				setFeedbackImage(alternativaSelecionada?.correta ? respostaCerta : respostaErrada)
				setFeedbackIsOpen(true);
				
				queryClient.setQueryData(['exercicio', exercicio.id], {
					data: {
						...exercicio,
						tries: 2,
						ultima_resposta: alternativaSelecionada.id
					}						
				})

				const aulasDataDraft = {...aulasData}
				const aulaDraft = aulasDataDraft.data?.find((aula: Aula) => aula.id === state.aula.id)

				if (aulaDraft) {
					aulaDraft.exercicios[state.index].respondido = true
					aulaDraft.exercicios[state.index].acertou = alternativaSelecionada.id === exercicio.alternativas.find((alternativa: Alternativa) => alternativa.correta)?.id
					aulaDraft.exercicios[state.index].tries = 2
				}
				
				queryClient.setQueryData(['aulas', state.unidadeTematica.id], aulasDataDraft)

				return
			}

			// Responde o exercício e o atualiza, assim como também atualiza a aula desse exercício
			mutation.mutate({ exercicio: exercicio.id, alternativa: alternativaSelecionada.id }, {
				onSuccess: () => {
					setFeedbackImage(alternativaSelecionada?.correta ? respostaCerta : respostaErrada)
					setFeedbackIsOpen(true);

					const aboutToFinish = aula.exercicios.filter((exercicio: ExercicioAula) => exercicio.respondido).length === aula.exercicios.length - 1
					
					queryClient.setQueryData(['exercicio', exercicio.id], {
						data: {
							...exercicio,
							tries: exercicio.tries + 1,
							ultima_resposta: alternativaSelecionada.id
						}						
					})

					/**
					 * Se a aula ainda não tem um status, ou seja, não tem um exercist_list_student, busca a aula novamente
					 * para retornar o novo status, que no caso vai retornar 0 (STARTED), e retornar o exercist_list_student
					 */
					if (aula.status === null) queryClient.invalidateQueries(['aulas', state.unidadeTematica.id])
					else {
						const aulasDataDraft = {...aulasData}
						const aulaDraft = aulasDataDraft.data?.find((aula: Aula) => aula.id === state.aula.id)

						if (aulaDraft) {
							aulaDraft.exercicios[state.index].respondido = true
							aulaDraft.exercicios[state.index].acertou = alternativaSelecionada.id === exercicio.alternativas.find((alternativa: Alternativa) => alternativa.correta)?.id
							aulaDraft.exercicios[state.index].tries++
						}					

						queryClient.setQueryData(['aulas', state.unidadeTematica.id], aulasDataDraft)
					}					

					// Se o aluno estava prestes a terminar a lista, ou seja, se foi o último exercício restante da lista que ele respondeu
					// volta para a página das unidades temáticas, mostra o alert e recarrega as aulas da unidade temática.
					// As aulas das quais o aluno respondeu todos os exercícios irão retornar em uma ordem diferente.
					if (aboutToFinish) 							
						setFeedbackModalOpen(true)
				}
			})			
		}
	}, [exercicio, alternativaSelecionada, state, mutation.isLoading, summary]);

	const handleVerRelatorio = useCallback(() => {
		if (userRole !== 3) {
			dispatch(
				ModalCreators.open(
					'UNAUTHORIZED_ACCESS',
					'Não é possível ver o relatório :(',
					'Somente os alunos possuem acesso ao relatório.'
				)
			);
			return
		}

		history.push('/relatorio-desempenho', { aula });
	}, [aula]);

	/* ========== Renderers ========== */

	const renderBanner = useCallback(() => {
		if (userRole !== 3) return

		if (canSelectAlternativa) return

		const acertou = aula?.exercicios[state.index].acertou

		return (
			<img
				src={acertou ? bannerRespostaCorreta : bannerRespostaErrada}
				alt="Banner resposta"
			/>
		);
	}, [aula, canSelectAlternativa, state, userRole])

	/* ========== Utils ========== */
	const showJustificativaButton = useMemo(() => {
		/**
		 * O botão que scrolla a página para a seção da justificativa aparece quando o aluno:
		 * - Ainda não tiver scrollado a página
		 * - Tiver scroll disponível para chegar até a seção da justificativa
		 * - Não tiver mais tentativas disponíveis no exercício, situação na qual a justificativa pode ser exibida
		 */

		const clientHeight = document.body.offsetHeight;
		const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;
		const scrollHeight = scrollbarRef.current?.getScrollHeight();

		const justificativaHasScroll = !!scrollHeight && scrollHeight > clientHeight - headerHeight;
		
		return !userScrolled && justificativaHasScroll && hasNoTriesLeft
	}, [userScrolled, hasNoTriesLeft, scrollbarRef.current?.getScrollHeight()])

	const handleSelectAlternativa = useCallback((index: number) => {
		if (canSelectAlternativa) setAlternativaSelecionadaIndex(index)	
	}, [canSelectAlternativa])

	const getAlternativaStatus = useCallback((alternativa: Alternativa, index: number) => {
		/**
		 * Se o status do exercício for LIST_NOT_STARTED, LIST_STARTED_AND_NOT_ANSWERED_EXERCISE ou LIST_BEING_REDONE_AND_TRIES_LEFT
		 * Nesses 3 status alguma das alternativas pode ser selecionada, e nesse caso só é possível a alternativa ter dois status:
		 * READY_TO_SELECT_OR_SELECTED e NOT_SELECTED
		 */
		if (canSelectAlternativa) {
			if (!alternativaSelecionada || alternativaSelecionadaIndex === index) return AlternativaStatus.READY_TO_SELECT_OR_SELECTED
			else return AlternativaStatus.NOT_SELECTED
		}
			
		// Se o status do exercício for LIST_STARTED_AND_ANSWERED_EXERCISE
		// Destaca apenas a que o aluno respondeu e indica se está correta ou náo
		if (exerciseStatus === ExerciseStatus.LIST_STARTED_AND_ANSWERED_EXERCISE) 
			if (alternativaSelecionadaIndex === index) return alternativaSelecionada?.correta ? AlternativaStatus.CORRECT : AlternativaStatus.WRONG
			else return AlternativaStatus.NOT_SELECTED

		// Só restou os seguintes status do exercício: LIST_BEING_REDONE_AND_NO_TRIES_LEFT e LIST_FINISHED 
		// Destaca apenas a que o aluno respondeu ou a correta
		if (alternativaSelecionadaIndex === index) return alternativa.correta ? AlternativaStatus.CORRECT : AlternativaStatus.WRONG
		else return alternativa.correta ? AlternativaStatus.CORRECT : AlternativaStatus.NOT_SELECTED
	}, [alternativaSelecionadaIndex, alternativaSelecionada, canSelectAlternativa, exerciseStatus, userRole])

	const redoExerciseList = useCallback(() => {
		const status = ExerciseListStatus.REDOING

		const aulasDataDraft = {...aulasData}
		const aulaDraft = aulasDataDraft.data?.find((aula: Aula) => aula.id === state.aula.id)

		if (!aulaDraft) return

		api.updateExerciseListStatus(aulaDraft.exercise_list_student, status)
			.then(() => {
				if (aulaDraft) {
					aulaDraft.status = status
					aulaDraft.exercicios = shuffle(aulaDraft.exercicios)
					queryClient.setQueryData(['aulas', state.unidadeTematica.id], aulasDataDraft)
				}

				history.replace('/exercicio', { ...state, aula: aulaDraft, index: 0 })
				setFeedbackModalOpen(false)
			})
			.catch(() => console.log('Houve um erro ao atualizar o status da lista de exercícios'))
	}, [aulasData, state])
	
	const finishExerciseList = useCallback(() => {
		const status = ExerciseListStatus.FINISHED

		const aulasDataDraft = {...aulasData}
		const aulaDraft = aulasDataDraft.data?.find((aula: Aula) => aula.id === state.aula.id)

		if (!aulaDraft) return

		api.updateExerciseListStatus(aulaDraft.exercise_list_student, status)
			.then(() => {
				if (aulaDraft) {
					aulaDraft.status = status
					queryClient.setQueryData(['aulas', state.unidadeTematica.id], aulasDataDraft)
				}

				history.goBack()
			})
			.catch(() => console.log('Houve um erro ao atualizar o status da lista de exercícios'))
	}, [aulasData, state])

	const feedbackModal = useMemo(() => {
		const aula = aulasData?.data?.find((aula: Aula) => aula.id === state.aula.id)

		const summary = {acertos: 0, erros: 0}

		aula?.exercicios.forEach((exercicio: ExercicioAula) => {
			if (exercicio.acertou) summary.acertos++
			else summary.erros++
		})
		
		return (
			<FeedbackModal {...summary} open={feedbackModalIsOpen} onClose={() => setFeedbackModalOpen(false)} 
				redoExerciseList={redoExerciseList} finishExerciseList={finishExerciseList} />
		)
	}, [redoExerciseList, finishExerciseList, feedbackModalIsOpen, aulasData])

	/* ========== Layout ========== */
	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById('root');
		const exercicioContentEl = document.getElementById('exercicioContent');
		const topRightImage = document.getElementById('topRightImage');
		const aulaSearchFieldContainer = document.getElementById('aula-search-field-container');

		if (widthWindow <= 1000) {
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max(
				body.scrollHeight,
				body.offsetHeight,
				html.clientHeight,
				html.scrollHeight,
				html.offsetHeight
			);

			if (rootEl) {
				rootEl.style.height = `${height}px`;
			}

			if (topRightImage) {
				topRightImage.style.width = `25%`;
			}

			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = '-400px';
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`;
			}

			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = '0';
			}
		}

		const browserZoomLevel = ((window.outerWidth - 10) / window.innerWidth) * 100;
		if (browserZoomLevel >= 148) {
			if (exercicioContentEl) {
				exercicioContentEl.style.width = `340px`;
			}
		} else {
			if (exercicioContentEl) {
				exercicioContentEl.style.width = `640px`;
			}
		}
	};

	const removeTextAlignJustify = (value: string) => {
		return value ? value?.replace(/(text-align):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const removeLineHeight = (value: string) => {
		return value ? value?.replace(/(line-height):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const removeColor = (value: string) => {
		return value ? value?.replace(/(color):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const removeFontSize = (value: string) => {
		return value ? value?.replace(/(font-size):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const removeFontFamily = (value: string) => {
		return value ? value?.replace(/(font-family):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const removeFontWeight = (value: string) => {
		return value ? value?.replace(/(font-weight):(.*?)((;)|(;')|(;")|(")|('>))/g, '') : '';
	};

	const getExercicioEnunciado = () => {
		let enunciado = exercicio?.enunciado ?? '';
		enunciado = removeColor(enunciado);
		enunciado = removeLineHeight(enunciado);
		enunciado = removeFontSize(enunciado);
		enunciado = removeFontFamily(enunciado);
		enunciado = removeFontWeight(enunciado);
		return removeTextAlignJustify(enunciado);
	};

	const getExercicioJustificativa = () => {
		let justificativaFinal = justificativa as string;
		justificativaFinal = removeLineHeight(justificativaFinal);
		justificativaFinal = removeColor(justificativaFinal);
		justificativaFinal = removeFontSize(justificativaFinal);
		justificativaFinal = removeFontFamily(justificativaFinal);
		justificativaFinal = removeFontWeight(justificativaFinal);
		return removeTextAlignJustify(justificativaFinal);
	};

	const getExercicioAlternativa = (value: string) => {
		value = removeColor(value);
		value = removeLineHeight(value);
		value = removeFontSize(value);
		value = removeFontFamily(value);
		value = removeFontWeight(value);
		return removeTextAlignJustify(value);
	};

	return (
		<>
			{feedbackModal}
			<SideCard relatorioAvailable={!!relatorioAvailable} />
			<Scrollbars
				autoHide
				style={{
					background:
						'transparent linear-gradient(204deg, #ffffff 0%, #ffffff 57%, #c3eefa 100%) 0% 0% no-repeat padding-box',
				}}
				onScrollFrame={({ top }) => {
					const topJustificativa = justificativaRef.current?.getBoundingClientRect().top;
					const headerHeight = document.getElementsByTagName('header')[0].offsetHeight + 20;

					isRespondido &&
						alternativaSelecionada &&
						!alternativaSelecionada.correta &&
						!userScrolled &&
						!!topJustificativa &&
						(topJustificativa < headerHeight || top === 1) &&
						setUserScrolled(true);
				}}
				ref={scrollbarRef}
			>
				{
					exercicio ? (
						<ExercicioContent id="exercicioContent">
							{renderBanner()}
							<>
								<ExercicioId>{`(${exercicio?.id}) `}</ExercicioId>
								<ExercicioLabel>
									{`Exercício ${state.index + 1}/${state.aula.exercicios.length}`}
								</ExercicioLabel>
							</>
							<QuestionEnunciadoWrapper>
								<QuestionEnunciado text={getExercicioEnunciado()} />
							</QuestionEnunciadoWrapper>
							<Question>
								<Alternativas confirmed={!canSelectAlternativa}>
									{exercicio?.alternativas?.map((alternativa: Alternativa, index: number) => (
										<AlternativasItem
											key={alternativa.id}
											onClick={() => handleSelectAlternativa(index)}
											status={getAlternativaStatus(alternativa, index)}
										>
											<AlternativasItemIndex>{indexAlpha[index]}</AlternativasItemIndex>
											<AlternativasItemEnunciado
												text={getExercicioAlternativa(alternativa.enunciado)}
											/>
										</AlternativasItem>
									))}
								</Alternativas>
								{justificativaIsVisible && (
									<JustificativaContainer ref={justificativaRef}>
										<p
											style={{
												fontSize: 20,
												color: '#2F2F30',
												marginBottom: 8,
											}}
										>
											Justificativa
										</p>
										<div
											style={{ fontSize: 16 }}
											dangerouslySetInnerHTML={{
												__html: getExercicioJustificativa(),
											}}
										/>
									</JustificativaContainer>
								)}
							</Question>
						</ExercicioContent>
					) : null
				}
			</Scrollbars>
			<FeedbackContainer>
				<FeedbackAsset
					src={feedbackImage}
					animating={feedbackIsOpen}
				></FeedbackAsset>
			</FeedbackContainer>
			<ButtonsContainer>
				<JustificativaButton
					onClick={() => {
						justificativaRef.current?.scrollIntoView({ behavior: 'smooth' });
						setUserScrolled(true);
					}}
					visible={showJustificativaButton}
				></JustificativaButton>
				{relatorioAvailable ? (
					<CustomizedGeneralButton visible onClick={handleVerRelatorio}>
						Ver relatório
					</CustomizedGeneralButton>
				) : null}
				{anteriorButtonIsVisible ? (
					<CustomizedGeneralButton visible onClick={handleAnteriorClick}>
						Anterior
					</CustomizedGeneralButton>
				) : null}
				{proximoButtonIsVisible ? (
					<CustomizedGeneralButton visible onClick={handleProximoClick}>
						Próximo
					</CustomizedGeneralButton>
				) : null}
				{confirmarButtonIsVisible ? (
					<ConfirmarButton
						ref={confirmarButtonRef}
						visible={confirmarButtonIsVisible}
						onClick={handleConfirmarClick}
						isLoading={mutation.isLoading}
					>
						Confirmar
					</ConfirmarButton>
				) : null}
			</ButtonsContainer>
			<img
				style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
				src={topRightImage}
				id="topRightImage"
				alt="Menino estudando com notebook"
			></img>
		</>
	);
};

export default ExercicioPage