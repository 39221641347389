import { createActions, createReducer } from 'reduxsauce';

interface Actions {
    OPEN: string;
    CLOSE: string;
}

interface Creators {
    open: (name: ModalNameType, title?: string, message?: string, callback?: () => void) => any;
    close: () => any;
}

export enum ModalName {
    AJUDA = 'AJUDA',
    ALERT = 'ALERT',
    LOADING = 'LOADING',
    LOGOUT = 'LOGOUT',
    OFFLINE = 'OFFLINE',
    UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS',
}

type ModalNameType = 'AJUDA' | 'LOADING' | 'LOGOUT' | 'OFFLINE' | 'UNAUTHORIZED_ACCESS' | 'ALERT'

export interface ModalStore {
    name?: ModalNameType
    title?: string
    message?: string
    callback?: () => void
}

const INITIAL_STATE: ModalStore = {};

const open = (_: any, action: any) => ({ name: action.name, title: action.title, message: action.message, callback: action.callback });
const close = () => INITIAL_STATE

export const { Types: ModalTypes, Creators: ModalCreators } = createActions<
    Actions,
    Creators
>({
    open: ['name', 'title', 'message', 'callback'],
    close: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
    [ModalTypes.OPEN]: open,
    [ModalTypes.CLOSE]: close
});
