import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Input from '../../../components/Input';

import { ModalCreators } from '../../../store/ducks/modal';

import {
	BelowLoginButton,
	BtnAjuda,
	BtnLogin,
	BtnLoginText,
	BtnRecoverPassword,
	ErrorMessage,
	Form,
	FormActions,
	FormGroup,
	MatriculaLoginMask,
	MsgBelowLogo,
} from './style';

import { getEnvironments } from '../../../utils';

const LoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [matriculaLogin, setMatriculaLogin] = useState('');
	const [isMatricula, setIsMatricula] = useState(false);
	const [retrievingEnvironments, setRetrievingEnvironments] = useState(false);
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [retrieveEnvironmentsError, setRetrieveEnvironmentsError] = useState(false);

	const nextButtonIsDisabled = useCallback(() => {
		if (isMatricula) return !(matriculaLogin.match(/\d/g)?.length === 8);
		else return !matriculaLogin;
	}, [matriculaLogin, isMatricula]);

	const setEnvironments = async (event: React.FormEvent) => {
		event.preventDefault();

		// setRetrievingEnvironments(true);

		getEnvironments(matriculaLogin.replace('-', ''))
			.then(environments => history.push('/password-input', { environments, matriculaLogin }))
			.catch(error => {
				setError(true);
				setErrorText(error.message);
			})
			.finally(() => setRetrievingEnvironments(false));
	};

	const handleChangeMatriculaLogin = (e: ChangeEvent<HTMLInputElement>) => {
		const isNumber = (value: string) => !isNaN(Number(value));
		const value = e.target.value;

		const valueLengthIsZero = value.match('\\d') === null;

		if (value.length === 1 && isNumber(value)) {
			setIsMatricula(true);
			setMatriculaLogin(e.target.value);
		} else if (valueLengthIsZero && isMatricula) {
			setIsMatricula(false);
			setMatriculaLogin('');
		} else setMatriculaLogin(e.target.value);

		if (retrieveEnvironmentsError) setRetrieveEnvironmentsError(false);
	};

	useEffect(() => {
		async function replaceQueryParam() {
			let isLoggedOut = window.localStorage.getItem('isLoggedOut');
			if (isLoggedOut === 'yes') {
				window.localStorage.removeItem('isLoggedOut');
				setTimeout(() => {
					window.location.pathname = '/';
				}, 500);
			}
		}
		replaceQueryParam();
	}, []);
	return (
		<>
			<MsgBelowLogo>
				Para começar a estudar
				<br /> faça seu login!
			</MsgBelowLogo>
			<ErrorMessage visible={error}>{errorText}</ErrorMessage>
			<FormGroup>
				<Form onSubmit={setEnvironments}>
					<MatriculaLoginMask
						mask={isMatricula ? '99999999' : ''}
						value={matriculaLogin}
						disabled={retrievingEnvironments}
						onChange={handleChangeMatriculaLogin}
					>
						{() => (
							<Input
								id="Matrícula ou login"
								error={error}
								onChange={handleChangeMatriculaLogin}
								labelWidth={135}
								style={{ marginBottom: 10 }}
								value={matriculaLogin}
							></Input>
						)}
					</MatriculaLoginMask>
					<FormActions>
						<BtnLogin
							disabled={nextButtonIsDisabled()}
							style={{
								backgroundColor: !nextButtonIsDisabled() ? '#09bdf0' : '#7fddf7',
								color: 'white',
							}}
							loading={retrievingEnvironments}
						>
							{retrievingEnvironments ? (
								<CircularProgress size={24} />
							) : (
								<>
									<div></div>
									<BtnLoginText>Próximo</BtnLoginText>
									<FontAwesomeIcon icon={faChevronRight} />
								</>
							)}
						</BtnLogin>
					</FormActions>
				</Form>
				<BelowLoginButton>
					<BtnRecoverPassword to="/password-recovery">Esqueci o login</BtnRecoverPassword>
					<BtnAjuda onClick={() => dispatch(ModalCreators.open('AJUDA'))}></BtnAjuda>
				</BelowLoginButton>
			</FormGroup>
		</>
	);
};

export default LoginForm;
