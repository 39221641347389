import React from 'react';
import { Link } from 'react-router-dom';

export default (props: IExercicioLink) => {
	const styles: React.CSSProperties = {
		width: 107,
		border: `2px solid #${props.correto ? '14E7C4' : 'FC6921'}`,
		borderRadius: 8,
		padding: 10,
		display: 'flex',
		justifyContent: 'space-around',
		textDecoration: 'none',
	};

	if (props.canSeeJustificativa)
		return (
			<Link to={props.to} style={styles}>
				{props.children}
			</Link>
		)

	return (
		<div style={styles}>
			{props.children}
		</div>
	);
};

interface IExercicioLink {
	correto?: boolean;
	to: string | object;
	children: any;
	canSeeJustificativa: boolean
}
