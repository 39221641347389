import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Backdrop, Fade, Card, CardHeader, CardContent, IconButton, Typography } from '@material-ui/core';

import { ModalCreators, ModalName } from '../../store/ducks/modal';

import useStyles from './styles';

import closeModalIcon from '../../assets/img/times-solid.svg';
import wppIcon from '../../assets/img/wpp-icon.svg';

const AjudaModal = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const isOpen = useSelector((state: any) => state.modal.name === ModalName.AJUDA);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={() => dispatch(ModalCreators.close())}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton
								onClick={() => dispatch(ModalCreators.close())}
								style={{ marginBottom: 2 }}
							>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>AJUDA</p>}
					/>
					<CardContent className={classes.cardContent}>
						<Typography style={{ fontSize: 20, marginBottom: 16, fontWeight: 500 }}>
							Dúvidas ou problemas com o uso do Super Aluno?
						</Typography>
						<Typography style={{ fontSize: 16, marginBottom: 16, fontWeight: 500 }}>
							Nos chame no WhatsApp ou ligue:
						</Typography>
						<a href="https://api.whatsapp.com/send?phone=559230426800" target="_blank" rel="noopener noreferrer" className={classes.whatsapp}>
							<img className={classes.wppIcon} src={wppIcon} alt="Zapzap" />
							<span>(92) 3042-6800</span>
						</a>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default AjudaModal
