import React from 'react';
import { useDispatch } from 'react-redux';

import { NavigationCreators } from '../../../store/ducks/navigation';

import { Videoaula } from '../../../services';

import { VideoAula, VideoAulaContainer, VideoAulaDetails, VideoAulaThumbnail, VideoAulaTitle } from './style';

const FoundAula = ({
	videoaula
}: FoundAulaProps) => {
	const dispatch = useDispatch();

	return (
		<VideoAulaContainer>
			<VideoAula
				to={{
					pathname: "/video-aula",
					state: {
						unidadeTematica: videoaula.unidade_tematica[0],
						videoaula
					}
				}}
				onClick={() => {
					dispatch(NavigationCreators.setDisciplina(videoaula.disciplina));
					dispatch(NavigationCreators.setModo(videoaula.is_prova_brasil ? 'PB' : 'SR'));
				}}
			>
				<VideoAulaThumbnail>
					<img src={videoaula.thumbnail.uri || ''} alt={`${videoaula.titulo}`} />
				</VideoAulaThumbnail>
				<VideoAulaDetails>
					<VideoAulaTitle text={videoaula.titulo} />
				</VideoAulaDetails>
			</VideoAula>
		</VideoAulaContainer>
	);
};

export default FoundAula;

interface FoundAulaProps {
	videoaula: Videoaula;
}
