import styled from 'styled-components';

import { makeStyles, createStyles, Button } from '@material-ui/core';

export const SubmitButton = styled(Button)`
	color: white;
	background-color: #09bdf0;
	border-radius: 8px;
	text-transform: none;
	font-size: 16px;
	height: 44px;

	&:hover {
		background-color: #09bdf0;
	}
`;

export const ErrorMessage = styled.p<{ visible: boolean }>`
	font-size: 12px;
	color: #fc2121;
	font-weight: 500;
	text-align: center;
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			borderRadius: 20,
			color: 'white',
		},
		cardHeader: {
			background: '#09BDF0 0% 0% no-repeat padding-box',
			height: 50,
			paddingLeft: 47,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			padding: '30px 85px 73px',
		},
		cardContentInputGroup: {
			display: 'grid',
			gridRowGap: 16,
			justifyContent: 'center',
		},
		instruction: {
			textAlign: 'center',
			marginTop: 20,
		},
		submitButton: {
			width: '100%',
			height: 44,
			border: 0,
			borderRadius: 8,
			color: 'white',
			font: 'Bold 16px/18px Ubuntu',
			textTransform: 'none',
		},
		checkCircleContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
		checkCircle: {
			width: 70,
		},
		errorMessage: {
			fontSize: 12,
			color: '#FC2121',
			textAlign: 'center',
			visibility: 'hidden',
		},
		errorMessageVisible: {
			visibility: 'visible',
		},
		form: {
			display: 'grid',
			rowGap: 10,
		},
	})
);

export default useStyles;
