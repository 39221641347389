import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';

export const RootContainer = styled.div``;

export const ExerciciosGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 210px);
	gap: 8px;
`;

export const Exercicio = styled(Link).attrs((props: any) => ({
	component: RouterLink,
	to: props.to,
	text: props.text,
	children: props.text,
	respondido: props.respondido,
	correto: props.correto,
}))`
	&& {
		color: #111;
		height: 36px;
		padding: 8px;
		width: 210px;
		text-align: center;
		font-size: 16px;
		transition: all 0.2s ease-in-out;
		text-decoration: none;
		border: 2px solid
			rgba(
				${props =>
					props.respondido
						? props.correto
							? '20, 231, 196, 0.6'
							: '252, 105, 33, 0.8'
						: '7, 176, 255, 0.6'}
			);
		border-radius: 8px;
		font-family: Ubuntu;
		font-size: 14px;
	}
`;

export const AssuntoContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;
`;

export const Assunto = styled.p`
	margin-left: 8px;
	color: #2f2f30;
	font-size: 14px;
	font-family: Ubuntu;
`;

export const ActionsContainer = styled.div<{columnsNumber: number}>`
	display: grid;
	column-gap: 2px;
	grid-template-columns: repeat(${props => props.columnsNumber}, 1fr);
`
