import styled from 'styled-components';

import { Select, MenuItem, makeStyles } from '@material-ui/core';

import { Globals } from '../../globals';
import SuperTooltip from '../../components/SuperTooltip';

import edit from '../../assets/img/perfil/edit.svg';

export const PerfilLabel = styled.p`
	margin: 16px auto;
	text-align: center;
	font-size: 14px;
`;

export const AlunoNome = styled.b`
	color: #07b0ff;
	font-size: 20px;
	text-transform: capitalize;
	word-wrap: break-word;
`;

export const PerfilInfoContainer = styled.div`
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
	margin-bottom: 32px;
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Tooltip = styled(SuperTooltip).attrs(() => ({
	title: 'Veja conteúdo de outros anos',
	placement: 'top',
}))``;


export const SeriesSelect = styled(Select)`
	width: 95px;
	height: 32px;
	border-radius: 8px;
	border: 1px solid #c5c5c5;
	background: #ffffff 0% 0% no-repeat padding-box;
	padding: 8px 0 8px 8px;
	font-size: 14px;
	color: #2f2f30;
`;

export const SerieOption = styled(MenuItem)`
	padding: 8px 19px;
	font-family: Ubuntu;
	font-size: 14px;
`;

export const ChangeSerieButton = styled(Globals.GeneralButton)`
	margin-top: 19px;
`;

export const PerfilMainContainer = styled.main`
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
	gap: 23px;
	position: absolute;
	left: 343px;
	margin-top: 27px;
	top: 162px;
	padding-bottom: 30px;
`;

export const PerfilInfoCard = styled.div`
	background: #f7fcfe 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 10px #07b0ff1a;
	border: 1px solid #07b0ff99;
	border-radius: 8px;
	padding: 34px 40px;
	width: 304px;
`;

export const RepresentativeImage = styled.img`
	margin: 0 auto 30px;
`;

export const CardInfoContainer = styled.div.attrs((props: any) => ({ last: props.last }))`
	border-bottom: ${props => (props.last ? '' : 'rgba(221, 216, 216, 0.5) 1px solid')};
	color: #2f2f30;
	font-weight: 500;
	font-family: Ubuntu;
	font-size: 16px;
	padding: 15px 0;
`;

export const CardInfoLabel = styled.p`
	font-weight: bold;
	color: #07b0ff;
	margin: 0 10px 8px 0;
	display: inline;
	margin-bottom: 8px;
`;

export const Edit = styled.img.attrs(() => ({ src: edit, alt: 'edit' }))`
	cursor: pointer;
	display: inline;
`;

export const CardInfo = styled.p`
	margin-top: 8px;
`;

export const ResponsavelUsername = styled.p<{ last?: boolean }>`
	padding: 15px 0;
	border-bottom: ${props => (props.last ? '' : 'rgba(221, 216, 216, 0.5) 1px solid')};
`;

export const useStyles = makeStyles(() => ({
	select: {
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},
	selectMenu: {
		top: 0,
	},
	avatarContainer: {
		background: '#ECE5F0 0% 0% no-repeat padding-box',
		borderRadius: '50%',
		width: 70,
		height: 70,
		justifyContent: 'center',
		display: 'flex',
	},
	iconButton: {
		backgroundColor: '#FC6921',
		width: 25,
		height: 25,
		padding: 0,
	},
	pencil: {
		width: 16,
		height: 16,
	},
}));
