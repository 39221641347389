/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Scrollbars } from 'react-custom-scrollbars';

import AlunoCard from '../../components/AulaExercicioAlunoCard';
import SuperTooltip from '../../components/SuperTooltip';
import VideoaulaSide from './VideoaulaSide';
import SuperEnsinoLoading from '../../components/SuperEnsinoLoading';

import { NavBarCreators } from '../../store/ducks/navBar';
import { AulasExerciciosTabsCreators } from '../../store/ducks/aulasExerciciosTabs';

import useApi from '../../hooks/useApi';

import { UnidadeTematica, Videoaula } from '../../services';

import { BtnExerciciosRelacionados, MainContainer, VideoAulaContainer, VideoContent, VideoStatusContainer, VideosUnidadeTematica, VideosUnidadeTematicaList, VimeoPlayer } from './style';

import unavailableVideo from '../../assets/img/unavailable-video.svg'

type LocationState = {
	unidadeTematica: UnidadeTematica
	videoaula: Videoaula
}

const Aula = () => {
	document.title = 'Super Aluno | Aula';

	const { state } = useLocation<LocationState>()

	const api = useApi()

	const { data } = useQuery(['videoaulas', state.unidadeTematica.id], () => api.getVideoaulas(state.unidadeTematica.id))
	const mutation = useMutation(api.assistirAula)
	const queryClient = useQueryClient()

	const videoaulas = data?.data.results ?? []

	const dispatch = useDispatch();

	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(false)
	const [videoaula, setVideoaula] = useState<Videoaula>()

	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById("root")
		const topCriancas = document.getElementById("topCriancas")

		const body = document.body;
		const html = document.documentElement;
		const height = Math.max(body.scrollHeight, body.offsetHeight,
			html.clientHeight, html.scrollHeight, html.offsetHeight);
		const width = Math.max(body.scrollWidth, body.offsetWidth,
			html.clientWidth, html.scrollWidth, html.offsetWidth);

		if (widthWindow <= 1299) {

			const aulaSearchFieldContainer = document.getElementById("aula-search-field-container")

			if (rootEl) {
				rootEl.style.height = `${height}px`
				rootEl.style.width = `${width}px`

			}

			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = "-250px"
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
				rootEl.style.width = `100%`
			}

			if (topCriancas) {
				topCriancas.style.width = `300px`
				topCriancas.style.left = `${width * 0.85}px`
			}
		}
	}

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `auto`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);

		dispatch(NavBarCreators.setTitle(state.unidadeTematica.nome));

		return () => window.removeEventListener("resize", resizeHanlder);
	}, []);

	useEffect(() => {
		setLoading(true);
		setError(false)
	}, [videoaula]);

	useEffect(() => {
		setVideoaula(state.videoaula)
	}, [state])

	const renderVideoStatus = useCallback(() => {
		if (isLoading) return <SuperEnsinoLoading />
		if (error) return <img src={unavailableVideo} alt="video-indisponivel"></img>
	}, [isLoading, error])

	return (
		<>
			<AlunoCard></AlunoCard>
			<MainContainer>
				<VideoAulaContainer>
					<VideoContent>
						{
							videoaula ? (
								<VimeoPlayer
									url={videoaula.uri}
									onReady={() => setLoading(false)}
									onPlay={() => {
										if (videoaula && !videoaula.assistido)
											mutation.mutate(videoaula.id, {
												onSuccess: () => {
													const videoaulasResponseDraft = { ...data }

													const videoaulaDraft = videoaulasResponseDraft?.data?.results?.find((va: Videoaula) => va.id === videoaula.id)

													if (videoaulaDraft) videoaulaDraft.assistido = true

													queryClient.setQueryData(['videoaulas', state.unidadeTematica.id], videoaulasResponseDraft)
												}
											})
									}}
									onError={() => {
										setLoading(false)
										setError(true)
									}}
									controls
									volume={.5}
								/>
							) : null
						}
						<VideoStatusContainer>
							{renderVideoStatus()}
						</VideoStatusContainer>
					</VideoContent>
					<p style={{ color: '#2F2F30', fontFamily: 'Ubuntu', fontWeight: 500, fontSize: 18 }}>
						{videoaula?.titulo}
					</p>
					<SuperTooltip title={`Exercícios de ${state.unidadeTematica.nome}`}>
						<BtnExerciciosRelacionados
							to="/aulas"
							onClick={() => {
								dispatch(AulasExerciciosTabsCreators.setActiveTab(1));

								dispatch(
									AulasExerciciosTabsCreators.setExerciciosOpenAccordion(state.unidadeTematica.id)
								);
							}}
						>
							Exercícios relacionados
						</BtnExerciciosRelacionados>
					</SuperTooltip>
				</VideoAulaContainer>
				<VideosUnidadeTematica>
					<VideosUnidadeTematicaList>
						<Scrollbars style={{ height: '100%' }}>
							{videoaulas?.map((videoaulaSide: Videoaula) => (
								<VideoaulaSide
									key={videoaulaSide.id}
									videoaula={videoaulaSide}
									watching={videoaulaSide.id === videoaula?.id}
								></VideoaulaSide>
							))}
						</Scrollbars>
					</VideosUnidadeTematicaList>
				</VideosUnidadeTematica>
			</MainContainer>
		</>
	);
};

export default Aula
