import styled from 'styled-components';
import { Select } from '@material-ui/core';

import { Globals } from '../../globals';
import SuperSelectArrow from './SelectArrow';

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
`;

export const LivroAzul = styled.img.attrs((props: any) => ({ src: props.src, alt: 'Livro azul do avatar' }))`
	position: relative;
	top: -28px;
	right: 9px;
`;

export const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
export const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
	text-transform: capitalize;
`;

export const ModoSelect = styled(Select).attrs(props => ({
	IconComponent: SuperSelectArrow,
}))`
	width: 169px;
	height: 44px;
	border-radius: 8px;
	background: #09bdf0 0% 0% no-repeat padding-box;
	padding: 13px 16px;
	font-size: 14px;
	color: white;
	font-weight: bold;

	.MuiSelect-select:focus {
		background-color: transparent;
	}
`;

export const DisciplinaSelect = styled(Select).attrs(props => ({
	IconComponent: SuperSelectArrow,
}))`
	width: 169px;
	height: 44px;
	border-radius: 8px;
	background: #15ddbd 0% 0% no-repeat padding-box;
	padding: 13px 16px;
	font-size: 14px;
	color: white;
	font-weight: bold;

	.MuiSelect-select:focus {
		background-color: transparent;
	}
`;

export const SerieOption = styled.option`
	padding: 8px 19px;
	font-family: Ubuntu;
	font-size: 14px;
	color: #2f2f30;
	cursor: pointer;

	&:hover {
		background-color: rgba(197, 197, 197, 0.5);
	}
`;

export const FiltrosContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	padding: 20px 0;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
	margin-bottom: 16px;
`;

export const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const DropdownLabel = styled.p`
	font-size: 14px;
	color: #2f2f30;
	margin-bottom: 8px;
`;

export const DesempenhoButton = styled(Globals.GeneralButton).attrs((props: any) => ({}))``;
