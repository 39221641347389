/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import InputBase from '@material-ui/core/InputBase';

import SuperTooltip from '../SuperTooltip';
import DesempenhoUnavailableModal from '../DesempenhoUnavailableModal';
import Avatar from '../Avatar';

import { Globals } from '../../globals';

import { Modo, NavigationCreators } from '../../store/ducks/navigation';
import { ModalCreators } from '../../store/ducks/modal';
import { AulasExerciciosTabsCreators } from '../../store/ducks/aulasExerciciosTabs';

import useApi from '../../hooks/useApi';

import { useAppContext } from '../../contexts/AppContext';

import { Disciplina } from '../../services';

import { AvatarContainer, AlunoInfoContainer, SpanNomeAluno, FiltrosContainer, DropdownContainer, DropdownLabel, ModoSelect, SerieOption, DisciplinaSelect, DesempenhoButton } from './style';

import logoSuperEnsino from '../../assets/img/se-logo-card-aluno.png';

const AulaExercicioAlunoCard = () => {
	const { userRole, nomeAluno, disciplinas, idAluno } = useAppContext()

	const serie = useSelector((state: any) => state.navigation.serie || 5);
	const modo = useSelector((state: any) => state.navigation.modo);
	const disciplina = useSelector((state: any) => state.navigation.disciplina);

	const history = useHistory();

	const [modoTooltipIsOpen, setModoTooltipIsOpen] = useState(false);
	const [disciplinaTooltipIsOpen, setDisciplinaTooltipIsOpen] = useState(false);
	const [desempenhoUnavailableModalIsOpen, setDesempenhoUnavailableModalIsOpen] = useState(false);

	const dispatch = useDispatch();

	const api = useApi()
	const queryClient = useQueryClient()

	const handleModoChange = (event: ChangeEvent<{ value: unknown }>) => {
		const modo = event.target.value as Modo;
		const firstDisciplinaModo = disciplinas.filter((disciplina: Disciplina) => disciplina.is_prova_brasil === (modo === 'PB'))[0];

		dispatch(NavigationCreators.setModo(modo));
		dispatch(NavigationCreators.setDisciplina(firstDisciplinaModo.id));

		history[history.location.pathname === '/video-aula' ? 'push' : 'replace']('/aulas')
	};

	const handleDisciplinaChange = (event: ChangeEvent<{ value: unknown }>) => {
		const disciplina = event.target.value as number

		dispatch(NavigationCreators.setDisciplina(disciplina));
		dispatch(AulasExerciciosTabsCreators.resetStore());

		history[history.location.pathname === '/video-aula' ? 'push' : 'replace']('/aulas')
	};

	const handleComoEstouIndoClick = useCallback(async () => {
		if (userRole !== 3) {
			dispatch(
				ModalCreators.open(
					'UNAUTHORIZED_ACCESS',
					'Opa! Seu perfil não permite acesso a essa área',
					`Somente os alunos possuem acesso ao ''Como Estou Indo''.`
				)
			);
			return;
		}
		try {
			dispatch(ModalCreators.open('LOADING'))

			const { data: desempenhoUnidadesTematicasData } = await queryClient.fetchQuery(['desempenhoUnidadesTematicas', disciplina],
				() => api.getDesempenhoUnidadeTematicas(idAluno, serie, disciplina))
			await queryClient.fetchQuery(['desempenhoGeral', disciplina], () => api.getDesempenhoGeral(idAluno, serie, disciplina))

			if (!desempenhoUnidadesTematicasData.length) {
				setDesempenhoUnavailableModalIsOpen(true)
				return
			}

			dispatch(ModalCreators.close())

			history.push('/desempenho')
		} catch (error) {

		}
	}, [idAluno, api, queryClient, disciplina, serie, userRole]);

	return (
		<>
			<Globals.AlunoCard>
				<AvatarContainer>
					<Avatar style={{ height: 57, marginRight: 18 }}></Avatar>
					<AlunoInfoContainer>
						<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
						<span>{serie}º ano</span>
					</AlunoInfoContainer>
				</AvatarContainer>
				<FiltrosContainer>
					<DropdownContainer>
						<DropdownLabel>Você está em:</DropdownLabel>
						<SuperTooltip
							title="Altere o modo de estudo"
							placement="top-start"
							open={modoTooltipIsOpen}
						>
							<ModoSelect
								value={modo}
								onChange={handleModoChange}
								input={<InputBase></InputBase>}
								onOpen={() => setModoTooltipIsOpen(false)}
								onMouseEnter={() => setModoTooltipIsOpen(true)}
								onMouseLeave={() => setModoTooltipIsOpen(false)}
							>
								<SerieOption value="SR">Super Reforço</SerieOption>
								<SerieOption value="PB">SAEB</SerieOption>
							</ModoSelect>
						</SuperTooltip>
					</DropdownContainer>
					<DropdownContainer>
						<DropdownLabel>Estudando a matéria:</DropdownLabel>
						<SuperTooltip
							title="Veja outras matérias"
							placement="top-start"
							open={disciplinaTooltipIsOpen}
						>
							<DisciplinaSelect
								value={disciplina}
								onChange={handleDisciplinaChange}
								input={<InputBase></InputBase>}
								onOpen={() => setDisciplinaTooltipIsOpen(false)}
								onMouseEnter={() => setDisciplinaTooltipIsOpen(true)}
								onMouseLeave={() => setDisciplinaTooltipIsOpen(false)}
							>
								{/* <SerieOption value={0}></SerieOption> */}
								{disciplinas
									.filter((disciplina: Disciplina) => disciplina.is_prova_brasil === (modo === 'PB'))
									.map((disciplina: Disciplina) => (
										<SerieOption key={disciplina.id} value={disciplina.id}>
											{disciplina.nome}
										</SerieOption>
									))}
							</DisciplinaSelect>
						</SuperTooltip>
					</DropdownContainer>
				</FiltrosContainer>
				<SuperTooltip title="Informações de como você está na matéria">
					<DesempenhoButton onClick={handleComoEstouIndoClick}>Como estou indo?</DesempenhoButton>
				</SuperTooltip>
				<img style={{ marginTop: 24 }} src={logoSuperEnsino} alt="Logo da Super Ensino" />
			</Globals.AlunoCard>
			<DesempenhoUnavailableModal
				open={desempenhoUnavailableModalIsOpen}
				onClose={() => setDesempenhoUnavailableModalIsOpen(false)}
			></DesempenhoUnavailableModal>
		</>
	);
};

export default AulaExercicioAlunoCard