/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useQuery } from 'react-query';

import { Scrollbars } from 'react-custom-scrollbars';

import Avatar from '../../components/Avatar';

import { useAppContext } from '../../contexts/AppContext';

import { Creators as BackgroundCreators } from '../../store/ducks/background';

import { ExercicioRelatorio, Alternativa } from '../../services';

import useApi from '../../hooks/useApi';

import { AlternativaBox, AlternativaContainer, AlternativaLabel, AlternativasContainer, AlunoInfoContainer, AsideContainer, AvatarContainer, EnunciadoExercicio, ExercicioBichinho, ExercicioContainer, ExercicioLabel, ExercicioLabelMain, ExerciciosGrid, Justificativa, JustificativaLabel, QuestoesContainer, QuestoesInfo, SpanNomeAluno, TopRightImage } from './style';
import { ModalCreators } from '../../store/ducks/modal';

type LocationState = {
	exercicios: ExercicioRelatorio[]
	index: number
}

const JustificativaExercicio = () => {
	document.title = 'Super Aluno | Justificativa dos exercícios';

	const { nomeAluno } = useAppContext()

	const { state } = useLocation<LocationState>()
	const history = useHistory()
	const dispatch = useDispatch()

	const serie: number = useSelector((state: any) => state.navigation.serie || 5);

	const exerciciosCorretosCount = state.exercicios?.filter(exercicio => exercicio.correta).length
	const exerciciosErradosCount = state.exercicios?.filter(exercicio => !exercicio.correta).length

	const [exercicioIndex, setExercicioIndex] = useState(-1);
	const exercicioRelatorio = state.exercicios[state.index]

	useEffect(() => {
		dispatch(BackgroundCreators.setBackgrounds([]));
		setExercicioIndex(state.index)
	}, []);

	const api = useApi()

	const { data: exercicioData, isLoading } = useQuery(['exercicio', exercicioRelatorio.exercicio_id], () => api.getExercicio(exercicioRelatorio.exercicio_id))

	const renderExerciciosCorretos = useCallback(() => {
		return state.exercicios.map((exercicio: ExercicioRelatorio, index: number) => {
			if (exercicio.correta)
				return (
					<ExercicioBichinho
						key={exercicio.exercicio_id}
						onClick={() => {
							history.replace('/justificativa-exercicio', { ...state, index })
							setExercicioIndex(index)
						}}
						correto
					>
						<ExercicioLabel>{`Exercício ${index + 1}`}</ExercicioLabel>
					</ExercicioBichinho>
				)
			return null
		})
	}, [])

	const renderExerciciosErrados = useCallback(() => {
		return state.exercicios.map((exercicio: ExercicioRelatorio, index: number) => {
			if (!exercicio.correta)
				return (
					<ExercicioBichinho
						key={exercicio.exercicio_id}
						onClick={() => {
							history.replace('/justificativa-exercicio', { ...state, index })
							setExercicioIndex(index)
						}}
					>
						<ExercicioLabel>{`Exercício ${index + 1}`}</ExercicioLabel>
					</ExercicioBichinho>
				)

			return null
		})
	}, [])

	useEffect(() => {
		if (isLoading) dispatch(ModalCreators.open('LOADING'))
		else dispatch(ModalCreators.close())
	}, [isLoading])

	return (
		<>
			<AsideContainer>
				<AvatarContainer>
					<Avatar style={{ marginRight: 18, height: 57 }}></Avatar>
					<AlunoInfoContainer>
						<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
						<span>{serie}º ano</span>
					</AlunoInfoContainer>
				</AvatarContainer>
				{exerciciosCorretosCount > 0 && (
					<QuestoesContainer corretas={true}>
						<QuestoesInfo>{`Você acertou ${exerciciosCorretosCount} quest${exerciciosCorretosCount > 1 ? 'ões' : 'ão'}`}</QuestoesInfo>
						<ExerciciosGrid>
							{renderExerciciosCorretos()}
						</ExerciciosGrid>
					</QuestoesContainer>
				)}
				{exerciciosErradosCount > 0 && (
					<QuestoesContainer>
						<QuestoesInfo>{`Você acertou ${exerciciosErradosCount} quest${exerciciosErradosCount > 1 ? 'ões' : 'ão'}`}</QuestoesInfo>
						<ExerciciosGrid>
							{renderExerciciosErrados()}
						</ExerciciosGrid>
					</QuestoesContainer>
				)}
			</AsideContainer>
			<Scrollbars style={{ position: 'relative', marginBottom: 20 }} autoHide>
				<ExercicioContainer>
					<ExercicioLabelMain>{`Exercício ${exercicioIndex + 1}`}</ExercicioLabelMain>
					<EnunciadoExercicio enunciado={exercicioRelatorio?.enunciado}></EnunciadoExercicio>
					<AlternativasContainer>
						{
							exercicioData?.data.alternativas.map((alternativa: Alternativa, index: number) => (
								<AlternativaContainer key={alternativa.id}>
									<AlternativaBox
										correta={alternativa.correta}
										selecionada={exercicioRelatorio?.alternativa_selecionada_id === alternativa.id}
									>
										{['a', 'b', 'c', 'd'][index]}
									</AlternativaBox>
									<AlternativaLabel
										enunciado={alternativa.enunciado.replace(/width: [0-9]+%/, 'width: 200px')}
										correta={alternativa.correta}
										selecionada={exercicioRelatorio?.alternativa_selecionada_id === alternativa.id}
									></AlternativaLabel>
								</AlternativaContainer>
							))
						}
					</AlternativasContainer>
					{exercicioRelatorio?.justificativa && (
						<>
							<JustificativaLabel>Justificativa</JustificativaLabel>
							<Justificativa
								justificativa={exercicioRelatorio?.justificativa}
							></Justificativa>
						</>
					)}
				</ExercicioContainer>
			</Scrollbars>
			<TopRightImage></TopRightImage>
		</>
	);
};

export default JustificativaExercicio;
