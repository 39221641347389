/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { useQueries } from 'react-query';

import { useDispatch, useSelector } from 'react-redux';

import { List, ListItem, Box } from '@material-ui/core';

import SideCard from './SideCard';
import DesempenhoUnidadeTematicaAccordion from './DesempenhoUnidadeTematica';

//creators
import { Creators as BackgroundCreators } from '../../store/ducks/background';

import useApi from '../../hooks/useApi';

import { DesempenhoUnidadeTematica } from '../../services';

//images
import telaSaeb from '../../assets/img/telasaeb.png';
import telaPrateleira from '../../assets/img/telaprateleira.png';

import { DadosGerais, DesempenhoGrid, UnidadeTematica } from './style';
import { useAppContext } from '../../contexts/AppContext';

const ComoEstouIndo = () => {
	document.title = 'Super Aluno | Como estou indo?';

	const { idAluno } = useAppContext()

	const dispatch = useDispatch()

	const serie = useSelector((state: any) => state.navigation.serie || 5);
	const disciplina = useSelector((state: any) => state.navigation.disciplina);

	const api = useApi()

	const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

	const resizeHanlder = () => {
		const widthWindow = window.innerWidth;
		const rootEl = document.getElementById("root")

		if (widthWindow <= 1200) {
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);
			const aulaSearchFieldContainer = document.getElementById("aula-search-field-container")
			const width = Math.max(body.scrollWidth, body.offsetWidth,
				html.clientWidth, html.scrollWidth, html.offsetWidth);

			if (rootEl) {
				rootEl.style.height = `${height + 300}px`
				rootEl.style.width = `${width}px`
			}
			if (aulaSearchFieldContainer) {
				aulaSearchFieldContainer.style.marginLeft = "-270px"
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `100%`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);
		dispatch(
			BackgroundCreators.setBackgrounds([
				{ backgroundImageUrl: telaPrateleira, position: 'right bottom' },
				{ backgroundImageUrl: telaSaeb, position: 'left bottom' },
			])
		);
		return () => window.removeEventListener("resize", resizeHanlder);
	}, []);

	const results = useQueries([{queryKey: ['desempenhoGeral', disciplina], queryFn: () => api.getDesempenhoGeral(idAluno, serie, disciplina)}, 
		{queryKey: ['desempenhoUnidadesTematicas', disciplina], queryFn: () => api.getDesempenhoUnidadeTematicas(idAluno, serie, disciplina)}])


	const report = useMemo(() => {
		if (!(results[0].data && results[1].data)) return null

		// @ts-ignore
		const desempenhoGeral = results[0].data.data
		// @ts-ignore
		const desempenhoUnidadesTematicas = results[1].data.data

		return (
			<>
				<DesempenhoGrid>
					<DadosGerais>
						<Box display="flex" flexDirection="row" p={0} m={0}>
							<Box p={1}>
								<SideCard desempenhoGeral={desempenhoGeral} />
							</Box>
						</Box>
					</DadosGerais>
					<UnidadeTematica>
						<Box display="flex" flexDirection="row" p={0} m={0}>
							<Box p={1}>
								<Scrollbars
									autoHide
									autoHeight
									autoHideTimeout={1000}
									autoHideDuration={200}
									autoHeightMax={window.innerHeight * 0.58}
								>
									<div style={{ display: 'flex', flexDirection: (window.innerWidth <= 1190 ? 'column' : 'row') }}>
										<List>
											{desempenhoUnidadesTematicas.map((desempenho: DesempenhoUnidadeTematica, index: number) =>
												index % 2 === 0 ? (
													<ListItem key={index}>
														<DesempenhoUnidadeTematicaAccordion
															desempenhoUnidadeTematica={desempenho}
															onChange={(_, expanded) =>
																setOpenAccordionIndex(expanded ? index : -1)
															}
															expanded={openAccordionIndex === index}
														/>
													</ListItem>
												) : null
											)}
										</List>
										<List>
											{desempenhoUnidadesTematicas.map((desempenho: DesempenhoUnidadeTematica, index: number) =>
												index % 2 === 1 ? (
													<ListItem key={index}>
														<DesempenhoUnidadeTematicaAccordion
															desempenhoUnidadeTematica={desempenho}
															onChange={(_, expanded) =>
																setOpenAccordionIndex(expanded ? index : -1)
															}
															expanded={openAccordionIndex === index}
														/>
													</ListItem>
												) : null
											)}
										</List>
									</div>
								</Scrollbars>
							</Box>
						</Box>
					</UnidadeTematica>
				</DesempenhoGrid>
			</>
		)
	}, [results])

	return report;
};

export default ComoEstouIndo;
