import React, { ReactNode } from "react"

import SuperTooltip from "../../../../components/SuperTooltip"

import { Anexo } from "../../../../services"

import { AnexoLink, AnexoTitulo, AnexosContainer, AnexosGrid } from "./styles"

const AnexosSection = ({anexos, icon, title}: {anexos: Anexo[], icon: ReactNode, title: string}) => (
    <AnexosContainer>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {icon}
            <span style={{marginLeft: 10}}>{title}</span>
        </div>
        <AnexosGrid>
            {
                anexos.map(anexo => (
                    <SuperTooltip key={anexo.id} title={anexo.titulo} placement="top">
                        <AnexoLink href={anexo.arquivo} target="_blank">
                            <AnexoTitulo>
                                {anexo.titulo}
                            </AnexoTitulo>
                        </AnexoLink>
                    </SuperTooltip>
                ))
            }
        </AnexosGrid>
    </AnexosContainer>
)

export default AnexosSection