import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import AjudaModal from './components/AjudaModal';
import AlertModal from './components/AlertModal';
import LogoutModal from './components/LogoutModal';
import UnauthorizedAccessModal from './components/UnauthorizedAccessModal';
import OfflineModal from './components/OfflineModal';
import LoadingModal from './components/LoadingModal';
import ActionsModal from './components/ActionsModal';

import { isMobile, isIOS } from 'react-device-detect';

import Routes from './Routes';

import { InitialState as IBackground } from './store/ducks/background';
import { persistor } from './store';

import GlobalStyles from './globals';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#255BFF',
		},
	},
	typography: {
		fontFamily: 'Ubuntu',
	},
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 60000
		}
	}
})

const App: React.FC = () => {
	useEffect(() => {
		if (isMobile) {
			if (isIOS) {
				window.location.href = 'https://apps.apple.com/br/app/super-aluno/id1345304774';
				return
			}
			window.location.href = 'https://play.google.com/store/apps/details?id=com.conatussw.superaluno';
			return
		}
	}, [])

	const backgroundStore: IBackground = useSelector((state: any) => state.background);

	const root = document.getElementById('root');

	if (root)
		root.style.background = backgroundStore.data.backgrounds
			.map(background => `url(${background.backgroundImageUrl}) no-repeat ${background.position}`)
			.join(',');

	return (
		<PersistGate
			persistor={persistor}
			loading={
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'flex-end',
						height: window.screen.availHeight * 0.4,
					}}
				>
					<CircularProgress size={64} />
				</div>
			}
		>
			<QueryClientProvider client={queryClient}>
				<GlobalStyles />
				<ThemeProvider theme={theme}>
					<Routes />
					<LogoutModal></LogoutModal>
					<UnauthorizedAccessModal></UnauthorizedAccessModal>
					<LoadingModal></LoadingModal>
					<AjudaModal></AjudaModal>
					<OfflineModal></OfflineModal>
					<AlertModal></AlertModal>
					<ActionsModal />
				</ThemeProvider>
			</QueryClientProvider>
		</PersistGate>
	);
};

export default App;
