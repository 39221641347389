import React from 'react';

import { useLocation } from 'react-router-dom';

import { VideoAula, VideoAulaContainer, VideoAulaDetails, VideoAulaThumbnail, VideoAulaTitle } from './style';

import { UnidadeTematica, Videoaula } from '../../../services';

import blueCheck from '../../../assets/img/blue-check.svg';

type LocationState = {
	unidadeTematica: UnidadeTematica
	videoaula: Videoaula
}

const VideoaulaSide = ({
	videoaula,
	watching
}: VideoaulaSideProps) => {
	const { state } = useLocation<LocationState>()

	return (
		<VideoAulaContainer watching={watching}>
			<VideoAula
				to={{
					pathname: "/video-aula",
					state: { ...state, videoaula }
				}}
				replace
			>
				<VideoAulaThumbnail>
					<img src={videoaula.thumbnail.uri || ''} alt={`${videoaula.titulo}`} />
				</VideoAulaThumbnail>
				<VideoAulaDetails>
					<VideoAulaTitle text={videoaula.titulo} />
					{videoaula.assistido && <img src={blueCheck} alt="Blue check" />}
				</VideoAulaDetails>
				{watching && <p style={{ fontSize: 12 }}>Você está assistindo</p>}
			</VideoAula>
		</VideoAulaContainer>
	);
};

export default VideoaulaSide;

interface VideoaulaSideProps {
	videoaula: Videoaula;
	watching: boolean;
}
