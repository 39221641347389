import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	CircularProgress,
	Typography
} from '@material-ui/core';

import TelefoneInputMask from 'react-input-mask'

import Input from '../../../components/Input'

import useApi from '../../../hooks/useApi';

import useStyles, { SubmitButton, ErrorMessage } from './styles'

import closeModalIcon from '../../../assets/img/times-solid.svg';
import checkCircle from '../../../assets/img/check-circle-solid.svg';

const ChangeEmailTelefoneModal = ({ open, onClose }: ChangeEmailTelefoneModal) => {
	const classes = useStyles();

	const [telefone, setTelefone] = useState('');
	const [telefoneConfirmation, setTelefoneConfirmation] = useState('');
	const [telefoneIsValid, setTelefoneIsValid] = useState(true);

	const [errorMessage, setErrorMessage] = useState('')
	const [formIsValid, setFormIsValid] = useState(true)

	const [processing, setProcessing] = useState(false)
	const [success, setSuccess] = useState(false)

	const api = useApi()

	const mutation = useMutation(api.editProfile)
	const queryClient = useQueryClient()

	const setAllFieldsToValid = () => {
		setFormIsValid(true)
		setTelefoneIsValid(true)
	}

	const onCloseModal = () => {
		onClose();

		setTelefone('')
		setTelefoneConfirmation('')

		setSuccess(false)
	};

	useEffect(() => {
	}, []);

	const handleChangeTelefone = (e: ChangeEvent<HTMLInputElement>) => {
		setTelefone(e.target.value);
		setAllFieldsToValid()
	};
	const handleChangeTelefoneConfiramtion = (e: ChangeEvent<HTMLInputElement>) => {
		setTelefoneConfirmation(e.target.value);
		setAllFieldsToValid()
	};

	const NewTelefoneInput = () => <Input id="novo telefone" error={!telefoneIsValid} labelWidth={105}></Input>
	const ConfirmationTelefoneInput = () => <Input id="confirme o novo telefone" error={!telefoneIsValid} labelWidth={190} ></Input>

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()

		setProcessing(true)

		const telefone_celular = telefone.replace(/[^\d]/g, '')

		if (!telefone_celular) {
			setErrorMessage('Insira um número de telefone')
			setFormIsValid(false)
		}
		else if (telefone_celular.length < 11) {
			setErrorMessage('Insira um número de telefone válido')
			setFormIsValid(false)
		}
		else if (telefone !== telefoneConfirmation) {
			setTelefoneIsValid(false)
			setErrorMessage('O novo telefone deve corresponder a sua confirmação')
			setFormIsValid(false)
		} else {
			try {
				mutation.mutate({ telefone_celular }, {
					onSuccess: () => queryClient.invalidateQueries('profile')
				})

				setSuccess(true)

				setTimeout(() => onCloseModal(), 2000)
			}
			catch (error) {
				setErrorMessage(error)
				setFormIsValid(false)
			}
		}

		setProcessing(false)
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={onCloseModal} style={{ marginBottom: 2 }}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>ALTERAÇÃO TELEFONE</p>}
					/>
					<CardContent className={classes.cardContent}>
						{
							!processing && success ? (
								<>
									<div className={classes.checkCircleContainer}>
										<img
											className={classes.checkCircle}
											src={checkCircle}
											alt="Check circle"
										/>
									</div>
									<Typography className={classes.instruction}>
										Telefone alterado com sucesso!
									</Typography>
								</>
							) :
								(
									<form onSubmit={handleSubmit} className={classes.form}>
										<TelefoneInputMask value={telefone} onChange={handleChangeTelefone} mask="(99) 99999-9999">
											{NewTelefoneInput}
										</TelefoneInputMask>
										<TelefoneInputMask value={telefoneConfirmation} onChange={handleChangeTelefoneConfiramtion} mask="(99) 99999-9999">
											{ConfirmationTelefoneInput}
										</TelefoneInputMask>
										<ErrorMessage visible={!formIsValid}>{errorMessage}</ErrorMessage>
										<SubmitButton
											disableFocusRipple
											type="submit"
										>
											{processing ? (
												<CircularProgress size={18} />
											) : (
												'Confirmar'
											)}
										</SubmitButton>
									</form>
								)
						}
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default ChangeEmailTelefoneModal;

interface ChangeEmailTelefoneModal {
	open: boolean;
	onClose: () => void;
}
