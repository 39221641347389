import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import AulaSearchField from '../../../components/AulaSearchField';

import { ModalCreators } from '../../../store/ducks/modal';

import { NavItem } from './style';

import homeLogoSuperEnsino from '../../../assets/img/home-logo-superensino.png';

const NavBar = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className={classes.container}>
			<nav className={classes.navBar}>
				<NavItem>
					<img
						src={homeLogoSuperEnsino}
						className={classes.logoSuperEnsino}
						alt="Logo da Super Ensino"
					/>
				</NavItem>
				<NavItem onClick={() => history.push('/perfil')}>Perfil</NavItem>
				{/* <NavItem className={classes.navItem}>Notificações</NavItem> */}
				<NavItem onClick={() => dispatch(ModalCreators.open('AJUDA'))}>Ajuda</NavItem>
				<NavItem onClick={() => dispatch(ModalCreators.open('LOGOUT'))}>Sair</NavItem>
			</nav>
			<AulaSearchField></AulaSearchField>
		</div>
	);
};

export default NavBar

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		padding: '24px 32px 0 47px',
	},
	navBar: {
		display: 'flex',
		alignItems: 'center',
	},
	logoSuperEnsino: {
		height: 30,
	},
}));
