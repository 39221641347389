import styled from 'styled-components';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: rgba(221, 216, 216, 0.5) 1px solid;
`;

export const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
	text-transform: capitalize;
`;

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 263,
			height: 429,
			left: '20%',
			borderRadius: 15,
		},
		media: {
			height: 0,
			paddingTop: '56.25%',
		},
		expand: {
			transform: 'rotate(0deg)',
			marginLeft: 'auto',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: 'rotate(180deg)',
		},
		large: {
			width: theme.spacing(7),
			height: theme.spacing(7),
		},
		blueNumber: {
			color: '#07B0FF',
			font: 'bold 14px/16px Ubuntu',
		},
		greenNumber: {
			color: '#15DDBD',
			font: 'bold 14px/16px Ubuntu',
		},
		desempenhoNumber: {
			font: 'Bold 14px/16px Ubuntu',
		},
		desempenhoLabel: {
			font: 'Medium 15px/17px Ubuntu',
			fontFamily: 'Ubuntu',
			fontWeight: 'bold',
			fontSize: 14,
			color: '#111',
		},
		iconAulasAssistidas: {
			left: '88px',
			width: '32px',
		},
		iconExerciciosRespondidos: {
			width: '44px',
		},
		adjustmentPercent: {
			marginLeft: -28,
		},
		adjustmentLabelAulasAssistidas: {
			marginBottom: 'auto',
			marginTop: 'auto',
		},
	})
);
