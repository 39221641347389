import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core/';
import chevronDown from '../../assets/img/chevron-circle-down-solid.svg';

import { Globals } from '../../globals';
import { AlternativaStatus } from '.';

enum Colors {
	GREEN = '#15DDBD',
	RED = '#FC6921',
	GREY = '#C5C5C5',
	BLUE = '#09bdf0',
	BLACK = '#2F2F30'
}

export const getAlternativaBackgroundColor = (
	status: number
) => {
	switch (status) {
		case AlternativaStatus.CORRECT: return Colors.GREEN
		case AlternativaStatus.WRONG: return Colors.RED
		case AlternativaStatus.READY_TO_SELECT_OR_SELECTED: return Colors.BLUE
		case AlternativaStatus.NOT_SELECTED: return Colors.GREY
		default: return Colors.BLACK
	}
};

export const getAlternativaFontColor = (
	status: number
) => {
	switch (status) {
		case AlternativaStatus.NOT_SELECTED: return Colors.GREY
		default: return Colors.BLACK
	}
};

export const ExercicioContent = styled.div`
	padding-top: 24px;
	left: 367px;
	position: relative;
	max-width: 740px;

	@media (max-width: 1000px) {
		max-width: 410px;
		padding-bottom: 30px;
	}
`;

export const Question = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ExercicioLabel = styled.span`
	color: #07b0ff;
	font-weight: bold;
	font-size: 16px;
`;

export const QuestionEnunciadoWrapper = styled.div`
	display: grid;
`;

export const QuestionEnunciado = styled.div.attrs((props: any) => ({
	text: props.text,
	dangerouslySetInnerHTML: { __html: props.text },
}))`
	font-size: 16px;
	color: #2f2f30;

	row-gap: 20px;
	margin: 8px 0 15px;
	line-height: 1.5em;

	/* Não utilizar display: grid, porque quebra elementos inline */
	float: left;

	/* Aplica espaçamento */
	i,
	del,
	ins {
		margin-left: 0.2em;
		margin-right: 0.2em;
	}

	b,
	b * {
		color: #07b0ff;
	}

	i,
	i * {
		color: #000;
	}

	p {
		width: 100%;
		margin-bottom: 12px;
		margin-top: 20px;
	}

	sup,
	sub,
	sup *,
	sub * {
		font-size: 12px !important;
	}

	*[style] {
		font-family: Ubuntu !important;
		margin-left: 0 !important;
	}

	img {
		width: auto;
		height: auto;

		/* como requisitado na task: #2ytqgqp as imagens serão alinhadas a esquerda, antes eram centralizadas */
		/*margin: auto !important; */

		display: inline;
		margin-top: 20px;
		margin-bottom: 10px;
	}
`;

export const Alternativas = styled.ul.attrs((props: any) => ({ confirmed: props.confirmed }))`
	display: grid;
	list-style: none;
	grid-template-rows: 1fr;
	row-gap: 15px;
	font-size: 16px;
	line-height: 1.5em;

	${props =>
		props.confirmed &&
		css`
			li {
				pointer-events: none;
			}
		`}
	margin-bottom: 66px;

	b,
	b * {
		color: #07b0ff;
	}

	i,
	i * {
		color: #000;
	}

	sup,
	sub,
	sup *,
	sub * {
		font-size: 12px !important;
	}

	*[style] {
		font-family: Ubuntu !important;
		margin-left: 0 !important;
	}
`;

export const AlternativasItem = styled.li.attrs((props: any) => ({
	status: props.status
}))`
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	column-gap: 15px;
	cursor: pointer;

	div {
		background: ${props =>
		getAlternativaBackgroundColor(
			props.status
		)};
	}

	p {
		color: ${props =>
		getAlternativaFontColor(
			props.status
		)} !important;
	}

	p b,
	p b * {
		color: ${props => {
		return getAlternativaFontColor(
			props.status
		) === '#C5C5C5'
			? '#C5C5C5'
			: '#07b0ff';
	}} !important;
	}

	p i,
	p i * {
		color: ${props => {
		return getAlternativaFontColor(
			props.status
		) === '#C5C5C5'
			? '#C5C5C5'
			: '#000';
	}} !important;
	}

	p div {
		background: transparent;
	}
`;

export const AlternativasItemIndex = styled.div`
	display: flex;
	height: 35px;
	width: 35px;
	justify-content: center;
	align-items: center;
	background-color: #09bdf0;
	color: white;
	font-size: 14px;
	border-radius: 4px;
	background: #09bdf0;
`;

export const AlternativasItemEnunciado = styled.p.attrs((props: any) => ({
	text: props.text,
	dangerouslySetInnerHTML: { __html: props.text },
}))`
	color: #2f2f30;
	font-size: 16px;

	p {
		align-items: center;
	}
`;

export const NextQuestion = styled(Button).attrs(() => ({ variant: 'contained', color: 'primary' }))``;

export const FeedbackContainer = styled.div`
	position: fixed;
	right: 5%;
	bottom: 10%;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	position: fixed;
	right: 5%;
	bottom: 5%;

	> *:not(:last-child) {
		margin-right: 16px;
	}
`

export const CustomizedGeneralButton = styled(Globals.GeneralButton).attrs((props: ButtonProps & { visible: boolean }) => ({
	classes: props.classes,
	visible: props.visible,
}))`
	width: 155px;
	justify-self: center;

	${props =>
		!props.visible &&
		css`
			display: none;
		`}

	animation: ${props => (!props.visible ? 'unset' : 'bouncezada 1s')};

	@keyframes bouncezada {
		20% {
			transform: translateY(15px) rotateZ(5deg);
		}
		40% {
			transform: rotateZ(-5deg);
		}
		60% {
			transform: rotateZ(5deg);
		}
		80% {
			transform: rotateZ(-5deg);
		}
		100% {
			transform: translateY(0) rotateZ(0);
		}
	}

	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
`;

export const ConfirmarButton = styled(Globals.GeneralButton).attrs((props: { visible: boolean }) => ({
	visible: props.visible,
}))`
	width: 155px;
	justify-self: center;

	${props =>
		!props.visible &&
		css`
			display: none;
		`}

	animation: ${props => (!props.visible ? 'unset' : 'bouncezada 0.75s')};

	@keyframes bouncezada {
		20% {
			transform: translateY(15px) rotateZ(5deg);
		}
		40% {
			transform: rotateZ(-5deg);
		}
		60% {
			transform: rotateZ(5deg);
		}
		80% {
			transform: rotateZ(-5deg);
		}
		100% {
			transform: translateY(0) rotateZ(0);
		}
	}

	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
`;

export const JustificativaButton = styled.div.attrs((props: JustificativaButtonProps) => ({
	visible: props.visible
}))`
	background: url(${chevronDown}) no-repeat;
	width: 40px;
	height: 40px;
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
	position: relative;
	animation: bounceWithDistortion 1.5s infinite;
	cursor: pointer;

	@keyframes bounceWithDistortion {
		0% {
			transform: scale(1, 1) translateY(0);
		}
		10% {
			transform: scale(1.1, 0.9) translateY(0);
		}
		30% {
			transform: scale(0.9, 1.1) translateY(-30px);
		}
		50% {
			transform: scale(1, 1) translateY(0);
		}
		100% {
			transform: scale(1, 1) translateY(0);
		}
	}
`;

export const FeedbackAsset = styled.img.attrs((props: any) => ({ animating: props.animating }))`
	position: relative;

	width: 155px;
	top: 25px;

	transition: transform 3s ease-in-out, opacity 1.5s ease-in-out;
	opacity: 0;

	${props => props.animating && css`
		transform: translateY(-25px);
		opacity: 1;
	`}

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
`;

export const JustificativaContainer = styled.div`
	display: grid;
	row-gap: 20px;
	margin-bottom: 50px;
	line-height: 1.5em;

	img {
		width: auto;
		height: auto;

		/* como requisitado na task: #2ytqgqp as imagens serão alinhadas a esquerda, antes eram centralizadas */
		/* margin: auto 45px; */
		margin: 25px 0;
		display: block;
	}

	p {
		margin-top: 20px;
		margin-left: 0 !important;
	}

	p * {
		margin-left: 0 !important;
	}

	i,
	i * {
		color: #000 !important;
	}

	b,
	b * {
		color: #07b0ff !important;
	}

	sup,
	sub,
	sup *,
	sub * {
		font-size: 12px !important;
	}

	*[style] {
		font-family: Ubuntu !important;
		margin-left: 0 !important;
	}

	ul {
		margin-left: 25px;
	}
`;

export const ExercicioId = styled.span`
	color: #c5c5c5;
	font-size: 16px;
	font-weight: bold;
`;

interface JustificativaButtonProps {
	visible: boolean;
	userScrolled: boolean;
}
