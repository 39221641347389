import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface AulasExerciciosTabsActions {
	SET_ACTIVE_TAB: string;
	SET_VIDEOAULAS_OPEN_ACCORDION: string;
	SET_EXERCICIOS_OPEN_ACCORDION: string;
	RESET_STORE: string;
}

interface AulasExerciciosTabsCreators {
	setActiveTab: (activeTab: number) => any;
	setVideoaulasOpenAccordion: (unidadeTematicaId: number) => any;
	setExerciciosOpenAccordion: (unidadeTematicaId: number) => any;
	resetStore: () => any;
}

export interface AulasExerciciosTabsStore {
	activeTab: number;
	videoaulasOpenAccordion: number;
	exerciciosOpenAccordion: number;
}

// initial state
const INITIAL_STATE: AulasExerciciosTabsStore = {
	activeTab: 0,
	videoaulasOpenAccordion: 0,
	exerciciosOpenAccordion: 0,
};

// reducers
const setActiveTab = (state = INITIAL_STATE, action: any) => ({
	...state,
	activeTab: action.activeTab,
});

const setVideoaulasOpenAccordion = (state = INITIAL_STATE, action: any) => ({
	...state,
	videoaulasOpenAccordion: action.unidadeTematicaId,
});

const setExerciciosOpenAccordion = (state = INITIAL_STATE, action: any) => ({
	...state,
	exerciciosOpenAccordion: action.unidadeTematicaId,
});

const resetStore = () => INITIAL_STATE;

// actions
export const { Types: AulasExerciciosTabsTypes, Creators: AulasExerciciosTabsCreators } = createActions<
	AulasExerciciosTabsActions,
	AulasExerciciosTabsCreators
>({
	setActiveTab: ['activeTab'],
	setVideoaulasOpenAccordion: ['unidadeTematicaId'],
	setExerciciosOpenAccordion: ['unidadeTematicaId'],
	resetStore: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[AulasExerciciosTabsTypes.SET_ACTIVE_TAB]: setActiveTab,
	[AulasExerciciosTabsTypes.SET_VIDEOAULAS_OPEN_ACCORDION]: setVideoaulasOpenAccordion,
	[AulasExerciciosTabsTypes.SET_EXERCICIOS_OPEN_ACCORDION]: setExerciciosOpenAccordion,
	[AulasExerciciosTabsTypes.RESET_STORE]: resetStore,
});
