import React from 'react';

import clipboard from '../../assets/img/clipboard-list-solid.svg';
import SuperTooltip from '../SuperTooltip';

const ReportAvailability: React.FC<IReportAvailability> = ({ available, tooltip, onClick }) => {
	const containerStyle: React.CSSProperties = {
		borderRadius: '50%',
		backgroundColor: available ? '#2fb0ff' : '#c5c5c5',
		backgroundImage: `url('${clipboard}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50% 50%',
		height: 20,
		width: 20,
		minWidth: 20,
		cursor: available ? 'pointer' : 'auto',
	};

	return tooltip ? (
		<SuperTooltip
			title={
				available
					? 'Resultado da lista de exercício disponível'
					: 'Complete a lista de exercício para ver seu resultado'
			}
			placement="top-start"
		>
			<div style={containerStyle} onClick={onClick}></div>
		</SuperTooltip>
	) : (
		<div style={containerStyle}></div>
	);
};

export default ReportAvailability;

interface IReportAvailability {
	available?: boolean;
	tooltip?: boolean;
	onClick?: () => void;
}
