import styled, { createGlobalStyle, css } from 'styled-components';

import { ExpansionPanel, ExpansionPanelSummary, AccordionDetails, Button } from '@material-ui/core';

export default createGlobalStyle`
	
	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
		user-select: none;
		user-drag: none;
	}

	#root {
		transform-origin: 0 0;
		width: fit-content;
		-webkit-scrollbar: {
			display: none;
		}
		
		.infinite-scroll-component {
			::-webkit-scrollbar {
				/* quiser remover o scroll deixar invisible so habilitar a função a baixo */
				/* display: none; */
				width: 8px;
				background-color: #F5F5F5;
			}
			::-webkit-scrollbar-track {
				background-color: rgba(0, 0, 0, 0.2);;
				background-color: #F5F5F5;
			}
			::-webkit-scrollbar-thumb{
				background-color: rgba(0, 0, 0, 0.2);
				border: 1px solid #FFFF;
				border-radius: 10px;
			}
			overflow-x: hidden !important;
		}
	}
	
	html,
	body,
	#root {
		width: 100%;
		height: 100%;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	

	body {
		font-family: "Ubuntu", sans-serif;
	}

	img,
	svg {
		display: block;
		max-width: 100%;
	}

`;

export const ContentGrid = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const PageGrid = styled.section`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template: max-content 1fr / 1fr;
`;

export const ChoicesGrid = styled.div`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	row-gap: 30px;
`;

export const AulaGrid = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 3fr;
`;

export const Title = styled.h2.attrs((props: any) => ({ text: props.text, children: props.text }))`
	color: #2a64fe;
	font-weight: 700;
	font-size: 26px;
	text-align: center;
`;

const Expansion = styled(ExpansionPanel)`
	&& {
		background-color: #faf9fe;
		border: solid 2px #ebe6ff;
		box-shadow: none;
		margin-bottom: 5px;
		min-width: 320px;
		overflow: hidden;

		&:before {
			background-color: transparent;
		}
	}
`;

const ExpansionTitle = styled(ExpansionPanelSummary).attrs((props: any) => ({
	text: props.text,
	children: props.text,
	title: props.text,
}))`
	&& {
		color: #0e2a47;
		font-weight: 700;

		.MuiExpansionPanelSummary-content {
			white-space: nowrap !important;
			display: block !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
		}
	}
`;

const ExpansionDetails = styled(AccordionDetails).attrs((props: any) => ({ aside: props.aside }))`
	&& {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;

		${props =>
		props.aside === 'true' &&
		css`
				grid-template-columns: repeat(1, 1fr);
			`}
	}
`;

const ExpansionDetailsExercicio = styled(AccordionDetails).attrs((props: any) => ({ aside: props.aside }))`
	&& {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;

		${props =>
		props.aside === 'true' &&
		css`
				grid-template-columns: repeat(1, 1fr);
			`}
	}
`;

const AlunoCard = styled.aside`
	position: absolute;
	top: 104px;
	left: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 263px;
	background: #f7fcfe 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 10px #0013ff29;
	border-radius: 15px;
	padding: 32px 47px 48px;
	font-family: Ubuntu;
	@media (max-width: 1280px) {
		max-width: 223px;
	}
`;

const GeneralButton = styled(Button).attrs((props: any) => ({ disabled: props.disabled, isLoading: props.isLoading }))`
	width: 169px;
	height: 44px;
	background: ${props => (!props.disabled ? `linear-gradient(180deg, #75cfea 0%, #0fd4d7 100%)` : `#C5C5C5`)};
	border-radius: 8px;
	text-transform: none;
	color: white;
	font-size: 14px;
	font-weight: bold;
	pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
`;

export const Globals = {
	Expansion,
	ExpansionTitle,
	ExpansionDetails,
	ExpansionDetailsExercicio,
	AlunoCard,
	GeneralButton,
};
