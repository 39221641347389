import styled from 'styled-components';

export const AnexosContainer = styled.div`
	border-top: rgba(221, 216, 216, 0.5) 1px solid;
	margin-top: 20px;
    padding: 10px;
`;

export const AnexosGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px 5px;
	padding-top: 15px;
`;

export const AnexoLink = styled.a`
	border: 2px solid rgba(7, 176, 255, 0.6);
	border-radius: 8px;

	height: 50px;
	padding: 8px;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AnexoTitulo = styled.p`
	font-family: Ubuntu;
	font-size: 14px;
	color: #111;

	text-align: center;  
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	overflow: hidden;
`