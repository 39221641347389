/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs } from '@material-ui/core';

import InfiniteScroll from 'react-infinite-scroll-component';

import AlunoCard from '../../components/AulaExercicioAlunoCard';
import NoClassesExercisesFeedback from './NoClassesExercisesFeedback';
import SuperEnsinoLoading from '../../components/SuperEnsinoLoading';
import VideoAulasAccordion from './VideoaulasAccordion';
import ExercicioAccordion from './ExerciciosAccordion';

import { Creators as BackgroundCreators } from '../../store/ducks/background';
import { NavBarCreators } from '../../store/ducks/navBar';
import { AulasExerciciosTabsCreators } from '../../store/ducks/aulasExerciciosTabs';
import { ModalCreators } from '../../store/ducks/modal';

import { UnidadeTematica } from '../../services';

import useApi from '../../hooks/useApi';

import { TabsContainer, TabsHeadersContainer, TabPanel, useStyles } from './style'

import rightTopImage from '../../assets/img/criancas-no-chao.png';
import bottomRightImage from '../../assets/img/menina-segurando-mochila-e-cadeirante435x504.svg';
import bottomLeftImage from '../../assets/img/boy-over-book-stack328x504.png';

const Aulas = () => {
	document.title = 'Super Aluno | Aulas & Exercícios';

	const dispatch = useDispatch();

	const classes = useStyles()

	const activeTab = useSelector((state: any) => state.aulasExerciciosTabs.activeTab);
	const videoaulasOpenAccordion = useSelector((state: any) => state.aulasExerciciosTabs.videoaulasOpenAccordion);
	const exerciciosOpenAccordion = useSelector((state: any) => state.aulasExerciciosTabs.exerciciosOpenAccordion);
	const disciplina = useSelector((state: any) => state.navigation.disciplina);
	const serie = useSelector((state: any) => state.navigation.serie || 5);

	const [nextPage, setNextPage] = useState(1)
	const [theresMoreData, setTheresMoreData] = useState(false)
	const [unidadesTematicas, setUnidadesTematicas] = useState<UnidadeTematica[]>()

	const unidadesTematicasVideoaulas = unidadesTematicas?.filter(unidadeTematica => unidadeTematica.quantidade_videoaulas || unidadeTematica.quantidade_anexos || unidadeTematica.quantidade_mapas_mentais)
	const unidadesTematicasExercicios = unidadesTematicas?.filter(unidadeTematica => unidadeTematica.quantidade_exercicios)

	const resizeHanlder = () => {
		const heightWindow = window.innerHeight;
		const rootEl = document.getElementById("root")

		if (heightWindow < 601) {
			if (rootEl) {
				rootEl.style.height = `${heightWindow + Math.abs(heightWindow - 630)}px`
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById("root")
		if (rootEl) {
			rootEl.style.height = `100%`
			rootEl.style.width = `100%`
		}
		resizeHanlder()
		window.addEventListener('resize', resizeHanlder);
		dispatch(
			BackgroundCreators.setBackgrounds([
				{ backgroundImageUrl: bottomRightImage, position: 'right bottom' },
				{ backgroundImageUrl: bottomLeftImage, position: 'left bottom' },
			])
		);
		dispatch(NavBarCreators.setTitle(''));
		return () => {
			const rootEl = document.getElementById("root")
			const body = document.body;

			body.style.height = `100%`
			if (rootEl) {
				rootEl.style.height = `100%`
				rootEl.style.width = `100%`
			}
			window.removeEventListener("resize", resizeHanlder);
		}
	}, []);

	const api = useApi()

	const getUnidadesTematicas = useCallback(async (nextPage: number) => {
		const params = {
			aulas__disciplina: disciplina,
			aulas__serie: serie,
			page: nextPage > 1 ? nextPage : undefined
		}

		api.getUnidadesTematicas(params)
			.then(({ data: { results, next } }) => {
				setUnidadesTematicas((currentUnidadesTematicas) => currentUnidadesTematicas && nextPage > 1 ?
					[...currentUnidadesTematicas, ...results] : results)
				setTheresMoreData(!!next)

				if (next) setNextPage(nextPage + 1)
			})
	}, [disciplina, serie])

	useEffect(() => {
		setUnidadesTematicas(undefined)
		dispatch(ModalCreators.open('LOADING'))
		getUnidadesTematicas(1)
		dispatch(ModalCreators.close())
	}, [disciplina])


	// If some accordion is open or there's more data, allow the infinite scroll container stretch as much as it needs to
	// Otherwise, the height of the infinite scroll container is calculated based on the number of unidades temáticas 
	// that have been loaded
	const videoaulasTabPanelHeight = videoaulasOpenAccordion || !theresMoreData ? 'auto' : 66 * (unidadesTematicasVideoaulas ? unidadesTematicasVideoaulas.length : 0) - 5
	const exerciciosTabPanelHeight = exerciciosOpenAccordion || !theresMoreData ? 'auto' : 66 * (unidadesTematicasExercicios ? unidadesTematicasExercicios.length : 0) - 5

	return (
		<>
			<AlunoCard />
			{unidadesTematicas && !unidadesTematicas.length ? (
				<NoClassesExercisesFeedback />
			) : (
				<TabsContainer>
					<TabsHeadersContainer>
						<Tabs
							textColor="inherit"
							variant="fullWidth"
							value={activeTab}
							onChange={(_: React.ChangeEvent<{}>, index: number) => dispatch(AulasExerciciosTabsCreators.setActiveTab(index))}
							TabIndicatorProps={{ style: { backgroundColor: '#F7D757', height: 3 } }}
						>
							<Tab label="aulas" style={{ fontWeight: 'bold' }} classes={{ root: classes.tabHover }} />
							<Tab label="exercícios" style={{ fontWeight: 'bold' }} classes={{ root: classes.tabHover }} />
						</Tabs>
					</TabsHeadersContainer>
					<TabPanel value={activeTab} index={0}>
						<InfiniteScroll
							loader={<SuperEnsinoLoading></SuperEnsinoLoading>}
							dataLength={unidadesTematicasVideoaulas ? unidadesTematicasVideoaulas.length : 0}
							next={() => getUnidadesTematicas(nextPage)}
							hasMore={!!theresMoreData}
							height={videoaulasTabPanelHeight}
							style={{maxHeight: '60vh'}}
						>
							{unidadesTematicasVideoaulas?.map(unidadeTematica =>
								<VideoAulasAccordion
									key={unidadeTematica.id}
									unidadeTematica={unidadeTematica}
								/>
							)}
						</InfiniteScroll>
					</TabPanel>
					<TabPanel value={activeTab} index={1}>
						<InfiniteScroll
							loader={<SuperEnsinoLoading></SuperEnsinoLoading>}
							dataLength={unidadesTematicasExercicios ? unidadesTematicasExercicios.length : 0}
							next={() => getUnidadesTematicas(nextPage)}
							hasMore={!!theresMoreData}
							height={exerciciosTabPanelHeight}
							style={{maxHeight: '60vh'}}
						>
							{unidadesTematicasExercicios?.map((unidadeTematica) => (
								<ExercicioAccordion
									key={unidadeTematica.id}
									unidadeTematica={unidadeTematica}
								/>
							))}
						</InfiniteScroll>
					</TabPanel>
				</TabsContainer>
			)}
			<img
				style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
				src={rightTopImage}
				alt="Crianças no chão"
			/>
		</>
	);
};

export default Aulas