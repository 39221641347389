import axios, { AxiosInstance } from "axios";

import fernet from 'fernet'
import { REACT_APP_FERNET_DECRYPTION_KEY } from "../env";

import { protocol } from "../utils";

class Api {
    private api: AxiosInstance;

    constructor(url: string, token?: string) {
        this.api = axios.create({
            baseURL: `${protocol}://${url}/api`
        });

        if (token)
            this.api.defaults.headers.Authorization = `Bearer ${token}`
    }

    // GETS
    getUnidadesTematicas = (params?: GetUnidadesTematicasParams) => this.api.get<PaginatedData<UnidadeTematica>>('unidadetematica/', { params })
    getVideoaulas = (unidadeTematica: number) => this.api.get<PaginatedData<Videoaula>>('videoaula/', { params: { aula__unidades_tematicas: unidadeTematica } })
    getDisciplinas = () => this.api.get<Disciplina[]>('query/disc/')
    getProfile = () => this.api.get<Profile>('user/profile/data/')
    getEscola = () => this.api.get<InfoEscolaAluno>('escola/')
    getDesempenhoGeral = (aluno: number, serie: number, disciplina: number) => this.api.get<Desempenho>(`desempenho/aluno/${aluno}/serie/${serie}/disciplina/${disciplina}`)
    getDesempenhoUnidadeTematicas = (aluno: number, serie: number, disciplina: number) => this.api.get<DesempenhoUnidadeTematica[]>(`desempenho/aluno/${aluno}/serie/${serie}/disciplina/${disciplina}/unidades-tematicas/`)
    getAulas = (unidadeTematica: number) => this.api.get<Aula[]>('2.0/exercicios/', { params: { unidades_tematicas: unidadeTematica } })
    getRelatorio = (aula: number) => this.api.get<RelatorioData>('aluno/relatorio/exercicios/', { params: { aula } })
    searchAulas = (params?: SearchVideoaulaParams) => this.api.get<PaginatedData<Videoaula>>('videoaula/search/', { params })
    getExercicio = (id: number) => this.api.get<Exercicio>(`2.0/questoes/${id}/`, {
        transformResponse: (data: string) => {
            const parsedData = JSON.parse(data)

            if (parsedData.id) return parsedData
            else {
                if (REACT_APP_FERNET_DECRYPTION_KEY) {
                    const secret = new fernet.Secret(REACT_APP_FERNET_DECRYPTION_KEY)
                    const exercicio = new fernet.Token({
                        secret,
                        token: data,
                        ttl: 0
                    })

                    return JSON.parse(exercicio.decode()) as unknown as Exercicio
                }
            }
        }
    })

    // POSTS
    login = (data: LoginData) => this.api.post<Auth>('token/', data)
    changePassword = (data: ChangePasswordData) => this.api.put('user/password/change/', data)
    resetPassword = (data: ResetPasswordData) => this.api.post('custom_password_reset/', data)
    responderExercicio = (data: ResponderExercicioData) => this.api.post('respostas/', data)
    assistirAula = (videoaula: number) => this.api.post('videoaula/assistida/', { aula_assitida: videoaula })
    editProfile = (data: Partial<Profile>) => this.api.put('user/profile/data/', data)

    // PATCH
    updateExerciseListStatus = (id: number, status: ExerciseListStatus) => this.api.patch(`exercise-list/${id}/`, {status})
}

export default Api

interface PaginatedData<T> {
    count: number
    next: string
    previous: string
    results: T[]
}

interface GetUnidadesTematicasParams {
    aulas__disciplina?: number
    aulas__serie?: number
    page?: number
}

export interface UnidadeTematica {
    id: number
    nome: string
    quantidade_anexos: number
    quantidade_exercicios: number
    quantidade_videoaulas: number
    quantidade_mapas_mentais: number
    anexos: Anexo[]
    mapas_mentais: Anexo[]
}

export interface Anexo {
    id: number
    titulo: string
    arquivo: string
}

export interface Videoaula {
    id: number
    titulo: string
    assistido: boolean
    uri: string
    unidade_tematica: UnidadeTematica[]
    descricao: string
    thumbnail: Thumbnail
    aula: number
    disciplina: number
    is_prova_brasil: boolean
}

interface Thumbnail {
    uri: string
}

export interface Disciplina {
    id: number
    nome: string
    is_prova_brasil: boolean
}

interface LoginData {
    username: string
    password: string
}

interface Auth {
    access: string
}

export interface Profile {
    avatar_id: number
    email: string
    id: number
    user_id: number
    username: string
    responsaveis: Responsavel[]
    nome: string
    role: number
    registration: string
    telefone_celular: string
}

interface Responsavel {
    username: string
}

interface ChangePasswordData {
    old_password: string
    new_password: string
}

interface ResetPasswordData {
    username: string
}

interface InfoEscolaAluno {
    id: number
    nome: string
    serie: number
    turma_cod: string
    turma_turn: string
}

export interface ResponderExercicioData {
    exercicio: number
    alternativa: number
}

export interface Desempenho {
    acertos: number
    total_exercicios: number
    total_exercicios_respondidos: number
    total_videoaulas: number
    videoaulas_assistidas: number
}

export interface DesempenhoUnidadeTematica {
    acertos: number
    indice: number
    total_exercicios: number
    total_exercicios_respondidos: number
    total_videoaulas: number
    videoaulas_assistidas: number
    unidade_tematica: string
    unidade_tematica_id: number
    assuntos: AssuntoDesempenho[]
}

interface AssuntoDesempenho {
    nome: string
    has_desempenho: boolean
}

export enum ExerciseListStatus {
    STARTED = 0,
    REDOING = 1,
    FINISHED = 2
}

export interface Aula {
    id: number
    assunto: string
    exercicios: ExercicioAula[]
    status: ExerciseListStatus
    exercise_list_student: number
}

export interface ExercicioAula {
    id: number
    respondido: boolean
    acertou: boolean
    tries: number
}

export interface Tentativa {
    resultado: ResultadoRelatorio
    exercicios: ExercicioRelatorio[]
}

interface RelatorioData {
    tentativas: Tentativa[]
    status: ExerciseListStatus
}

interface ResultadoRelatorio {
    corretas: number
    erradas: number
}

export interface ExercicioRelatorio {
    correta: boolean
    enunciado: string
    exercicio_id: number
    alternativa_selecionada: string
    alternativa_selecionada_id: number
    resposta_correta: string
    justificativa: string
}

export interface SearchVideoaulaParams {
    limit: number
    search_term?: string
    serie?: number
    disciplina?: number
    offset?: number
}

export interface Exercicio {
    id: number
    enunciado: string
    alternativas: Alternativa[]
    ultima_resposta: number
    tries: number
}

export interface Alternativa {
    id: number
    enunciado: string
    correta: boolean
    explicacao: string
}