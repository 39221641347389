import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player/vimeo';

import { Globals } from '../../globals';

export const MainContainer = styled.main`
	display: flex;
	top: 184px;
	left: 343px;
	position: absolute;
	gap: 51px;
	flex-direction: row;
    flex-wrap: wrap;
	@media (max-width: 1300px) {
		padding-bottom: 30px;
	}
`;

export const VideoAulaContainer = styled.section`
	@media screen and (max-width: 1000px) {
		width: 410px;
	}
	@media screen and (min-width: 1001px) and (max-width: 1300px) {
		width: 585px;
	}
	@media screen and (min-width: 1301px) and (max-width: 1600px) {
		width: 650px;
	}
	@media screen and (min-width: 1601px) {
		width: 630px;
	}
`;

export const VideoContent = styled.div`
	height: 351px;
	margin-bottom: 24px;
	position: relative;

	iframe {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	display: flex;
	justify-content: center;
	align-items: center;

	
	@media (max-width: 1000px) {
		width: 410px;
		justify-content: flex-start;
	}
	
`;

export const VideosUnidadeTematica = styled.aside`
	border: 1px solid #07b0ff99;
	border-radius: 8px;
	background: #f7fcfe 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 10px #07b0ff1a;
	padding: 10px 0;
	height: 540px;

	@media (max-width: 1000px) {
		width: 410px;
	}
	@media (max-width: 1300px) {
		width: 260px;
	}

	@media (max-width: 1600px) {
		width: 290px;
	}

	@media (min-width: 1601px) {
		width: 320px;
	}
`;

export const BtnExerciciosRelacionados = styled(Globals.GeneralButton).attrs((props: any) => ({
	component: RouterLink,
	to: props.to,
}))`
	width: 223px;
	margin-top: 16px;
	font: Bold 14px/16px Ubuntu;
	padding: 0 23px;
`;

export const VideosUnidadeTematicaList = styled.ul`
	list-style: none;
	height: 100%;
`;

export const VimeoPlayer = styled(ReactPlayer)`
	height: 100%;
	width: 100%;
`;

export const VideoStatusContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
`