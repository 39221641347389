import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import DesempenhoCircularProgressBar from '../../../components/desempenhoCircularProgressBar';
import Avatar from '../../../components/Avatar';

import iconAulasAssistidas from '../../../assets/img/play-circle-regular.svg';
import iconCheck from '../../../assets/img/check-circle-regular.svg';
import iconStream from '../../../assets/img/stream-solid.svg';

import { Desempenho } from '../../../services';

import { useAppContext } from '../../../contexts/AppContext';

import { Globals } from '../../../globals';

import { AlunoInfoContainer, AvatarContainer, SpanNomeAluno, useStyles } from './styles';

const SideCard = ({ desempenhoGeral }: SideCardProps) => {
	const { nomeAluno } = useAppContext()
	const classes = useStyles();

	const serie = useSelector((state: any) => state.navigation.serie || 5);

	const desempenhoPorcentagem = useMemo(() => {
		if (!desempenhoGeral || !desempenhoGeral.total_exercicios_respondidos) return 0

		return (desempenhoGeral.acertos / desempenhoGeral.total_exercicios_respondidos) * 100
	}, [desempenhoGeral]);

	const fraseConceito = useMemo(() => {
		if (0 <= desempenhoPorcentagem && desempenhoPorcentagem <= 25) return 'Precisa estudar mais!';
		if (26 <= desempenhoPorcentagem && desempenhoPorcentagem <= 50) return 'Tá melhorando!';
		if (51 <= desempenhoPorcentagem && desempenhoPorcentagem <= 75) return 'Tá quase lá!';
		if (desempenhoPorcentagem > 75) return 'Parabéns, você é um Super Aluno!';

		return ''
	}, [desempenhoPorcentagem])

	return (
		<Globals.AlunoCard className={classes.root}>
			<AvatarContainer>
				<Avatar style={{ marginRight: 18, height: 57 }}></Avatar>
				<AlunoInfoContainer>
					<SpanNomeAluno>{nomeAluno}</SpanNomeAluno>
					<span>{serie}º ano</span>
				</AlunoInfoContainer>
			</AvatarContainer>
			<Box display="flex" flexDirection="row" p={0} m={0} className={classes.adjustmentPercent}>
				<Box p={1}>
					<DesempenhoCircularProgressBar percentage={desempenhoPorcentagem} />
				</Box>
				<Box p={1} className={classes.adjustmentLabelAulasAssistidas}>
					<div>
						<span className={classes.desempenhoLabel}>{fraseConceito}</span>
					</div>
				</Box>
			</Box>
			<Typography variant="body2" color="textSecondary" component="p">
				<Box display="flex" flexDirection="row" p={0} m={0}>
					<Box p={1}>
						<img
							src={iconAulasAssistidas}
							className={classes.iconAulasAssistidas}
							alt="Aulas assistidas"
						/>
					</Box>
					<Box p={1}>
						<div>
							<span
								className={clsx(classes.blueNumber, classes.desempenhoNumber)}
							>{`${desempenhoGeral?.videoaulas_assistidas}/${desempenhoGeral?.total_videoaulas}`}</span>
							<br />
							<span className={classes.desempenhoLabel}>Aulas assistidas</span>
						</div>
					</Box>
				</Box>
				<Box display="flex" flexDirection="row" p={0} m={0}>
					<Box p={1}>
						<img
							src={iconStream}
							className={classes.iconExerciciosRespondidos}
							alt="Exercícios respondidos"
						/>
					</Box>
					<Box p={1}>
						<div>
							<span
								className={clsx(classes.blueNumber, classes.desempenhoNumber)}
							>{`${desempenhoGeral?.total_exercicios_respondidos}/${desempenhoGeral?.total_exercicios}`}</span>
							<br />
							<span className={classes.desempenhoLabel}>Exercícios respondidos</span>
						</div>
					</Box>
				</Box>
				<Box display="flex" flexDirection="row" p={0} m={0}>
					<Box p={1}>
						<img src={iconCheck} className={classes.iconAulasAssistidas} alt="Aulas assistidas" />
					</Box>
					<Box p={1}>
						<div>
							<span
								className={clsx(classes.greenNumber, classes.desempenhoNumber)}
							>{`${desempenhoGeral?.acertos}/${desempenhoGeral?.total_exercicios_respondidos}`}</span>
							<br />
							<span className={classes.desempenhoLabel}>Questões corretas</span>
						</div>
					</Box>
				</Box>
			</Typography>
		</Globals.AlunoCard>
	);
}

export default SideCard

interface SideCardProps {
	desempenhoGeral: Desempenho | undefined
}
