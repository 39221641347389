/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IconButton, CircularProgress, InputBase, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import SelectArrow from '../../../components/SelectArrow';
import Input from '../../../components/Input';

import { AuthCreators } from '../../../store/ducks/auth';
import { ModalCreators } from '../../../store/ducks/modal';

import Api from '../../../services';

import {
	BackButton,
	BelowLoginButton,
	BtnAjuda,
	BtnLogin,
	BtnLoginText,
	BtnRecoverPassword,
	EnvironmentOption,
	EnvironmentsDropdown,
	ErrorMessage,
	Form,
	FormActions,
	FormGroup,
	MsgBelowLogo,
	useStyles,
} from './style';
import { NavigationCreators } from '../../../store/ducks/navigation';

const LoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [passwordIsVisible, setPasswordVisible] = useState(false);
	const [password, setPassword] = useState('');
	const [environments, setEnvironments] = useState<Environment[]>([]);
	const [matriculaLogin, setMatriculaLogin] = useState('');
	const [selectedEnvironment, setSelectedEnvironment] = useState<Environment>();
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [isLoading, setLoading] = useState(false);

	const classes = useStyles();

	const resizeHanlder = () => {
		const heightWindow = window.innerHeight;
		const rootEl = document.getElementById('root');

		if (heightWindow < 601) {
			if (rootEl) {
				rootEl.style.height = `${heightWindow + Math.abs(heightWindow - 620)}px`;
			}
		} else {
			if (rootEl) {
				rootEl.style.height = `100%`;
			}
		}
	};

	useEffect(() => {
		const rootEl = document.getElementById('root');
		if (rootEl) {
			rootEl.style.height = `100%`;
			rootEl.style.width = `100%`;
		}
		resizeHanlder();
		window.addEventListener('resize', resizeHanlder);
		const { environments, matriculaLogin } = history.location.state as PasswordInput;

		if (environments.length === 1) setSelectedEnvironment(environments[0]);

		setEnvironments(environments);
		setMatriculaLogin(matriculaLogin);

		return () => {
			const rootEl = document.getElementById('root');
			const body = document.body;

			body.style.height = `100%`;
			if (rootEl) {
				rootEl.style.height = `100%`;
			}
			window.removeEventListener('resize', resizeHanlder);
		};
	}, []);

	useEffect(() => {
		if (error) setError(false);
	}, [password]); // react-eslint-ignore-line

	const login = async (event: React.FormEvent) => {
		event.preventDefault();

		try {
			if (!selectedEnvironment) return;

			const { matriculaLogin } = history.location.state as PasswordInput;

			const formattedUsername = matriculaLogin.replace('-', '');

			setLoading(true);

			const url = selectedEnvironment.domain_url;

			const {
				data: { access },
			} = await new Api(url).login({ username: formattedUsername, password });
			const {
				data: { serie },
			} = await new Api(url, access).getEscola();

			dispatch(AuthCreators.setAuth(access, url));
			dispatch(NavigationCreators.setSerie(serie));
		} catch (error) {
			setError(true);
			switch (error.response.status) {
				case 401:
					return setErrorText('Login ou senha inválida');
				case 403:
					return setErrorText('Usuário sem permissão');
				case 404:
					return setErrorText('O servidor não pode encontrar o recurso solicitado.');
				case 405:
					return setErrorText(
						'O método de solicitação é conhecido pelo servidor, mas foi desativado e não pode ser usado.'
					);
				case 408:
					return setErrorText('Tempo esgotado da solicitação');
				case 500:
					return setErrorText('Erro interno do servidor');
				default:
					return setErrorText('Ocorreu um erro na solicitação, por favor tente novamente');
			}
		} finally {
			setLoading(false);
		}
	};

	const handleClickBackButton = () => {
		dispatch(AuthCreators.resetAuth());
		history.goBack();
	};

	return (
		<>
			<BackButton
				startIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 16 }}></FontAwesomeIcon>}
				onClick={handleClickBackButton}
			>
				Voltar
			</BackButton>
			<MsgBelowLogo>
				Para começar a estudar
				<br /> faça seu login!
			</MsgBelowLogo>
			<ErrorMessage visible={error}>{errorText}</ErrorMessage>
			<FormGroup>
				<Form>
					{environments.length > 1 ? (
						<EnvironmentsDropdown
							value={selectedEnvironment?.id}
							input={<InputBase></InputBase>}
							IconComponent={SelectArrow}
							onChange={event => {
								const selectedEnvironment = environments.find(
									({ id }) => id === event.target.value
								);
								setSelectedEnvironment(selectedEnvironment);
							}}
						>
							{environments.map(({ id, name }) => (
								<EnvironmentOption key={id} value={id}>
									{name}
								</EnvironmentOption>
							))}
						</EnvironmentsDropdown>
					) : (
						<Input
							id="login"
							value={matriculaLogin}
							disabled
							classes={{ root: classes.input }}
							labelWidth={42}
						></Input>
					)}
					<Input
						id="senha"
						type={passwordIsVisible ? 'text' : 'password'}
						value={password}
						disabled={isLoading}
						onChange={e => setPassword(e.target.value)}
						error={error}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setPasswordVisible(!passwordIsVisible)}
									edge="end"
								>
									{passwordIsVisible ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						labelWidth={46}
						classes={{ root: classes.input }}
					/>
					<FormActions>
						<BtnLogin
							disabled={!password}
							style={{ backgroundColor: password ? '#09bdf0' : '#7fddf7', color: 'white' }}
							onClick={login}
							loading={isLoading}
						>
							{isLoading ? (
								<CircularProgress size={24} />
							) : (
								<>
									<div></div>
									<BtnLoginText>Entrar</BtnLoginText>
									<FontAwesomeIcon icon={faChevronRight} />
								</>
							)}
						</BtnLogin>
					</FormActions>
				</Form>
				<BelowLoginButton>
					<BtnRecoverPassword to="/password-recovery">Esqueci a senha</BtnRecoverPassword>
					<BtnAjuda onClick={() => dispatch(ModalCreators.open('AJUDA'))}></BtnAjuda>
				</BelowLoginButton>
			</FormGroup>
		</>
	);
};

export default LoginForm;

interface Environment {
	id: number;
	name: string;
	domain_url: string;
}

interface PasswordInput {
	environments: Environment[];
	matriculaLogin: string;
}

interface Auth {
	access: string;
}
