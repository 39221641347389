import styled from 'styled-components';

import topRightImage from '../../assets/img/canto-relatorio.png';

export const TopRightImage = styled.img.attrs(() => ({ src: topRightImage }))`
	position: absolute;
	top: 0;
	right: 0;
`;

export const AsideContainer = styled.aside`
	position: absolute;
	top: 104px;
	left: 40px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	font-family: Ubuntu;
	z-index: 1;
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 24px 24px 14px;
	box-shadow: 0px 3px 10px #0013ff26;
	border-radius: 20px;
	background: #f7fcfe 0% 0% no-repeat padding-box;
`;

export const AlunoInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 20px;
`;
export const SpanNomeAluno = styled.span`
	color: #09bdf0;
	font-weight: bold;
	text-transform: capitalize;
`;

export const QuestoesContainer = styled.div.attrs((props: any) => ({ corretas: props.corretas }))`
	background: #f7fcfe 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 10px #0013ff26;
	border: 2px solid ${props => (props.corretas ? '#14E7C4' : '#FC6921')};
	border-radius: 20px;
	padding: 24px 21px;
`;

export const QuestoesInfo = styled.p`
	color: black;
	font-size: 14px;
	margin-bottom: 16px;
`;

export const ExerciciosGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;
`;

export const ExercicioBichinho = styled.div.attrs((props: any) => ({ correto: props.correto }))`
	width: 107px;
	border: 2px solid rgba(${props => (props.correto ? '20, 231, 196, 0.6' : '252, 105, 33, 0.8')});
	border-radius: 8px;
	padding: 10px;
	display: flex;
	justify-content: space-around;
	cursor: pointer;
	transition: 0.25s ease;

	&:hover {
		transform: translateY(-6px);
	}
`;

export const ExercicioLabel = styled.span`
	color: black;
	font-family: Ubuntu;
	font-size: 12px;
`;

export const ExercicioContainer = styled.div`
	position: relative;
	left: 343px;
	top: 19px;
	display: flex;
	flex-direction: column;
	width: 1000px;
`;

export const ExercicioLabelMain = styled.p`
	color: #07b0ff;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 8px;
`;

export const EnunciadoExercicio = styled.div.attrs((props: any) => ({
	enunciado: props.enunciado,
	dangerouslySetInnerHTML: { __html: props.enunciado },
}))`
	color: #2f2f30;
	font-size: 16px;
	margin-bottom: 24px;
	width: 70vw;
`;

export const AlternativasContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 16px;
	margin-bottom: 32px;
`;

export const AlternativaContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, max-content);
	column-gap: 11px;
`;

export const AlternativaBox = styled.div.attrs((props: any) => ({ correta: props.correta, selecionada: props.selecionada }))`
	border-radius: 4px;
	width: 35px;
	height: 35px;
	background-color: ${props => getAlternativaBackgroundColor(props)};
	color: white;
	text-align: center;
	line-height: 35px;
`;

export const AlternativaLabel = styled.p.attrs((props: any) => ({
	correta: props.correta,
	selecionada: props.selecionada,
	enunciado: props.enunciado,
	dangerouslySetInnerHTML: { __html: props.enunciado },
}))`
	font-size: 16px;
	color: ${props => getFontColor(props)};
	img {
		width: 10%;
	}
`;

export const JustificativaLabel = styled.p`
	font-size: 20px;
	margin-bottom: 8px;
`;

export const Justificativa = styled.div.attrs((props: any) => ({
	justificativa: props.justificativa,
	dangerouslySetInnerHTML: { __html: props.justificativa },
}))`
	font-size: 16px;
	width: 62vw;
`;

const getAlternativaBackgroundColor = (props: any) => {
	const { selecionada, correta } = props;

	if (correta) return '#15DDBD';

	if (!selecionada && !correta) return '#C5C5C5';

	if (selecionada && !correta) return '#FC6921';
};

const getFontColor = (props: any) => {
	const { selecionada, correta } = props;

	if (!selecionada && !correta) return '#C5C5C5';

	return '#2F2F30';
};
