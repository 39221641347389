import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Typography,
	Button,
} from '@material-ui/core';

import { ModalCreators, ModalName } from '../../store/ducks/modal';
import { AuthCreators } from '../../store/ducks/auth';
import { NavigationCreators } from '../../store/ducks/navigation';

import localforage from 'localforage';

import { REACT_APP_STORAGE_KEY } from '../../env';

import closeModalIcon from '../../assets/img/grey-times-solid.svg';

const LogoutModal = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const isOpen = useSelector((state: any) => state.modal.name === ModalName.LOGOUT);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={() => dispatch(ModalCreators.close())}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton
								onClick={() => dispatch(ModalCreators.close())}
								style={{ marginBottom: 2 }}
							>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
					/>
					<CardContent className={classes.cardContent}>
						<Typography style={{ fontSize: 20, marginBottom: 16, fontWeight: 500 }}>
							Deseja mesmo sair?
						</Typography>
						<Button
							onClick={() => {
								window.localStorage.setItem('isLoggedOut', 'yes');
								dispatch(AuthCreators.resetAuth());
								dispatch(NavigationCreators.reset());
								dispatch(ModalCreators.close());
								localforage.removeItem(`persist:${REACT_APP_STORAGE_KEY as string}`);
							}}
							className={classes.sairButton}
						>
							SAIR
						</Button>
						<Button
							onClick={() => dispatch(ModalCreators.close())}
							className={classes.backToAppButton}
						>
							Voltar ao Super Aluno
						</Button>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default LogoutModal

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			width: 364,
			height: 235,
			borderRadius: 20,
			paddingBottom: 70,
			background: '#f7fcfe',
		},
		cardHeader: {
			height: 50,
		},
		cardHeaderTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 500,
		},
		cardContent: {
			color: '#2F2F30',
			paddingLeft: 72,
			paddingRight: 72,
			paddingBottom: 70,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			paddingTop: 6,
		},
		sairButton: {
			color: 'white',
			width: 169,
			height: 44,
			borderRadius: 8,
			background: 'transparent linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%) 0% 0% no-repeat padding-box',
			fontSize: 14,
			fontWeight: 'bold',
			marginBottom: 12,
		},
		backToAppButton: {
			fontSize: 14,
			marginBottom: 16,
			fontWeight: 500,
			color: '#09BDF0',
			textTransform: 'none',
		},
	})
);
