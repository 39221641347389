/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';

import { useAppContext } from '../../contexts/AppContext';

import { Container, LupaContainer, SearchField, Suggestion, SuggestionsContainer } from './style';

import lupa from '../../assets/img/lupa-aula-search-field.svg';

const AulaSearchField = ({ style }: AulaSearchFieldProps) => {
	const { searchTermSuggestions, searchTerm, setSearchTerm, disciplinas } = useAppContext()

	const disciplina = useSelector((state: any) => state.navigation.disciplina);

	const history = useHistory();
	const location = useLocation()

	const classes = useStyles();

	const containerRef = useRef<HTMLFormElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const containerBaseWidth = window.innerWidth > 1024 ? 347 : 300;

	const [containerWidth, setContainerWidth] = useState(containerBaseWidth);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [searchSuggestionsInitialLeftPosition, setSearchSuggestionsInitialLeftPosition] = useState<number>();

	useEffect(() => {
		setSearchSuggestionsInitialLeftPosition(containerRef.current?.getBoundingClientRect().left);
	}, []);

	useEffect(() => {
		setShowSuggestions(!!searchTermSuggestions.length);
	}, [searchTermSuggestions]);

	const chipLabel = disciplinas.find(d => d.id === disciplina)?.nome || ''

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (searchTerm) {
			const aulasSearchPathname = '/aula-search';

			history[location.pathname === aulasSearchPathname ? 'replace' : 'push'](aulasSearchPathname, { searchTerm })
		}
	};

	const handleClickSuggestion = (e: any) => {
		if (navigator.onLine) {
			const searchTerm = e.currentTarget.textContent;

			setSearchTerm(searchTerm)

			const aulasSearchPathname = '/aula-search';

			history[location.pathname === aulasSearchPathname ? 'replace' : 'push'](aulasSearchPathname, { searchTerm })
		}
	};

	return (
		<>
			<Container ref={containerRef} style={style} onSubmit={handleSubmit} width={containerWidth}>
				<SearchField
					id="aula-search-field-container"
					ref={inputRef}
					placeholder="Procure por aulas"
					value={searchTerm}
					onChange={e => setSearchTerm(e.target.value.trim())}
					onFocus={() => {
						setContainerWidth(500);
						searchTermSuggestions.length > 0 && setShowSuggestions(true);
					}}
					onBlur={() => {
						setContainerWidth(containerBaseWidth);
						setShowSuggestions(false);
					}}
				></SearchField>
				{chipLabel && (
					<Chip
						size="small"
						label={chipLabel}
						style={{ backgroundColor: '#09BDF0', color: 'white', flexShrink: 0 }}
						onDelete={() => { }}
						classes={{
							deleteIconSmall: classes.deleteIcon,
						}}
					></Chip>
				)}
				<LupaContainer onClick={handleSubmit} disabled={false}>
					<img src={lupa} alt="Lupa" />
				</LupaContainer>
			</Container>
			<SuggestionsContainer isOpen={showSuggestions}
				position={{
					top: containerRef.current?.getBoundingClientRect().bottom,
					left: searchSuggestionsInitialLeftPosition,
				}} width={containerWidth}>
				{searchTermSuggestions.map((assunto, index) => (
					<Suggestion key={index} onClick={handleClickSuggestion}>
						<img src={lupa} alt="Lupa" />
						{assunto}
					</Suggestion>
				))}
			</SuggestionsContainer>
		</>
	);
};

export default AulaSearchField;

interface AulaSearchFieldProps {
	style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
	deleteIcon: {
		color: 'white',
		'&:hover': {
			color: '#FCE797',
		},
	},
}));
