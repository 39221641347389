import greenBottomLeftBackground from './green-bottom-left-background.svg';
import greenBottomRightBackground from './green-bottom-right-background.png';
import greenChar from './green-char.png';

import orangeBottomLeftBackground from './orange-bottom-left-background.png';
import orangeBottomRightBackground from './orange-bottom-right-background.png';
import orangeChar from './orange-char.png';

import redBottomLeftBackground from './red-bottom-left-background.svg';
import redBottomRightBackground from './red-bottom-right-background.png';
import redChar from './red-char.png';

import yellowBottomLeftBackground from './yellow-bottom-left-background.svg';
import yellowBottomRightBackground from './yellow-bottom-right-background.png';
import yellowChar from './yellow-char.png';

const assets: Performance[] = [
	{
		performance: 'green',
		charAsset: greenChar,
		bottomLeftBackground: greenBottomLeftBackground,
		bottomRightBackground: greenBottomRightBackground,
		motivationalPhrase: 'Parabéns! Você é um Super Aluno!',
	},
	{
		performance: 'yellow',
		charAsset: yellowChar,
		bottomLeftBackground: yellowBottomLeftBackground,
		bottomRightBackground: yellowBottomRightBackground,
		motivationalPhrase: 'Agora falta pouco para ser um Super Aluno!',
	},
	{
		performance: 'orange',
		charAsset: orangeChar,
		bottomLeftBackground: orangeBottomLeftBackground,
		bottomRightBackground: orangeBottomRightBackground,
		motivationalPhrase: 'Muito bem! Já estamos vendo sua evolução!',
	},
	{
		performance: 'red',
		charAsset: redChar,
		bottomLeftBackground: redBottomLeftBackground,
		bottomRightBackground: redBottomRightBackground,
		motivationalPhrase: 'Você pode melhorar! Continue praticando!',
	},
];

export { assets };

export interface Performance {
	performance: string;
	charAsset: string;
	bottomLeftBackground: string;
	bottomRightBackground: string;
	motivationalPhrase: string;
}
