import axios from 'axios';

import { REACT_APP_STAGING_URL, REACT_APP_IS_DEV, REACT_APP_WORKSPACES_MANAGER_DOMAIN } from './env';

export const protocol = REACT_APP_STAGING_URL || !REACT_APP_IS_DEV ? 'https' : 'http';

export const getEnvironments = async (username: string) => {
	if (REACT_APP_STAGING_URL)
		return [
			{
				id: 0,
				name: 'Staging Environment',
				domain_url: REACT_APP_STAGING_URL,
			},
		];

	if (REACT_APP_IS_DEV)
		return [
			{
				id: 0,
				name: 'Development Environment',
				domain_url: 'localhost:8000',
			},
		];

	try {
		const { data: environments } = await axios.get(
			`https://${REACT_APP_WORKSPACES_MANAGER_DOMAIN}/api/find/workspaces/`,
			{
				params: { username },
			}
		);

		if (!environments.length) throw Error();

		return environments;
	} catch (error) {
		if ('response' in (error as Object)) throw Error('Erro interno do servidor');

		throw Error('Login inválido');
	}
};

export const getReducedName = (name: string) => {
	try {
		const splittedName = name.split(' ');

		return `${splittedName[0]} ${splittedName[splittedName.length - 1]}`.toLowerCase();
	} catch {
		return '';
	}
};
