import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';

const PasswordRecoveryFeedback: React.FC<any> = ({ location }) => {
	const classes = useStyles();
	const history = useHistory();

	const { message } = location.state;

	return (
		<div className={classes.recoveryFeedback}>
			<p className={classes.message}>
				{message}
				<br />
				<span className={classes.email}>{` ${location.state.email}`}</span>
			</p>
			<Button
				className={classes.backToLogin}
				onClick={() => history.go(-2)}
				startIcon={<ChevronLeft> </ChevronLeft>}
			>
				Voltar ao login
			</Button>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recoveryFeedback: {
			width: 260,
		},
		message: {
			fontWeight: 500,
			fontSize: 20,
			fontFamily: theme.typography.fontFamily,
			borderBottom: 'rgba(221,216,216,0.5) 1px solid',
			paddingBottom: 16,
		},
		email: {
			font: 'Bold 20px/24px Ubuntu',
			color: '#07B0FF',
		},
		backToLogin: {
			width: '100%',
			backgroundColor: 'white',
			height: 36,
			textTransform: 'none',
		},
	})
);

export default PasswordRecoveryFeedback;
