import styled from 'styled-components';

import { OutlinedInput, FormControl } from '@material-ui/core';

export const StyledFormControl = styled(FormControl)`
	.MuiFormLabel-root.Mui-focused {
		color: #08adf8;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: #08adf8;
	}
`;

export const StyledInput = styled(OutlinedInput)`
	color: #2f2f30;
	opacity: 0.8;
	border-radius: 8px;
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}
`;
