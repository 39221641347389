import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface AuthActions {
	SET_AUTH: string;
	RESET_AUTH: string;
}

interface AuthCreators {
	setAuth: (token: string, domain: string) => any;
	resetAuth: () => void;
}

export interface AuthStore {
	token: string
	domain: string
}

// initial state
const INITIAL_STATE: AuthStore = {
	token: '',
	domain: ''
};

// reducers
const setAuth = (_: any, action: any) => ({ token: action.token, domain: action.domain });
const resetAuth = () => INITIAL_STATE;

// actions
export const { Types: AuthTypes, Creators: AuthCreators } = createActions<AuthActions, AuthCreators>({
	setAuth: ['token', 'domain'],
	resetAuth: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
	[AuthTypes.SET_AUTH]: setAuth,
	[AuthTypes.RESET_AUTH]: resetAuth,
});
