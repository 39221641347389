import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { validate } from 'email-validator';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import clsx from 'clsx'

import Input from '../../../components/Input'

const PasswordRecoveryForm = () => {
	// TODO: Reformular tela de recuperação de senha
	const [email, setEmail] = useState('');
	const [emailIsValid, setEmailIsValid] = useState(true);
	const [loading,] = useState(false)

	const classes = useStyles();
	const history = useHistory();

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setEmailIsValid(true);
	};

	const handlePasswordRecovery = (event: FormEvent) => {
		event.preventDefault();

		alert('Recuperação de senha desativada temporariamente')

		if (!validate(email)) {
			setEmailIsValid(false)
			return
		}
	};

	useEffect(() => {
		document.title = 'Esqueci a senha - Super Aluno';
	}, []);

	return (
		<div className={classes.container}>
			<form onSubmit={handlePasswordRecovery}>
				<p className={classes.msgAboveInput}>
					<p className={classes.msgAboveInput}>Digite seu email abaixo</p>
				</p>
				<p className={clsx(classes.errorMessage, !emailIsValid && classes.errorMessageVisible)}>Insira um email válido</p>
				<Input id="email" error={!emailIsValid} value={email} onChange={handleChangeEmail} className={classes.emailInput} labelWidth={40}></Input>
				<Button
					className={classes.submitButton}
					style={{ backgroundColor: !email ? '#75DCF5' : '#09BDF0', color: 'white' }}
					disabled={loading || !email}
					type="submit"
					disableFocusRipple
				>
					{loading ? <CircularProgress size={24} /> : 'Recuperar senha'}
				</Button>
			</form>
			<Button
				className={classes.backToLogin}
				onClick={() => history.goBack()}
				startIcon={<ChevronLeft> </ChevronLeft>}
			>
				Voltar ao login
			</Button>
			{/* <Button className={classes.ajudaButton} onClick={() => setAjudaModalIsOpen(true)}> */}
			<Button className={classes.ajudaButton} onClick={() => { }}>
				Ajuda
			</Button>
		</div>
	);
};

export default PasswordRecoveryForm

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: 260,
			color: '#2F2F30',
		},
		msgAboveInput: {
			fontFamily: theme.typography.fontFamily,
			fontWeight: 500,
			fontSize: 20,
			textAlign: 'center',
			marginBottom: 8,
		},
		emailInputContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			borderRadius: 8,
			height: 44,
			marginBottom: 16,
			paddingLeft: 16,
			paddingRight: 16,
			border: '1px solid #C5C5C5',
		},
		emailInput: {
			width: '100%',
			fontWeight: 500,
			fontSize: 16,
			border: 0,
			fontFamily: theme.typography.fontFamily,
			background: 'rgb(247, 252, 254)',
			marginBottom: 10
		},
		submitButton: {
			width: '100%',
			height: 44,
			border: 0,
			borderRadius: 8,
			color: 'white',
			font: 'Bold 16px/18px Ubuntu',
			marginBottom: 24,
			textTransform: 'none',
		},
		backToLogin: {
			width: '100%',
			height: 36,
			textTransform: 'none',
		},
		ajudaButton: {
			width: '100%',
			height: 36,
			textTransform: 'none',
			fontSize: 14,
			fontWeight: 500,
		},
		errorMessage: {
			color: '#FC2121',
			fontWeight: 500,
			fontSize: 12,
			textAlign: 'center',
			marginBottom: 8,
			visibility: 'hidden'
		},
		errorMessageVisible: {
			visibility: 'visible'
		}
	})
);
