import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

export default (props: TooltipProps) => {
	const classes = useStyles();

	return (
		<Tooltip classes={{ tooltip: classes.tooltip }} {...props}>
			{props.children}
		</Tooltip>
	);
};

const useStyles = makeStyles(() => ({
	tooltip: {
		borderRadius: 5,
		backgroundColor: '#0076AE',
		fontSize: 13,
		fontWeight: 'bold',
		color: '#F7FCFE',
	},
}));
