import React, { ChangeEvent, FC, FormEvent, useEffect, useMemo, useState } from 'react';

import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Typography,
	Button,
	CircularProgress,
} from '@material-ui/core';

import clsx from 'clsx'

import PasswordField from '../../../components/PasswordField';

import useApi from '../../../hooks/useApi';

import useStyles from './styles'

import checkCircle from '../../../assets/img/check-circle-solid.svg';
import closeModalIcon from '../../../assets/img/times-solid.svg';

const ChangePasswordModal: FC<IChangePasswordModal> = ({ open, onClose }) => {
	const classes = useStyles();

	const [newPassword, setNewPassword] = useState('');
	const [repeatNewPassword, setRepeatNewPassword] = useState('');
	const [newPasswordIsOk, setNewPasswordIsOk] = useState(false);
	const [repeatNewPasswordIsOk, setRepeatNewPasswordIsOk] = useState(false);
	const [error, setError] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('')
	const [success, setSuccess] = useState(false)
	const [processing, setProcessing] = useState(false)

	const api = useApi()

	const onCloseModal = () => {
		onClose();
		setNewPassword('');
		setRepeatNewPassword('');
		setCurrentPassword('')
		setSuccess(false)
		setError(false)
		setProcessing(false)
	};

	useEffect(() => {
		setNewPasswordIsOk(newPassword.length >= 6);
		setRepeatNewPasswordIsOk(repeatNewPassword.length > 0 && repeatNewPassword === newPassword);
		setError(repeatNewPassword.length > 0 && repeatNewPassword !== newPassword);
	}, [newPassword, repeatNewPassword]);

	const submit = (e: FormEvent) => {
		e.preventDefault()

		setProcessing(true)

		api.changePassword({ new_password: newPassword, old_password: currentPassword })
			.then(() => setSuccess(true))
			.catch(() => alert('Houve um erro ao alterar a senha. Por favor, contate o administrador'))
			.finally(() => {
				setTimeout(() => onCloseModal(), 2000)
			})
	}

	const submitButtonIsEnabled = useMemo(() => {
		return !!(newPasswordIsOk && repeatNewPasswordIsOk && currentPassword)
	}, [newPasswordIsOk, repeatNewPasswordIsOk, currentPassword])

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={onCloseModal} style={{ marginBottom: 2 }}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>MUDAR SENHA</p>}
					/>
					<CardContent className={classes.cardContent} classes={{ root: classes.cardContentRoot }}>
						{success ? (
							<>
								<div className={classes.checkCircleContainer}>
									<img
										className={classes.checkCircle}
										src={checkCircle}
										alt="Check circle"
									/>
								</div>

								<Typography className={classes.instruction}>
									Senha alterada com sucesso!
								</Typography>
							</>
						) : (
							<>
								<Typography className={classes.instruction}>
									Digite sua nova senha abaixo, depois digite-a novamente.
								</Typography>
								<Typography className={clsx(classes.errorMessage, error && classes.errorMessageVisible)}>
									Digite a mesma senha nos dois campos
								</Typography>
								<div className={classes.cardContentInputGroup} onSubmit={submit}>
									<PasswordField
										id="Senha atual"
										onChange={(event: ChangeEvent<HTMLInputElement>) => setCurrentPassword(event.target.value)}
										value={currentPassword}
										labelWidth={85}
									></PasswordField>
									<PasswordField
										id="Nova senha"
										onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
										value={newPassword}
										isOk={newPasswordIsOk}
										autoComplete="new-password"
										labelWidth={85}
									></PasswordField>
									<PasswordField
										id="Confirme a nova senha"
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											setRepeatNewPassword(event.target.value);
										}}
										value={repeatNewPassword}
										error={error}
										isOk={!error && repeatNewPasswordIsOk}
										autoComplete="repeat-new-password"
										labelWidth={171}
									></PasswordField>
									<Button
										className={classes.submitButton}
										style={{
											backgroundColor:
												!submitButtonIsEnabled
													? '#75DCF5'
													: '#09BDF0',
											color: 'white',
										}}
										disabled={!submitButtonIsEnabled}
										disableFocusRipple
										onClick={submit}
									>
										{processing ? (
											<CircularProgress size={18} />
										) : (
											'Confirmar'
										)}
									</Button>
								</div>
							</>
						)}
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default ChangePasswordModal;

interface IChangePasswordModal {
	open: boolean;
	onClose: () => void;
}
