import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
	createStyles({
		accordion: {
			border: '1px solid #07B0FF99',
			borderRadius: 8,
			background: '#F7FCFE',
			boxShadow: '0px 2px 10px #07B0FF1A',
			width: '100%',
		},
		header: {
			color: '#2F2F30',
			letterSpacing: 0,
			fontWeight: 'bold',
			fontSize: 14,
		},
		expandMoreIcon: {
			background: 'linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%)',
			height: 20,
			width: 20,
			borderRadius: 10,
			color: 'white',
		},
		details: {
			display: 'grid',
			gridRowGap: 24,
			color: 'black',
			letterSpacing: 0,
			// paddingBottom: 30,
		},
		blueNumber: {
			color: '#07B0FF',
		},
		greenNumber: {
			color: '#15DDBD',
		},
		desempenhoNumber: {
			fontSize: 22,
			fontWeight: 'bold',
		},
		desempenhoLabel: {
			fontWeight: 'normal',
			fontSize: 14,
		},
		assuntoList: {
			listStyleType: 'none',
			fontFamily: 'Ubuntu',
			fontSize: 14,
			letterSpacing: 0,
			marginLeft: 36,
		},
		assuntoLabel: {
			fontSize: 14,
		},
		accordionDetails: {
			display: 'flex',
			flexDirection: 'column',
		},
	})
);
