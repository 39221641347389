import styled from 'styled-components';

import Input, { InputProps } from '../Input';

import checkCircle from '../../assets/img/check-circle-solid.svg';
import errorCircle from '../../assets/img/error-red-times.svg';

export const PF = styled(Input).attrs((props: PasswordFieldProps) => ({ isOk: props.isOk, error: props.error }))`
	${props =>
		(props.isOk || props.error) &&
		`&:after {
			content: url(${props.isOk ? checkCircle : errorCircle});
			position: absolute;
			left: 268px;
		};	
	`}
`;

export interface PasswordFieldProps extends InputProps {
	isOk?: boolean;
	error?: boolean;
	autoComplete?: string;
}
