import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent, IconButton } from '@material-ui/core';

import { ModalCreators, ModalName } from '../../store/ducks/modal';

import closeModalIcon from '../../assets/img/grey-times-solid.svg';
import noConnectionAsset from '../../assets/img/sem-conexao.svg';

const OfflineModal = () => {
	const classes = useStyles();

	const isOpen = useSelector((state: any) => state.modal.name === ModalName.OFFLINE);

	const dispatch = useDispatch();

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={() => dispatch(ModalCreators.close())}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isOpen}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton onClick={() => dispatch(ModalCreators.close())}>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
					/>
					<CardContent className={classes.cardContent}>
						<img src={noConnectionAsset} alt="Sem conexão" />
						<p className={classes.message}>Sem conexão :( Tente se conectar novamente</p>
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default OfflineModal

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: '0px 3px 10px #0013FF26',
		},
		card: {
			width: 364,
			borderRadius: 20,
		},
		cardHeader: {
			padding: '16px 16px 0',
		},
		cardContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			padding: '0 32px 49px 20px',
			'&:last-child': {
				paddingBottom: 48,
			},
		},
		message: { fontSize: 20, fontWeight: 'bold', color: '#07B0FF', width: 169, marginLeft: 12 },
	})
);
