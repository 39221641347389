import styled from 'styled-components';

const amarelo = '#f7d757';
const laranja = '#fc6921';
const azul = '#07b0ff';
const verde = '#14e7c4';

const Loading = styled.div`
	margin: auto;

	animation: rotate 1s infinite;
	height: 50px;
	width: 50px;

	&:before,
	&:after {
		border-radius: 50%;
		content: '';
		display: block;
		height: 20px;
		width: 20px;
	}
	&:before {
		animation: ball1 1s infinite;
		background-color: ${laranja};
		box-shadow: 30px 0 0 ${amarelo};
		margin-bottom: 10px;
	}
	&:after {
		animation: ball2 1s infinite;
		background-color: ${azul};
		box-shadow: 30px 0 0 ${verde};
	}

	@keyframes rotate {
		0% {
			-webkit-transform: rotate(0deg) scale(0.8);
			-moz-transform: rotate(0deg) scale(0.8);
		}
		50% {
			-webkit-transform: rotate(360deg) scale(1.2);
			-moz-transform: rotate(360deg) scale(1.2);
		}
		100% {
			-webkit-transform: rotate(720deg) scale(0.8);
			-moz-transform: rotate(720deg) scale(0.8);
		}
	}

	@keyframes ball1 {
		0% {
			box-shadow: 30px 0 0 ${amarelo};
		}
		50% {
			box-shadow: 0 0 0 ${amarelo};
			margin-bottom: 0;
			-webkit-transform: translate(15px, 15px);
			-moz-transform: translate(15px, 15px);
		}
		100% {
			box-shadow: 30px 0 0 ${amarelo};
			margin-bottom: 10px;
		}
	}

	@keyframes ball2 {
		0% {
			box-shadow: 30px 0 0 ${verde};
		}
		50% {
			box-shadow: 0 0 0 ${verde};
			margin-top: -20px;
			-webkit-transform: translate(15px, 15px);
			-moz-transform: translate(15px, 15px);
		}
		100% {
			box-shadow: 30px 0 0 ${verde};
			margin-top: 0;
		}
	}
`;

export default Loading;
