import { useSelector } from 'react-redux';

import Api from '../services';

const useApi = () => {
	const domain = useSelector((state: any) => state.auth.domain);
	const token = useSelector((state: any) => state.auth.token);

	return new Api(domain, token);
};

export default useApi;