import avatar1 from './Avatar-1.svg';
import avatar2 from './Avatar-2.svg';
import avatar3 from './Avatar-3.svg';
import avatar4 from './Avatar-4.svg';
import avatar5 from './Avatar-5.svg';
import avatar6 from './Avatar-6.svg';
import avatar7 from './Avatar-7.svg';
import avatar8 from './Avatar-8.svg';
import avatar9 from './Avatar-9.svg';
import avatar10 from './Avatar-10.png';
import avatar11 from './Avatar-11.png';
import avatar12 from './Avatar-12.png';
import avatar13 from './Avatar-13.png';
import avatar14 from './Avatar-14.png';
import avatar15 from './Avatar-15.png';
import avatar16 from './Avatar-16.png';
import avatar17 from './Avatar-17.png';
import avatar18 from './Avatar-18.png';
import avatar19 from './Avatar-19.png';
import avatar20 from './Avatar-20.png';
import avatar21 from './Avatar-21.png';

export default [
	avatar1,
	avatar2,
	avatar3,
	avatar4,
	avatar5,
	avatar6,
	avatar7,
	avatar8,
	avatar9,
	avatar10,
	avatar11,
	avatar12,
	avatar13,
	avatar14,
	avatar15,
	avatar16,
	avatar17,
	avatar18,
	avatar19,
	avatar20,
	avatar21,
];
