/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';

import FoundAula from './FoundAula';

import { Creators as BackgroundCreators } from '../../store/ducks/background';
import { NavBarCreators } from '../../store/ducks/navBar';

import { useAppContext } from '../../contexts/AppContext';

import useApi from '../../hooks/useApi';

import { SearchVideoaulaParams, Videoaula } from '../../services';

import rightTopImage from '../../assets/img/guri-cadeirante-e-guria-em-pe-segurando-livro.png';

import { ImagemMeninaInterrogacoes, Msg1, Msg2, NoResultSearchContainer, PageTitle, RootContainer, VideoaulasGridContainer } from './style';

type LocationState = {
	searchTerm: string
}

const AulaSearch = () => {
	const dispatch = useDispatch();

	const [offset, setOffset] = useState(0)
	const [aulas, setAulas] = useState<Videoaula[]>()
	const [theresMoreData, setTheresMoreData] = useState(false)

	const { serie, disciplina } = useSelector((state: any) => state.navigation)
	const { state } = useLocation<LocationState>()

	const { setSearchTerm } = useAppContext()

	document.title = 'Super Aluno | Resultados da busca por aula';

	useEffect(() => {
		dispatch(BackgroundCreators.setBackgrounds([]));
		dispatch(NavBarCreators.setTitle(''));
		// setSearchTerm(state.searchTerm);
		searchAulas()

		return () => setSearchTerm('');
	}, []);

	const api = useApi()

	useEffect(() => {
		searchAulas()
	}, [state])

	const searchAulas = useCallback(() => {
		const params: SearchVideoaulaParams = {
			serie,
			search_term: state.searchTerm,
			limit: 16
		}

		if (offset) params.offset = offset
		if (disciplina) params.disciplina = disciplina

		api.searchAulas(params)
			.then(({ data: { next, results } }) => {
				setAulas(aulas && offset > 0 ? [...aulas, ...results] : results)
				setTheresMoreData(!!next)

				if (next) setOffset((currentOffset) => currentOffset + 16)
			})
	}, [state, serie, disciplina, offset, aulas])

	const renderAulas = useCallback(() => {
		if (aulas) {
			return !aulas.length ? (
				<NoResultSearchContainer>
					<Msg1>Sua busca não deu resultados :(</Msg1>
					<Msg2>Você pode diminuir a quantidade de palavras ou tentar outras palavras.</Msg2>
					<ImagemMeninaInterrogacoes></ImagemMeninaInterrogacoes>
				</NoResultSearchContainer>
			) : (
				<>
					<PageTitle>Vídeos</PageTitle>
					<RootContainer>
						<InfiniteScroll
							loader={<p>Loading...</p>}
							dataLength={aulas.length}
							next={searchAulas}
							hasMore={!!theresMoreData}
							height="70vh"
						>
							<VideoaulasGridContainer>
								{
									aulas.map((aula) => (
										<FoundAula
											key={aula.id}
											videoaula={aula}
										></FoundAula>
									))
								}
							</VideoaulasGridContainer>
						</InfiniteScroll>
					</RootContainer>
				</>
			)
		}

		return null
	}, [aulas, theresMoreData, searchAulas])

	return (
		<>
			{renderAulas()}
			<img
				style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
				src={rightTopImage}
				alt="Crianças com livro"
			/>
		</>
	);
};

export default AulaSearch
