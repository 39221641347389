import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import chevronDown from '../../assets/img/white-chevron-down.svg';

export default () => (
	<IconButton className="MuiSelect-icon" style={{ top: 0, height: '100%', paddingRight: 16 }}>
		<img src={chevronDown} alt="Dropdown arrow" />
	</IconButton>
);
