import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Assunto = styled.p<{ hasDesempenho: boolean }>`
	color: ${props => (props.hasDesempenho ? '#2F2F30' : '#C5C5C5')};
	${props => props.hasDesempenho && 'cursor: pointer'};
	margin: 5px 0;
`;

export default makeStyles(() =>
	createStyles({
		accordion: {
			border: '1px solid #07B0FF99',
			borderRadius: 8,
			background: '#F7FCFE',
			boxShadow: '0px 2px 10px #07B0FF1A',
			width: 420,
		},
		header: {
			color: '#2F2F30',
			letterSpacing: 0,
			fontWeight: 'bold',
			fontSize: 14,
		},
		expandMoreIcon: {
			background: 'linear-gradient(180deg, #75CFEA 0%, #0FD4D7 100%)',
			height: 20,
			width: 20,
			borderRadius: 10,
			color: 'white',
		},
		details: {
			display: 'flex',
			justifyContent: 'space-between',
			color: 'black',
			letterSpacing: 0,
			marginLeft: 36,
			marginRight: 36,
			paddingBottom: 30,
			borderBottom: 'rgba(221,216,216,0.5) 1px solid',
		},
		blueNumber: {
			color: '#07B0FF',
		},
		greenNumber: {
			color: '#15DDBD',
		},
		desempenhoNumber: {
			fontSize: 22,
			fontWeight: 'bold',
		},
		desempenhoLabel: {
			fontWeight: 'normal',
			fontSize: 14,
		},
		assuntoList: {
			listStyleType: 'none',
			fontFamily: 'Ubuntu',
			fontSize: 14,
			letterSpacing: 0,
			marginLeft: 36,
			overflowY: 'auto',
			height: 170,
		},
		assuntoLabel: {
			fontSize: 14,
		},
		accordionDetails: {
			display: 'flex',
			flexDirection: 'column',
		},
	})
);
