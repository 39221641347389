import { ReactNode } from 'react';
import { createActions, createReducer } from 'reduxsauce';

interface Actions {
    OPEN_ACTIONS_MODAL: string;
    CLOSE_ACTIONS_MODAL: string;
}

export interface ActionButton {
    label: string
    callback: () => void
    icon?: ReactNode
}

interface Creators {
    openActionsModal: (actionButtons: ActionButton[], title?: string, message?: string) => any;
    closeActionsModal: () => any;
}

export interface ModalStore {
    open: boolean
    actionButtons: ActionButton[]
    title?: string
    message?: string
}

const INITIAL_STATE: ModalStore = {
    open: false,
    actionButtons: []
};

const openActionsModal = (_: any, action: any) => ({ open: true, actionButtons: action.actionButtons, title: action.title, message: action.message });
const closeActionsModal = () => INITIAL_STATE

export const { Types: ActionsModalTypes, Creators: ActionsModalCreators } = createActions<
    Actions,
    Creators
>({
    openActionsModal: ['actionButtons', 'title', 'message'],
    closeActionsModal: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
    [ActionsModalTypes.OPEN_ACTIONS_MODAL]: openActionsModal,
    [ActionsModalTypes.CLOSE_ACTIONS_MODAL]: closeActionsModal
});
