import React, { useState } from 'react';

import {
	Modal,
	Backdrop,
	Fade,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Button,
} from '@material-ui/core';
import { Done, Refresh } from '@material-ui/icons';
import clsx from 'clsx'

import { Summary, useStyles } from './styles';

import closeModalIcon from '../../../assets/img/grey-times-solid.svg';
import correct from '../../../assets/img/check-circle-solid.svg';
import wrong from '../../../assets/img/error-red-times.svg';

const FeedbackModal = ({open, onClose, finishExerciseList, redoExerciseList, acertos, erros}: Props) => {
	const classes = useStyles();

	const [confirmationIsVisible, setConfirmationVisible] = useState(false)

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Card className={classes.card}>
					<CardHeader
						action={
							<IconButton
								onClick={onClose}
								style={{ marginBottom: 2 }}
							>
								<img src={closeModalIcon} alt="Close modal"></img>
							</IconButton>
						}
						className={classes.cardHeader}
						title={<p className={classes.cardHeaderTitle}>RESUMO</p>}
					/>
					<CardContent className={classes.cardContent}>
						{
							confirmationIsVisible ? (
								<div>
									<p style={{textAlign: 'center', marginBottom: 16}}>Ao confirmar, não será mais possível refazer os exercícios. Deseja realmente confirmar?</p>
									<Button
										onClick={() => {
											setConfirmationVisible(false)
											finishExerciseList()
										}}
										className={clsx(classes.sairButton, classes.simButton)}
									>
										Sim
									</Button>
									<Button
										onClick={() => setConfirmationVisible(false)}
										className={classes.sairButton}
									>
										Não
									</Button>
								</div>
							) : (
								<>
									<div style={{marginBottom: 16}}>
										<Summary correto>
											<img src={correct} style={{ height: 20, width: 20, marginRight: 8 }} alt="correct" />
											Acertos: {acertos}
										</Summary>
										<Summary>
											<img src={wrong} style={{ height: 20, width: 20, marginRight: 8 }} alt="wrong" />
											Erros: {erros}
										</Summary>
									</div>
									<Button
										onClick={() => setConfirmationVisible(true)}
										className={classes.sairButton}
									>
										<Done />
										Confirmar
									</Button>
									<Button
										onClick={redoExerciseList}
										className={classes.sairButton}
									>
										<Refresh />
										Refazer
									</Button>
								</>
							)
						}
					</CardContent>
				</Card>
			</Fade>
		</Modal>
	);
};

export default FeedbackModal

interface Props {
	acertos: number
	erros: number
	open: boolean
	onClose: () => void
	redoExerciseList: () => void
	finishExerciseList: () => void
}