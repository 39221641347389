import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import 'react-circular-progressbar/dist/styles.css';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		circularProgressBar: {
			fontFamily: 'Ubuntu',
			width: 68,
			height: 66,
		},
	})
);

const DesempenhoCircularProgressBar: React.FC<IDesempenhoCircularProgressBar> = ({ percentage, style }) => {
	const classes = useStyles();

	const getProgressColor = () => {
		if (0 <= percentage && percentage <= 25) return '#FF5151';
		else if (26 <= percentage && percentage <= 50) return '#F5A42A';
		else if (51 <= percentage && percentage <= 75) return '#F7D757';
		else return '#14E7C4';
	};

	return (
		<div className={classes.circularProgressBar} style={style}>
			<CircularProgressbar
				value={percentage}
				text={`${percentage?.toFixed(0)}%`}
				styles={{
					path: {
						stroke: getProgressColor(),
					},
					text: {
						fill: 'black',
						fontSize: 22,
					},
				}}
			/>
		</div>
	);
};

export default DesempenhoCircularProgressBar;

interface IDesempenhoCircularProgressBar {
	percentage: number;
	style?: React.CSSProperties;
}
